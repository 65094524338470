export const RENT = [
  {
    id: 20001,
    title: "ПАЛАТКА MESUCA OUTDOOR",
    img: "tent2Mesuca.jpg",
    desc: "2-местная легкая (1 слой) ",
    category: "rent",
    type: "rentTent",
    price: 10,
    shortly: "Простая и лёгкая палатка типа Camping Tent",
    column: [
      "210*150*120 см",
      "Вес 1700 гр ",
      "Тип конструкции: Быстрое автоматическое открытие",
      "Материал шеста: Алюминиевый корпус",
      "Индекс наружной водоустойчивости палатки 1000-1500 мм",
      "Водоустойчивый индекс нижней части 1000-1500 мм",
      "Сезон: Палатка на три сезона",
    ],
  },
  {
    id: 20005,
    type: "rentTent",
    title: "ПАЛАТКА ARTEN SPACE",
    img: "23.38.44.jpg",
    desc: "2-х местная легкая (1 слой)",
    category: "rent",
    price: 10,
    shortly:
      "Легкая однослойная палатка. Вход спального отделения продублирован москитной сеткой. Большой вентиляционный клапан. Все швы проклеены. Идеальна для простых походов в весеннее, летнее и осеннее время.",
    column: [
      "Высота (см): 120",
      "Наружний габарит (см): 210*150",
      "Производитель:ARTEN",
      "Материал каркаса: Фибергласс 7,9 мм",
      "Материал тента: 100% полиэстер 75D/190T PU W/R",
      "Водостойкость тента (мм в. ст): 2000",
      "Материал дна: Армированный полиэтилен (терпаулинг)",
      "Водостойкость дна (мм в. ст.): 10000",
      "Вес (г): 1800",
      "Количество слоев: 1",
      "Количество мест: 2",
      "Количество входов: 1",
      "Упаковка: Тканевый мешок",
    ],
  },
  {
    id: 20003,
    type: "rentTent",
    title: "ПАЛАТКА ARTEN LIGHT 2",
    img: "arten_light2.png",
    desc: "2-х местная cамораскрывающаяся (1 слой)",
    category: "rent",
    price: 14,
    shortly:
      "Хорошая вентиляция и мгновенное раскладывание, минимальный вес и комфорт, защита от лёгкой непогоды",
    column: [
      "230*110*90 см",
      "Производитель: ARTEN",
      "Материал каркаса: Фибергласс 7,9 мм",
      "Материал тента: 100% полиэстер 75D/190T PU W/R",
      "Водостойкость тента (мм в. ст): 2000",
      "Материал дна: Терпаулинг (армированный полиэтилен)",
      "Водостойкость дна (мм в. ст.): 10000",
      "Вес (г): 1800",
      "Количество слоев: 1",
      "Количество мест: 2",
      "Количество входов: 1",
      "Упаковка: Тканевый мешок",
    ],
  },
  {
    id: 2203043343013,
    type: "rentTent",
    title: "Палатка Pavillo Cooldome 2",
    img: "14.41.19.jpg",
    desc: "2-х местная (1 слой)",
    category: "rent",
    price: 14,

    column: [
      "Размер палатки: 205 Х 145 Х100 см",
      "В комплекте: палатка, 8 колышков, сумка для переноски.",
      "Влагозащита: 300 мм вод. ст.",
      "Вес: 1.76 кг",
    ],
  },
  {
    id: 200011,
    type: "rentTent",
    title: "ПАЛАТКА BESTWAY PAVILLO COOLDOME",
    img: "bestwat_pavillo.jpg",
    desc: "3-х местная (1 слой)",
    category: "rent",
    price: 14,
    shortly:
      "Палатка Pavillo Activemount 3 идеально подходит для активного туриста, который хочет начать свой день на свежем воздухе. Эта прочная двухслойная палатка изготовлена из огнезащитного материала с водонепроницаемыми швами. Туристическая палатка подойдет для использования в любых погодных условиях. Предусмотрен специальный козырек, который служит как дополнительное пространство для хранения вещей. Материал палатки - высококачественный водонепроницаемый полиэстер. Пол палатки выполнен из структурированного полиэтилена. Имеется внутренний карман для личных вещей. Швы палатки герметичны и проклеены. Есть противомоскитная сетка.",
    column: [
      "Водостойкость тента: 300.00 мм вод. ст",
      "Водостойкость дна: 300.00 мм вод. ст",
      "Вес: 2.20 кг",
      "Размер корпуса: 210*210*130",
      "Вентиляционные окна",
      "Материал тента: Полиэстер",
      "Материал пола: Армированный полиэтелен",
      "Материал дуг: Cтеклопластик",
      "Материал колышков: Cталь",
      "Количество мест: 3 чел",
      "Габариты транспортной упаковки:14х60х14см",
    ],
  },
  {
    id: 200013,
    type: "rentTent",
    title: "ПАЛАТКА BESTWAY COOLMOUNT4",
    img: "COOLMOUNT4.png",
    desc: "4-х местная самораскрывающаяся (1 слой)",
    category: "rent",
    price: 14,
    shortly:
      "Вам не надо ее устанавливать, просто положите ее на место установки и она разложится сама. В модели предусмотрена увеличенная вентиляция за счет больших окон по сторонам.Для переноски и хранения есть сумка чехол. Складывается в дискообразную сумку.",
    column: [
      "Бренд: Pavillo",
      "Размер палатки: 240*210*100 см.",
      "Вес: 2.3 кг.",
      "Водоотталкивающие свойства тента: 2000 мм.",
      "Водоотталкивающие свойства пола: 20000 мм.",
      "Кол. спальных мест: 4",
      "Габариты транспортной упаковки: 90*90*3 см",
    ],
  },

  {
    id: 22030443013,
    type: "rentTent",
    title: "Палатка Tramp Scout 2 ",
    img: "14.13.06.jpg",
    desc: "2-х местная (2 слой)",
    category: "rent",
    price: 15,

    column: [
      "Размер палатки: 250 X 220 X 120 см",
      "легкий двухслойный тент с двумя входами",
      "входы спального отделения продублированы москитной сеткой",
      "внешний тент палатки устойчив к ультрафиолетовому излучению",
      "внешний тент имеет пропитку, задерживающую распространение огня",
      "два вентиляционных клапана",
      "светоотражающие оттяжки и элементы на вентиляции",
      "все швы проклеены",
      "Вес, кг 3.7",
    ],
  },
  {
    id: 220430443013,
    type: "rentTent",
    title: "Палатка NORDWAY Orion 2  ",
    img: "u_files_store_1_1438374.jpg",
    desc: "2-х местная (2 слой)",
    category: "rent",
    price: 15,
    column: [
      "Размер палатки: 260 (50 тамбур) Х 120 Х 110 см",
      "Водонепроницаемость тента 1200 мм/в.ст/сутки, дна - 10000 мм/в.ст/сутки",
      "Дополнительные вентиляционные окна и вход, продублированный москитной сеткой",
      'Форма палатки "полусфера" способна выдержать сильный ветер',
      "Вес, кг 2.3",
      "Размер в сложенном виде (дл х шир х выс), 58 x 16 x 16 см",
      "Размер тамбура (дл х шир х выс), 50 x 125 x 105 см",
    ],
  },
  {
    id: 220304343013,
    type: "rentTent",
    title: "Палатка TOWI",
    img: "IMG_0797.png",
    desc: "2-х местная (2 слой)",
    category: "rent",
    price: 15,

    column: ["Размер палатки: 210 Х 140 Х 110 см"],
  },
  {
    id: 200443013,
    type: "rentTent",
    title: "Палатка Easy Life (с тамбуром)",
    img: "IMG_0745.png",
    desc: "3-х местная (2 слой)",
    category: "rent",
    price: 16,

    column: ["Размер палатки: 210 Х 210 Х 165 СМ + 110 см тамбур."],
  },
  {
    id: 20007,
    type: "rentTent",
    title: "ПАЛАТКА QUECHUA MH-100",
    img: "DECATHLON2ух.jpg",
    desc: "2-х местная 3-сезонная (2 слоя)",
    category: "rent",
    price: 16,
    shortly:
      "Простая и легкая в установке, эта эко-палатка предназначена для ваших лагерей на 2 человек. Экологичная и недорогая палатка. Благодаря отдельно стоящей куполообразной конструкции вы можете перемещать палатку, чтобы выбрать наиболее подходящее для вас место для кемпинга.",
    column: [
      "Комната: 130*210*107 см",
      "Рост: 107см",
      "Размер корпуса: 58*16*16 см / 12 л",
      "Вес: 2.6 кг ",
      "Ткань с защитой SPF 30: пропускает дневной свет, фильтрует УФ-лучи",
      "Водостойкость навес (мм в. ст.): 2000",
      "Водостойкость дна (мм в. ст.): 2400",
    ],
  },
  {
    id: 20009,
    type: "rentTent",
    title: "ПАЛАТКА QUECHUA MH-100",
    img: "tent3dec.jpg",
    desc: "3-х местная 3-сезонная (2 слоя)",
    category: "rent",
    price: 18,
    shortly:
      "Простая и легкая в установке, эта эко-палатка предназначена для ваших лагерей на 3 человека. Благодаря отдельно стоящей куполообразной конструкции вы можете перемещать палатку, чтобы выбрать наиболее подходящее для вас место для кемпинга.",
    column: [
      "Задняя вентиляция",
      "Выдерживает ветер силой 5 баллов (40 км/ч)",
      "Комната 195*210 см",
      "Высота: 120 см",
      "Размер корпуса: 59*17*17 см / 13 л",
      "Вес: 3,4 кг",
      "Ткань с защитой SPF 30: Пропускает дневной свет, фильтрует УФ-лучи",
      "Водостойкость навес (мм в. ст.): 2000",
      "Водостойкость дна (мм в. ст.): 2400",
    ],
  },
  {
    id: 2000912,
    type: "rentTent",
    title: "ПАЛАТКА Outventure Teslin 3",
    img: "tentoOutventure.jpg",
    desc: "3-х местная 3-сезонная (2 слоя)",
    category: "rent",
    price: 20,
    shortly:
      "Удобная трехместная туристическая палатка Outventure с увеличенным внутренним пространством и объемными тамбурами.",
    column: [
      "2 тамбура общей площадью более 1,5 м2 позволят с удобством разместить снаряжение",
      "Тент с показателем водонепроницаемости 3000 мм и проклеенные швы защитят от затяжного дождя",
      "В палатке предусмотрены карманы для мелких предметов",
      "2 вентиляционных окна и 2 входа с москитной сеткой обеспечивают воздухообмен",
      "Водонепроницаемость дна, мм.в.ст. 10000",
      "Водонепроницаемость тента, мм.в.ст. 3000",
      "Фиберглассовый каркас способен выдержать сильные порывы ветра.",
      "Вес, кг-3.9",
      "Размер палатки (дл х шир х выс), см 340 х 220 x 125",
    ],
  },
  {
    id: 200891234,
    type: "rentTent",
    title: "ПАЛАТКА Quechua Fresh and Black",
    img: " freshandblack.png",
    desc: "3-х местная 3-сезонная (2 слоя)",
    category: "rent",
    price: 20,
    column: [
      "Водонепроницаемость: Тент > 2000 мм. Коврик > 2400 мм.",
      "Размеры чехла: 60x20x18 см., 17 л.",
      "Вес: 4,1 кг.",
      "Боковая вентиляция и ткань Fresh&Black для хорошего сна.",
      "Устойчивость к ветру 40 км/ч (сила 5): проверена в аэродинамической трубе.",
      "Комната 195х210 см. Макс. полезная высота: 116 см",
    ],
  },
  {
    id: 2008322191234,
    type: "rentTent",
    title: "Палатка шестиместная двухкомнатная",
    img: "21.39.58.jpg",
    desc: "6-х местная 3-сезонная (2 слоя)",
    category: "rent",
    price: 20,
    column: [
      "Назначение: кемпинговая",
      "Размеры в сложенном виде (ДxШxВ), см: 64x21,5x21,5",
      "Длина в разложенном виде, см: 360",
      "Ширина в разложенном виде, см: 208",
      "Высота в разложенном виде, см: 190",
      "Общий вес, кг: 10.8",
      "Водостойкость тента: от 1000 мм до 3000 мм",
    ],
  },
  {
    id: 1200941,
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 2 20D",
    titleEng: "TENT NATUREHIKE CLOUD UP 2 20D",
    img: "tent18.png",
    desc: "2-х местная двухслойная",
    descEng: "2-person, double-layer 150*275*110",
    category: "rent",
    type: "rentTent",
    price: 20,
    shortlyEng:
      "Ultralight two-person tent for hiking, water, and biking trips. The spacious vestibule can accommodate a couple of large-sized backpacks.",
    shortly:
      "Ультралегкая двухместная палатка для пеших, водных и вело-походов. В просторный тамбур можно разместить пару рюкзаков внушительных размеров.",
    column: [
      "Каркас изготовлен из очень прочного, но гибкого легированного алюминия марки 7001",
      "Комната 210*125 см",
      "Высота: 100 см",
      "Вес: 1.55 кг",
      "Водостойкость навес (мм в. ст.): >4000",
      "Водостойкость дна (мм в. ст.): >4000",
      "Чехол для хранения и транспортировки",
      "Материал тента: Нейлон 20D с силиконовым покрытием",
      "Материал дна	Нейлон 20D с силиконовым покрытием",
      "Материал каркаса	Анодированный алюминий 7001",
      "Размер в сложенном виде (см)	40х13",
    ],
    columnEng: [
      "The frame is made of very durable but flexible alloy aluminum grade 7001.",
      "Room size: 210*125 cm.",
      "Height: 100 cm.",
      "The outer tent is made of thin and lightweight 210T polyester with RipStop weave and PU coating.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 1.83 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >3000.",
      "Floor waterproof rating (mm H2O): >3000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 2008912,
    type: "rentTent",
    title: "ПАЛАТКА экспедиционной серии Tramp Bike 2",
    img: "tentTrampBike2.jpg",
    desc: "2-х местная 4-сезонная (2 слоя)",
    category: "rent",
    price: 25,
    column: [
      "Вес: 2.40кг",
      "Размер в упаковке: 47.00см x 18.00см x 16.00см",
      "Максимальная защита от самых суровых погодных условий",
      "Водостойкость дна, мм вод. ст - 10000",
      "Водостойкость тента, мм вод. ст - 8000",
      "Назначение: всесезонные походы любой категории сложности – пеший, горный и вело туризм, альпинизм и зимние походы",
    ],
  },
  {
    id: 2030438912,
    type: "rentTent",
    titleEng: "TENT BTRACE CHALLENGE 3",
    title: "ПАЛАТКА BTRACE CHALLENGE 3",
    img: "rak9tvrh4clc52xeptloql.webp",
    desc: "3-х местная 3-сезонная (2 слоя)",
    category: "rent",
    price: 30,
    column: [
      "Вес: 4,75 кг",
      "Производитель: BTRACE",
      "Материал каркаса: Durapol 8,5 мм",
      "Материал тента: 100% полиэстер 75D/190Т RipStop",
      "Водостойкость тента (мм в. ст): 6000",
      "Материал дна: 100% полиэстер 75D/190Т",
      "Водостойкость дна (мм в. ст.): 8000",
      "Материал внутренней палатки: 100% полиэстер",
      "Цвет внутренней палатки: Желтый",
      "Количество слоев: 2",
      "Устойчивость к ультрафиолетовому излучению: Да",
      "Количество мест: 2",
      "Количество входов: 2",
      "Комплектация: Колышки, оттяжки, инструкция",
      "Высота (см): 130",
      "Размер в упаковке: 47.00см x 18.00см x 16.00см",
      "Наружний габарит (см): 370*220",
      "Тамбур (см): 1*110, 1*50",
      "Упаковка: Тканевая сумка",
      "Максимальная защита от самых суровых погодных условий",
      "Водостойкость дна, мм вод. ст - 10000",
      "Водостойкость тента, мм вод. ст - 8000",
      "Назначение: всесезонные походы любой категории сложности – пеший, горный и вело туризм, альпинизм и зимние походы",
    ],
  },
  {
    id: 20870016,
    title: "СПАЛЬНЫЙ МЕШОК HIKER",
    img: "sleeping_bag_green.jpg",
    desc: "комфорт от +18",
    category: "rent",
    type: "rentSleeping",

    price: 6,
    column: [
      "Вес: 960гр",
      "Размер: 195*60см",
      "Размер внешний: 35*26см",
      "зеленый/хаки",
    ],
  },
  {
    id: 20870096,
    title: "СПАЛЬНЫЙ МЕШОК HIKER",
    img: "23.15.08.jpg",
    desc: "комфорт от +10",
    category: "rent",
    type: "rentSleeping",
    price: 8,
    column: [
      "Вес: 960гр",
      "Размер: 195*60см",
      "Размер внешний: 35*26см",
      "камуфляж",
    ],
  },
  {
    id: 200016,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК NORDWAY Viking",
    img: "NORDWAYViking.jpeg",
    desc: "комфорт от +10 до +16",
    category: "rent",

    price: 8,
    column: [
      "Вес: 1.4 кг",
      "Длина: 220 см",
      "Ширина в плечах: 75 см",
      "Наполнитель: синтетика ",
    ],
  },
  {
    id: 200020,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК NORDWAY TORONTO",
    img: "sleppingbagNordway.jpg",
    desc: "комфорт от +10 до +20",
    category: "rent",
    price: 8,
    column: [
      "Pазмер: 215x75x75см",
      "Вес: 1070гр",
      "Молния	боковая",
      "Тип: мешок-одеяло",
      "Внешний материал	полиэстер",
      "Внутренний материал	полиэстер",
      "Утеплитель	синтетика",
    ],
  },
  {
    id: 200022,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК OUTVENTURE",
    img: "sleeping3.jpg",
    desc: "комфорт +20°C",
    category: "rent",
    price: 8,
    column: [
      "Pазмер: 180*85см",
      "Вес: 850гр",
      "Размер в сложенном виде (дл х шир х выс):41*20*20",
      "Тип: мешок-одеяло",
      "Внешний материал	полиэстер",
      "Внутренний материал	полиэстер",
      "Утеплитель HollowFiber",
      "Верхняя температура комфорта +25",
      "Нижняя температура комфорта +15",
      "Экстремальная температура +5",
      "Вес утеплителя: 150 г/м2",
    ],
  },
  {
    id: 204002200,
    type: "rentSleeping",
    title: "Спальный мешок Countryside ",
    img: "21.27.09.jpg",
    desc: "комфорт +9°C",
    category: "rent",
    price: 8,
    column: [],
  },
  {
    id: 204980022,
    type: "rentSleeping",
    title: "Спальный мешок Freetime",
    img: "18.22.56.jpg",
    desc: "комфорт +10°C",
    category: "rent",
    price: 8,
    column: [],
  },
  {
    id: 20002298,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Northland Hypnos",
    img: "NorthlandHypnos.jpg",
    desc: "комфорт +10°C",
    category: "rent",
    price: 10,
    column: [
      "Легкий и компактный спальный мешок от Northland — отличный выбор для походов в теплое время года",
      "Высокотехнологичный синтетический утеплитель AlphaCloud не даст замерзнуть",
      "Вес: 900гр",
    ],
  },
  {
    id: 100017,
    title: "СПАЛЬНЫЙ МЕШОК CAMPSOR",
    titleEng: "SLEEPING BAG CAMPSOR",
    img: "sleeping_bag_red.jpg",
    desc: "210*66см от +10 до 0",
    descEng: "210*66cm from +10 to 0°C",
    category: "rent",
    type: "rentSleeping",
    price: 10,
    column: ["Вес: 1300гр", "Размер внешний: 33*24см"],
    columnEng: ["Weight: 1300g", "External size: 33*24cm."],
  },
  {
    id: 298022909,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Pinguin Tramp L",
    img: "PinguinTrampL.jpeg",
    desc: "комфорт +8°C",
    category: "rent",
    price: 10,
    column: [
      "Тип кокон",
      "Внутренний материал полиэстер",
      "Размер спального места (ДхШ), см. 185х85",
      "Вес: 950гр",
      "Водонепроницаемый",
    ],
  },
  {
    id: 29802402909,
    type: "rentSleeping",
    title: "Спальный мешок Naturehike LW180",
    img: "IMG_0753.png",
    desc: "комфорт +15°C",
    category: "rent",
    price: 10,
    column: [
      "Размер: 190*75 см",
      "Комфорт: 15°C",
      "Лимит комфорта: 8°C",
      "Экстрим: -3°C",
      "Утеплитель: искусственный шелк-хлопок 100 гр/м2",
      "Размер в собранном виде 29*12*12 см",
      "Масса: 680 гр",
      "Водоотталкивающая пропитка",
    ],
  },
  {
    id: 200022909,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Rock Empire",
    img: "ROCKEMPIRESLEEPINGBAG.jpg",
    desc: "комфорт от +4°C, предел -2°C, экстрим -18°C",
    category: "rent",
    price: 10,
    column: [
      "Тип кокон",
      "Внутренний материал полиэстер",
      "Размер спального места (ДхШ), см. 185х85",
      "Вес: 950гр",
      "Водонепроницаемый",
    ],
  },

  {
    id: 200018,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Outventure Montreal",
    img: "OutventureMontreal.jpg",
    desc: "комфорт от +3°C",
    category: "rent",
    price: 10,
    column: [
      "Модель рекомендуется использовать при температуре от +13 до +3 °С.",
      "Синтетический утеплитель с увеличенной плотностью 300 г/м2 надежно защищает от холода",
      "В жаркую погоду можно полностью расстегнуть молнию, превратив спальник в одеяло",
      "Максимальный рост пользователя, 200см",
      "Трехсезонный",
      "Вес, кг - 1.97",
    ],
  },
  {
    id: 200971832,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Quechua Arpenaz 10",
    img: "QuechuaArpenaz.png",
    desc: "комфорт от +10°C",
    category: "rent",
    price: 10,
    column: [
      "Вес: 1300гр",
      "Размер: 190*72см",
      "Объем: 15 Л",
      "НАБИВКА: 100.00% Полиэстер",
      "Конвертируется в одеяло.",
    ],
  },
  {
    id: 2004971832,
    type: "rentSleeping",
    title: "Спальный мешок Deuter Orbit SQ +5",
    img: "deuter_orbit_sq.jpg",
    desc: "комфорт от +10°C",
    category: "rent",
    price: 15,
    column: [
      "Вес: 1330 грамм + мешок 70 грамм",
      "Размер в упакованном виде: 19 x 44 см",
      "Размеры: 195 + 35 x 80 см | на рост ~200 см",
      "ТЕМПЕРАТУРА КОМФОРТА: +10°C",
      "ТЕМПЕРАТУРА НИЖНЕГО ЛИМИТА КОМФОРТА: +5°C",
      "ТЕМПЕРАТУРА ЭКСТРИМА: -8°C",
      "тёплая и мягкая смешанная хлопковая ткань внутри",
      "Благодаря двум шнурам затяжки на воротнике – круглому и плоскому – справиться со спальным мешком в темноте стало проще",
      "Подкладка: T/C",
      "Наружный материал: 40D PA Ripstop",
    ],
  },
  {
    id: 2008722909,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Alpinus Fibrepro",
    img: "10891.jpg",
    desc: "комфорт от +2°C, предел до -7°C, экстрим до -12°C",
    category: "rent",
    price: 15,
    column: [
      "Вес: 1,3 кг",
      "Размер в компрессионном мешке: 15 x 25 см",
      "Длина: 215 см",
      "Ширина: 75 / 55 см",
    ],
  },
  {
    id: 203087226909,
    type: "rentSleeping",
    title: "Спальный мешок Forclaz Treck ",
    img: "44a77a0599.jpg",
    desc: "L = до 185 см, комфорт от +5, лимит 0",
    category: "rent",
    price: 15,
    column: ["1200 Г ДЛЯ РАЗМЕРА L.", "ОБЪЕМ: 11,3 Л", "КОМПРЕССИОННЫЙ ЧЕХОЛ"],
  },
  {
    id: 20001898,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Rock Empire Blizzard Long ",
    img: "RockEmpireBlizzardLong.jpg",
    desc: "комфорт -8°C, экстрим -27°C",
    category: "rent",
    price: 15,
    column: [
      "Спальник оснащен утепленным воротником и молнией, а также анатомическим капюшоном",
      "Мешок изготовлен в виде кокона, но имеет возможность полностью расстёгиваться, превращаясь в одеяло",
      "Карманчик для мелочей приятно дополняет конструкцию",
      "Спальник изготавливается с левой и правой молнией, что обеспечивает возможность их состёгивания",
      "Вес: 2220г",
      "Длина наружная: 230 см",
      "Ширина в груди: 80 см",
      "Ширина в ногах: 50 см",
      "Диаметр в упакованном виде: 23 см",
      "Длина в упакованном виде: 46 см",
    ],
  },
  // {
  //   id: 200024,
  //   type:'rentSleeping',
  //   title: "СПАЛЬНЫЙ МЕШОК HANNAH BIVAK",
  //   img: "sleeping-bag.jpg",
  //   desc: "комфорт от -5°C до +9°C",
  //   category: "rent",
  //   price: 15,
  //   column: [
  //     "Вес:	1,7 кг",
  //     "Температура комфорта (град):	13 °С",
  //     "Наполнитель:	синтетика",
  //     "Экстремальная температура (град):	-18 °С",
  //     "Длина:	205 см",
  //     "Ширина в плечах:	80 см",
  //     "Ширина в ногах:	50 см",
  //     "Мин, температура комфорта (град):	3 °С",
  //     "Тип спальника:	кокон",
  //     "Компрессионный мешок:	есть",
  //     "Внешняя ткань:	полиэстр",
  //     "Подкладка:	полиэстр",
  //     "Двойная молния:	да",
  //   ],
  // },
  {
    id: 200024332,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Marmot Wave III",
    img: "Marmot-Wave-III.png",
    desc: "комфорт -1, предел -8 градусов, экстрим -25",
    category: "rent",
    price: 15,
    column: [
      "Вес:	1,785 кг",
      "Синтетические спальные мешки серии Wave обеспечивают исключительное тепло при небольшом весе",
    ],
  },
  {
    id: 200025,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК TENNIER 2B1",
    img: "sleepongbagTwo.jpg",
    desc: "комфорт от -20°C до +15°C",
    category: "rent",
    price: 15,
    column: [],
  },
  {
    id: 20002985,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК Deuter Orbit",
    img: "DeuterOrbit.jpg",
    desc: "комфорт 0, лимит -5",
    category: "rent",
    price: 15,
    column: [
      "Синтетический утеплитель Thermo ProLoft и форма внутреннего кокона позволяют хорошо и эффективно отдохнуть, восстановив силы для будущих приключений",
      "Вес наполнителя/полный: 1020 г / 1700 г",
      "Капюшон специальной анатомической формы",
      "Два стяжных шнура для точно регулировки капюшона",
    ],
  },
  {
    id: 10001709,
    title: "Спальный мешок Forclaz MT500 ",
    titleEng: "SLEEPING BAG CAMPSOR",
    img: "2585160.png",
    desc: "комфорт 0/ лимит -5",
    descEng: "210*66cm from +10 to 0°C",
    category: "rent",
    type: "rentSleeping",
    price: 15,
    column: [
      "Комфортная темп.: 0°C / пред. темп.: -5°C.",
      "Наполнитель из синтетической ваты",
      "Вес: 1450 г",
      "Объем: 12,9 л.",
      "Компрессионный чехол",
      "Застегните молнию на 3/4 длины чехла, оснащенного спец. системой застежки-молнии",
      "Регулируемый капюшон с помощью эластичного шнура и фиксатора",
      "Пропитка снаружи (PFC Free) препятствует проникновению воды",
    ],
    columnEng: ["Weight: 1300g", "External size: 33*24cm."],
  },
  {
    id: 200015,
    type: "rentSleeping",
    title: "СПАЛЬНЫЙ МЕШОК BTRACE ZERO L SIZE ПРАВЫЙ",
    img: "BTRACE_ZERO_L.png",
    desc: "Ссверхлегкий, комфорт +5-15°C, эктрим 0°C",
    category: "rent",
    price: 15,
    shortly:
      "Новинка сезона 2021 - супер лёгкий спальный мешок-кокон BTrace Zero. Он идеально подойдёт для небольших путешествий в теплую погоду. Занимая совсем мало места, этот спальный мешок позволит с комфортом переночевать в походных условиях. Как и другие спальные мешки-коконы этой серии, BTrace Zero имеет возможность состёгиваться. Также можно выбрать один из 2х размеров, чтоб сэкономить ещё места и веса, если рост позволяет пользоваться спальным мешком меньшего размера.",
    column: [
      "Производитель: BTRACE",
      "Температура экстрима: 0",
      "Температура комфорта: +5",
      "Вес (г): 800",
      "Материал внешний: Полиэстер 20D 380Т Nylon RipStop WR",
      "Внутренний материал: 100% полиэстер 210T Soft Nylon",
      "Утеплитель: Izoshell Pro 2*80 г/м2",
      "Размер в упаковке (см): 52*25*25",
      "Размер (см): 220*90(55)",
      "Упаковка: Тканевый мешок",
    ],
  },

  {
    id: 200909826,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ ",
    img: "IMG_8135.jpg",
    desc: "50L (без подвесной системы) камуфляж",
    category: "rent",
    type: "rentBack",
    price: 5,
    column: ["Объем: 50 л"],
  },
  {
    id: 20409826,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ ",
    img: "AxonKenya36L.jpg",
    desc: "Axon Kenya 36L",
    category: "rent",
    type: "rentBack",
    price: 5,
    column: [
      "Объем: 36 л",
      "Он подходит как для отдыха на природе, прогулок по городу, так и для разнообразных спортивных активностей",
      "Регулируемые плечевые, бедренные и нагрудные ремни идеально адаптируют рюкзак к форме вашего тела, а вместе с мягкой спиной VCS, которая имеет сетчатую конструкцию для лучшей вентиляции, обеспечат комфорт при использовании в любых условиях. ",
    ],
  },
  {
    id: 200026,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "backbag4.jpg",
    desc: "65L NORDWAY ",
    type: "rentBack",
    category: "rent",
    price: 10,
    shortly:
      "Этот рюкзак предназначен для пользователей, которые отправляются в поход по гористой местности или хотят отправиться на другой конец света и отправиться в свое первое приключение. Этот простой рюкзак, который очень удобен благодаря своим отделениям",
    column: [
      "Объем: 65 л",
      "Вес:	1,9 кг",
      "Число лямок: 2",
      "Подвесная система: анатомическая",
      "Боковая стяжка: есть",
      "Верхний клапан: есть",
      "Карманы: боковые карманы",
    ],
  },
  {
    id: 200028,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "THENORTHFACE_blue.jpg",
    desc: "50L THE NORTH FACE(not original)",
    category: "rent",
    type: "rentBack",
    price: 10,
    shortly:
      "Легкий, надежный и полностью регулируемый рюкзак для многодневных выходов и путешествий налегке. Рюкзак NorthFace обладая объемом 50л, подойдет для скалолазания, походов и путешествий.",
    column: ["Объем: 50л", "Конструкция: Мягкий (без элементов жесткости)"],
  },
  {
    id: 2008728,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "60LTHENORTHFACEnotoriginal.jpg",
    desc: "60L THE NORTH FACE(not original)",
    category: "rent",
    type: "rentBack",
    price: 10,
    shortly:
      "Легкий, надежный и полностью регулируемый рюкзак для многодневных выходов и путешествий налегке. Рюкзак NorthFace обладая объемом 50л, подойдет для скалолазания, походов и путешествий.",
    column: ["Объем: 60л", "Конструкция: Мягкий (без элементов жесткости)"],
  },
  {
    id: 200030,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "backbag5.jpg",
    desc: "REGATTA 35L",
    category: "rent",
    type: "rentBack",
    price: 12,
    shortly:
      "Универсальный и прочный, он отлично подходит для походов и отпуска",
    column: [
      "Объем: 35л",
      "Материал: 100% полиэстер",
      "Размеры: 55x27,5x17,5 см",
      "2 сетчатых открытых боковых кармана",
      "Изготовлен из прочного материала с регулируемым поясным ремнем и нагрудными ремнями",
      "Боковые карманы на молнии обеспечивают дополнительное место для хранения",
      "Рюкзак имеет встроенные ранты для крепления треккинговых палок, сетчатые боковые карманы для бутылки с водой",
      "Дождевик, держатель для ключей, выдвижные застежки-молнии",
      "2 боковых кармана на молнии",
    ],
  },
  {
    id: 200032,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "backbag9.jpg",
    desc: "OUTVENTURE Creek 65L",
    category: "rent",
    price: 14,
    type: "rentBack",
    shortly:
      "Универсальный и прочный, он отлично подходит для походов и отпуска",
    column: [
      "Объем: 65л",
      "Материал: 100% полиэстер",
      "Размеры: 20 x 32 x 85 см",
      "Вес: 1.8кг",
      "Боковые стяжки: Да",
      "Вентиляция спины: Да",
      "Верхний клапан: Да",
      "Количество боковых карманов: 2",
      "Количество отделений: 1",
      "Крепление для ледового инструмента: Да",
      "Крепление для палок: Да",
      "Нагрудный ремень: Да",
      "Поясной ремень: Да",
      "Регулировка клапанов: Да",
      "Фронтальный карман: Да",
      "Число лямок: 2",
    ],
  },
  {
    id: 2090032,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "OutventureCreek80.jpg",
    desc: "OUTVENTURE Creek 80L",
    category: "rent",
    type: "rentBack",
    price: 14,
    column: [
      "Объем: 80л",
      "Профиль спины регулируется и настраивается по росту",
      "Предусмотрено 2 точки доступа к вещам, а также возможность внешней навески экипировки",
      "Предусмотрены карманы для дополнительного снаряжения",
      "Размеры (дл х шир х выс), см	21 x 36 x 95",
      "Вес: 2кг",
    ],
  },
  {
    id: 10004336,
    title: "ТУРИСТИЧЕСКИЙ РЮКЗАК",
    titleEng: "BACKPACK",
    descEng: "NATUREHIKE ROCK 60+51",
    img: "backpackNaturehike60.jpg",
    desc: "NATUREHIKE ROCK 60+51",
    category: "rent",
    type: "rentBack",
    price: 15,
    shortlyEng:
      "Ultra-lightweight hiking backpack with a 60-liter capacity and a rigid frame weighing only 1.16 kg.",
    shortly:
      "Сверхлегкий туристический рюкзак объемом 60 литров с жесткой рамой весом всего 1,16 кг.",
    column: [
      "Выполнен из нейлона DYNEEMA 420D, который превосходит по прочности сталь в 20 раз",
      "Вместительное основное отделение",
      "Система подвески с алюминиевым каркасом для лучшей воздухопроницаемости и снятия нагрузки с плеч",
      "Регулируемая грудная стяжка",
      "S-образные регулируемые плечевые лямки со съемным карманом",
      "Широкий поясной ремень с карманами для мелочей или еды",
      "Отделение для питьевой системы",
      "Выход для трубки питьевой системы на лямке",
      "Боковые сетчатые карманы",
      "Боковые стяжки",
      "Объемный карман спереди",
      "В комплекте чехол от дождя (в отсеке на дне)",
      "Имеет большой объем и грузоподъемность 14 кг.",
      "Модель 2022г.",
    ],
    columnEng: [
      "Constructed from 420D DYNEEMA nylon, which is 20 times stronger than steel",
      "Spacious main compartment",
      "Suspension system with aluminum frame for better ventilation and weight distribution",
      "Adjustable chest strap",
      "S-shaped adjustable shoulder straps with detachable pocket",
      "Wide waist belt with pockets for small items or food",
      "Hydration system compartment",
      "Tube exit for hydration system on the shoulder strap",
      "Side mesh pockets",
      "Side compression straps",
      "Large front pocket",
      "Includes a rain cover (stored in the bottom compartment)",
      "Has a large volume and a load capacity of 14 kg.",
      "Model year: 2022.",
    ],
  },
  {
    id: 200034,
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "backbag11.jpg",
    desc: "DEUTER PATAGONIA 70+15L",
    category: "rent",
    type: "rentBack",
    price: 16,
    shortly:
      "Универсальный и прочный, он отлично подходит для походов и отпуска",
    column: [
      "Объем: 70+15л",
      "Вес:  2.7кг",
      "Объём рюкзака регулируется вертикальными и горизонтальными стропами",
      "Имеется большой внешний карман для разных походных принадлежностей или мокрой одежды",
      "Возможность крепления дополнительного походного снаряжения",
      "Регулировка объема",
      "Фурнитура: Duraflex",
      "Вентиляция спины",
      "Грудной фиксатор",
      "нутренняя перегородка",
      "Наружные карманы",
      "Клапан: Съемный клапан с карманами",
    ],
  },
  {
    id: 2000354,
    title: "Рюкзак туристический",
    img: "rook65.jpg",
    desc: "Osprey Rock 65L	",
    category: "rent",
    type: "rentBack",
    price: 16,

    column: [
      "Объем: 65л",
      "Вес: 1.6 кг",
      "Osprey Rook 65 - это практичный и надежный рюкзак для путешествий и походов",
      "Интегрированная накидка от дождя",
      "Максимальные размеры (см): 74 (длина) x 42 (ширина) x 36 (глубина)",
      "Внутренняя компрессионная стяжка в верхней части рюкзака",
      "Лёгкий периферический каркас",
      "Съёмные стяжки для коврика",
      "Боковые компрессионные стяжки",
      "Грудная стяжка с сигнальным свистком",
      "Верхний доступ с несъемным клапаном",
      "Два кармана на молнии на поясном ремне",
      "Карман на молнии на верхнем клапане",
    ],
  },
  {
    id: 20032035,
    title: "Рюкзак туристический",
    img: "22.17.14.jpg",
    desc: "Trek Planet MAKALU 95",
    category: "rent",
    type: "rentBack",
    price: 20,

    column: [
      "Вес(кг), 2.65 кг",
      "Объем(литр): 95 л",
      "Размеры (В x Ш x Г) 90 х 43 х 31 см",
      "Анатомическая конструкция спины и ремней",
      "Ругулировка размера спины рюкзака (S-XL)",
      "Жесткий каркас спины - 2 алюминиевые пластины",
      "Влагоотталкивающие свойства внешнего материала",
      "Нагрудный ремень",
      "2 боковых эластичных кармана",
      "2 кармана в верхнем клапане",
      "Фронтальные стяжки",
      "Карман на молнии на бедренном поясе",
      "Дополнительный боковой вход в основное отделение",
      "Крепление для питьевой системы с внешним выходом",
      "Светоотражающий элемент",
      "Петли для навесного оборудования",
      "Чехол от дождя",
      "Материал Полиэстер 420D PU WR/RipStop 600D PU WR",
    ],
  },
  {
    id: 200035,
    title: "РЮКЗАК-ПЕРЕНОСКА (ДО 18+4КГ)",
    img: "deuterforchild.jpg",
    desc: "DEUTER KID COMFORT",
    category: "rent",
    type: "rentBack",
    price: 25,
    shortly:
      "Теперь вам не придётся нести уставшего или заснувшего ребёнка на руках",
    column: [
      "Вес:  2.780кг",
      "Быстрая и бесступенчатая регулировка спины VARI QUICK, чтобы оба родителя могли с комфортом носить рюкзак",
      "Детское кресло регулируется по высоте в зависимости от возраста ребёнка",
      "В комплект входит защита от солнца/дождя",
      "Два эластичных сетчатых кармана по бокам для бутылки или термоса",
    ],
  },
  {
    id: 2450035,
    title: "Cтул складной",
    img: "chairwithBag.JPG",
    desc: "до 110 кг",
    category: "rent",
    type: "rentFURNITURE",
    price: 4,
    column: [
      "Вес:1.1 кг",
      "Высота сидения 47 см",
      "Габариты в разложенном виде 26*35*60 см",
      "со спинкой и внешним, большим карманом",
    ],
  },
  {
    id: 245004535,
    title: "Стул складной Quechua",
    img: "23.31.23.jpg",
    desc: "до 110 кг",
    category: "rent",
    type: "rentFURNITURE",
    price: 4,
    column: [
      "Вес:1.4 кг",
      "Высота в сложенном виде - 17 см",
      "Ширина в сложенном виде - 17 см",
    ],
  },
  {
    id: 2445004535,
    title: "Кемпинговый стул-кресло с подстаканником ",
    img: "acf2bdf03.jpeg",
    desc: "до 110 кг",
    category: "rent",
    type: "rentFURNITURE",
    price: 5,
    column: [],
  },
  {
    id: 24435004535,
    title: "Туристический стул-кресло",
    img: "22.43.25.jpg",
    desc: "до 150 кг",
    category: "rent",
    type: "rentFURNITURE",
    price: 6,
    column: ["C повышенным комфортом"],
  },
  {
    id: 100440108,
    title: "СКЛАДНОЙ СТУЛ",
    img: "chair2.JPG",
    desc: "( ТАБУРЕТ )",
    category: "rent",
    type: "rentFURNITURE",
    price: 2,
    column: [],
  },
  {
    id: 200036,
    title: "СКЛАДНОЙ СТОЛ С ПОДСТАКАННИКОМ ",
    img: "table2.jpg",
    desc: "72 x 49 x 60 см",
    category: "rent",
    type: "rentFURNITURE",
    price: 8,
    column: [],
  },
  {
    id: 1004334107,
    title: "Cтол складной Quechua",
    img: "2579643.png",
    desc: "42 x 64 см, высота 30 см	",
    category: "rent",
    type: "rentFURNITURE",
    price: 8,
    column: [
      "Размеры сложен.: 42 x 32 x 4 см/ 5 л.",
      "Вес 1,6 кг",
      "Ручка для переноски",
      "Высота: 30 см.",
      "Ножки из алюм., столешница – из меламина.",
      "Макс.нагрузка на столешницу: 50 кг.",
    ],
  },
  {
    id: 10230433324109,
    title: "Cтол складной TONAR",
    img: "37464yxxvkwj.jpg",
    desc: "высота 60 см",
    category: "rent",
    type: "rentFURNITURE",
    price: 12,
    column: [
      "Размер: 50х70 см",
      "Высота: 60 см",
      "Материал ножки: сталь 16 мм",
      "Толщина: 1 мм",
      "Материал поверхности: ХДФ",
      "Максимальная нагрузка: 30 кг",
      "Вес: 2,6 кг",
    ],
  },
  {
    id: 200061,
    title: "ЗОНТ ПЛЯЖНЫЙ (СКЛАДНОЙ)",
    img: "umbrella.jpg",
    desc: "35*26см вес 0,510кг",
    type: "rentFURNITURE",
    category: "rent",
    price: 3,
    column: [
      "Материал купола: Нейлон",
      "Регулировка высоты: Есть",
      "Вес: 600гр",
    ],
  },
  {
    id: 200063,
    title: "ТЕНТ ПОЛИЭСТЕР 4*4м",
    img: "awning.jpg",
    desc: "35*26см вес 0,510кг без стоек",
    type: "rentFURNITURE",
    category: "rent",
    price: 5,
    column: ["Материал тента: Полиэстер с ПУ пропиткой"],
  },
  {
    id: 20054063,
    title: "Тент туристический Top Lander",
    img: "23.55.41.jpg",
    desc: "3*4 м, с металлическими опорами и колышками",
    type: "rentFURNITURE",
    category: "rent",
    price: 12,
    column: [""],
  },
  {
    id: 21104687,
    title: "ЛАМПА / ФОНАРЬ ДЛЯ КЕМПИНГА",
    titleEng: "CAMPING LAMP / LANTERN",
    img: "kamp-lambasi-100-lumen-kirmizi-bl100.jpg",
    desc: "QUECHUA BL100",
    category: "rent",
    type: "rentFURNITURE",
    price: 5,
    column: [
      "Красный режим: 20 лумен (яркость света)",
      "Белый режим: 50 лумен",
      "Яркий белый режим: 100 лумен",
      "Высота: 12 см",
      "Диаметр: 8 см",
      "Вес: 240 г",
      "Лампа работает на 4 батареях типа AA-IR6",
      "26 часов работы на максимальной яркости (100 луменов)",
      "60 часов работы на белом режиме (50 луменов)",
      "130 часов работы в красном режиме (20 луменов)",
    ],
    columnEng: [
      "Red mode: 20 lumens (light brightness)",
      "White mode: 50 lumens",
      "Bright white mode: 100 lumens",
      "Height: 12 cm",
      "Diameter: 8 cm",
      "Weight: 240 g",
      "The lamp operates on 4 AA-IR6 batteries",
      "26 hours of operation at maximum brightness (100 lumens)",
      "60 hours of operation in white mode (50 lumens)",
      "130 hours of operation in red mode (20 lumens)",
    ],
  },
  {
    id: 134043142087,
    title: "Гамак мексиканский с планкой",
    img: "2993683952.jpg",
    desc: "до 150 кг (одноместный)",
    category: "rent",
    type: "rentFURNITURE",
    price: 6,
    column: [
      "Ткань натуральный хлопок",
      "Длина: 200 см",
      "Ширина: 80 см",
      "Вес: 1,3 кг",
    ],
  },
  {
    id: 1340142087,
    title: "Гамак Quechua Comfort 350*175 cм",
    img: "basic.jpeg",
    desc: "до 220 кг (двухместный)",
    category: "rent",
    type: "rentFURNITURE",
    price: 10,
    column: [
      "Двухместный гамак",
      "Состав: 100% полиэстер",
      "Состав стропы: 100% полипропилен",
      "Размеры: 280 x 175 cm",
      "Размеры в упаковке: 36 x 18 x 8 cm / 5,2 ",
      "Вес: 950 грамм",
      "Максимальная нагрузка 220 кг",
    ],
  },
  {
    id: 1100142087,
    title: "Гамак Quechua Basic 300*150 cм",
    img: "hammock.jpg",
    desc: "до 110 кг (одноместный)",
    category: "rent",
    type: "rentFURNITURE",
    price: 8,
    column: [
      "Вес: 540 г",
      "Размеры 300 x 150 см",
      "Не стирать | Если необходимо: почистить влажной губкой",
      "Основная ткань/сумка для переноски 100% полиэтилентерефталат",
      "Рекомендуемый максимальный вес: 110 кг",
      "Крепления в комплекте",
    ],
  },
  {
    id: 2780051,
    title: "Надувной матрас двухместный INTEX",
    img: "mattressIntex2.jpg",
    desc: "203х152х25см + насос ручной",
    category: "rent",
    type: "rentFURNITURE",
    price: 12,
    column: [
      "Размеры: 203х152х25 см",
      "Рекомендуемый максимальный вес: 136 кг.",
      "Изготовлен по технологии Fiber-Tech внутренние перегородки сделаны из множества полиэфирных волокон, за счет чего он становится намного прочнее и легче флокированное покрытие матраса не дает соскальзывать",
      "Легко очищается клаган 2в1",
      "В комплект входит самоклеящаяся заплатка",
      "Обратите внимание, что ширина данного матраса больше, чем внутренняя ширина любой двухместной палатки. Для данного типа матраса подходит палатка 3-местная и 3+ .",
    ],
  },
  {
    id: 110014209,
    title: "НАДУВНОЙ МАТРАС ОДНОМЕСТНЫЙ INTEX",
    img: "mattressIntex1.png",
    desc: " 191х76х25см + насос ручной",
    category: "rent",
    type: "rentFURNITURE",
    price: 8,
    column: [
      "Размеры: 191х76х25 см",
      "Рекомендуемый максимальный вес: 136 кг.",
      "Изготовлен по технологии Fiber-Tech внутренние перегородки сделаны из множества полиэфирных волокон, за счет чего он становится намного прочнее и легче флокированное покрытие матраса не дает соскальзывать",
      "Легко очищается клаган 2в1",
      "В комплект входит самоклеящаяся заплатка",
    ],
  },
  {
    id: 11001420598,
    title: "Самонадувающийся коврик ",
    img: "unnamed.jpg",
    desc: "Pinguin Horn 30",
    category: "rent",
    type: "rentFURNITURE",
    price: 15,
    column: [
      "Вес: 1050 г.",
      "Толщина: 3 см.",
      "Размер: 195x51 см.",
      "Размер в упаковке: 20х34 см.",
      "Внешнее (верхнее) покрытие: Матовый полиэстер с алмазной рип-стоп обработкой и TPU ламинированием.",
      "Материал дна: Полиэстер с TPU ламинированием.",
      "Наполнитель: Сплошная пена.",
      "Материал клапана: Пластик.",
    ],
  },
  {
    id: 100009141,
    title: "НАДУВНАЯ ПОДУШКА ДЛЯ КЕМПИНГА",
    img: "airbasic.jpg",
    desc: "QUECHUA AIR BASIC ",
    category: "rent",
    type: "rentFURNITURE",
    price: 4,
    column: [
      "Размеры: 27×37×10 см",
      "Вес: 100 г",
      "Чтобы подушка быстрее сдулась, рекомендуем сжать клапан, чтобы знать блокировку и выпустить воздух",
    ],
  },
  {
    id: 200354,
    title: "Душ автомобильный",
    img: "shower.jpg",
    desc: "Экспедиция' (EASH-06)",
    category: "rent",
    type: "rentFURNITURE",
    price: 10,
    shortly: "Инструкция по эксплуатации:",
    column: [
      "Погрузите насос в емкость с водой, предварительно убедившись, что воды достаточно",
      "Закрепите душ так, как Вам удобно, используя S–крюк или присоску",
      "Вставьте штекер питания в сеть DC 12V",
      "Включите душ, нажав кнопку включения",
      "Отрегулируйте напор воды с помощью регулятора Min-Max",
      "Мойтесь и наслаждайтесь",
      "Удобный чехол из полимерного материала поможет - компактно упаковать автодуш и подготовить его к перевозке, - предохранить салон автомобиля от воды, оставшейся в устройстве, если вам нужно продолжать путь сразу после мытья.",
    ],
  },
  {
    id: 20043354,
    title: "Тренога костровая",
    img: "00.13.37.jpg",
    desc: "для котелка и/или казана",
    category: "rent",
    type: "rentDISHES",
    price: 3,
    column: [],
  },
  {
    id: 2003243354,
    title: "Чайник костровой",
    img: "chaynik.jpg",
    desc: "походный 3L",
    category: "rent",
    type: "rentDISHES",
    price: 4,
    column: [],
  },
  {
    id: 200343233354,
    title: "Казан с крышкой",
    img: "00.23.42.jpg",
    desc: "2L для открытого огня или мангала",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 200323335455,
    title: "Котелок походный",
    img: "00.34.06.jpg",
    desc: "для открытого огня 6L",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 2003903354,
    title: "Котелок походный",
    img: "00.34.13.jpg",
    desc: "для открытого огня 9L",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 200042,
    title: "МНОГОФУНКЦИОНАЛЬНЫЙ HOЖ 6 B 1 ",
    img: "knife.jpg",
    desc: "EXPEDITION",
    category: "rent",
    type: "rentDISHES",
    price: 2,
    column: [],
  },
  {
    id: 200043,
    title: "HOЖ OPIENEL 08",
    img: "knifeRent.jpg",
    desc: "( 8.5 СМ )",
    category: "rent",
    type: "rentDISHES",
    price: 3,
    column: [
      "Вес - 45 гр",
      "Высота- 10 см",
      "Рукоять из бука",
      "Тип заточки - прямой",
      "Длина клинка - 8,5 см",
      "Марка стали - Sandvik 12C27",
    ],
  },
  {
    id: 1000101,
    title: "СКЛАДНОЙ РЫБОЛОВНЫЙ НОЖ",
    img: "knife7.jpg",
    desc: "CAPERLAN KN FID",
    category: "rent",
    type: "rentDISHES",
    price: 3,
    column: [
      "Нержавеющая сталь с тефлоновым покрытием для защиты от ржавчиныв",
      "Устойчив к морской воде",
      "Его ручка плотно прилегает к вашей руке благодаря формованной эргономичной конструкции",
      "Общая длина: 19 см",
      "Длина лезвия:8,5 см",
      "Толщина лезвия: 3 мм",
      "Лезвие: 100 % нержавеющая сталь",
      "Рукоятка: 100 % полипропилен (ПП)",
      "Cмажьте лезвие силиконовым маслом и храните в сухом месте",
    ],
  },
  {
    id: 103240101,
    title: "Набор шампуров из 6 шт",
    img: "00.54.27.jpg",
    desc: "в прокате с июня 2023",
    category: "rent",
    type: "rentDISHES",
    price: 3,
    column: [],
  },
  {
    id: 10320101,
    title: "Набор шампуров из 6 шт",
    img: "00.54.27.jpg",
    desc: "в прокате с июня 2024",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 103204310154,
    title: "Гриль - решетка 40*33 см",
    img: "4fa8.png",
    desc: "в прокате с июня 2023",
    category: "rent",
    type: "rentDISHES",
    price: 3,
    column: [],
  },
  {
    id: 1032043101,
    title: "Гриль - решетка 40*33 см",
    img: "4fa8.png",
    desc: "в прокате с июня 2024",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 200037,
    title: "МАНГАЛ СКЛАДНОЙ",
    img: "mangal.jpg",
    desc: "в прокате с 08.2023",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 2003254037,
    title: "МАНГАЛ СКЛАДНОЙ",
    img: "22.17.12.jpg",
    desc: "в прокате с 05.2023",
    category: "rent",
    type: "rentDISHES",
    price: 5,
    column: [],
  },
  {
    id: 20032037,
    title: "Мангал со встроенным грилем",
    img: "grill.jpg",
    desc: "невысокий (в прокате с августа 2024)",
    category: "rent",
    type: "rentDISHES",
    price: 8,
    column: [],
  },
  {
    id: 2030900437,
    title: "Мангал-Барбекю со встроенным грилем",
    img: "IMG_8165.png",
    desc: "cредних размеров (в прокате с июня 2024)",
    category: "rent",
    type: "rentDISHES",
    price: 10,
    column: [],
  },
  {
    id: 20370900437,
    title: "Термос STANLEY",
    img: "16.15.11.jpg",
    desc: "Classic 2,3L ",
    category: "rent",
    type: "rentDISHES",
    price: 15,
    column: [],
  },
  {
    id: 10043089,
    title: "ТЕРМОС",
    img: "2201772.png",
    desc: "1 литр Quechua",
    category: "rent",
    price: 5,
    type: "rentDISHES",
    column: [
      "Начал. темп. 95°С: 82°C после 6 ч., 73°C после 12 ч., 60°C после 24 ч",
      "Чем более заполнен термос, тем лучше он держит температуру",
      "Легкость ухода благодаря крышке с резьбой. Внутренняя стенка из нерж. стали",
      "Крышка содержит встроенный фильтр, который фильтрует такие продукты, как фруктовые настойки или крупный чайный лист. Не подходит для очень мелкого чайного листа.",
      "Размеры: Ø 8,6 см x 30 см",
      "Вес: 405 г",
    ],
  },

  {
    id: 100243089,
    title: "ТЕРМОС",
    img: "ac450f8.jpg",
    desc: "1 литр АРКТИКА",
    category: "rent",
    price: 5,
    type: "rentDISHES",
    column: [
      "Начал. темп. 95°С: 82°C после 6 ч., 73°C после 12 ч., 60°C после 24 ч",
    ],
  },

  {
    id: 1032404354089,
    title: "Cумка-холодильник Outventure 20L",
    img: "22.58.08.jpg",
    desc: "c 3 аккумуляторами холода",
    category: "rent",
    price: 10,
    type: "rentDISHES",
    column: [
      "Объем, л 20",
      "Глубина, см 25",
      "Ширина, см 35",
      "Высота, см 35",
      "Термосумка из полиэстера",
      "Подкладка выполнена из полиэтиленвинилацетата",
      "Используется вместе с аккумуляторами холода",
    ],
  },
  {
    id: 1032043089,
    title: "Терморюкзак с посудой на 4 человек",
    img: "180.jpg",
    desc: "",
    category: "rent",
    price: 15,
    type: "rentDISHES",
    column: [
      "4 тарелки, 4 глубоких тарелки, 4 кружки, скатерть, 4 ножа, 4 вилки, 4 маленькие ложки",
    ],
  },
  {
    id: 1023043089,
    title: "Набор посуды для горелки",
    img: "6559.jpg",
    desc: "кастрюля, сотейник, сковорода",
    category: "rent",
    price: 10,
    type: "rentBURNER",
    column: [],
  },
  {
    id: 10230430829,
    title: "Набор посуды для горелки",
    img: "dish8.jpg",
    desc: "4 котелка, сковорода, лопатка, поварешка, 3 тарелки",
    category: "rent",
    price: 10,
    type: "rentBURNER",
    column: ["4 котелка разного объема"],
  },
  {
    id: 1230430829,
    title: "Набор посуды из 4 котелков для горелки",
    img: "dish4.jpg",
    desc: "2 больших, 2 маленьких",
    category: "rent",
    price: 8,
    type: "rentBURNER",
    column: [],
  },
  {
    id: 12000430829,
    title: "Чайник BTrace",
    img: "teapot_BTRACE1.jpg",
    desc: "Quechua 1.1L алюминиевый	",
    category: "rent",
    price: 4,
    type: "rentBURNER",
    column: [],
  },
  {
    id: 120004308292,
    title: "Чапельник",
    img: "clip.jpg",
    desc: "прихватка для посуды, еды	",
    category: "rent",
    price: 2,
    type: "rentBURNER",
    column: [],
  },
  {
    id: 120004308293,
    title: "Ветрозащитный экран для горелки",
    img: "screen.jpg",
    desc: "13,50 см ",
    category: "rent",
    price: 2,
    type: "rentBURNER",
    column: [],
  },

  {
    id: 12009308293,
    title: "Комплект адаптера",
    img: "adapter.jpg",
    desc: "с цангового баллона на резьбу",
    category: "rent",
    price: 2,
    type: "rentBURNER",
    column: ["для заправки и переходник с цангового баллона на резьбу"],
  },
  {
    id: 312009308293,
    title: "Горелка для резьбовых баллонов",
    img: "burner32.jpg",
    desc: "Nurgaz	",
    category: "rent",
    price: 4,
    type: "rentBURNER",
    column: [],
  },
  {
    id: 312009320438293,
    title: "Горелка для резьбовых баллонов",
    img: "Campsor_folding.jpg",
    desc: "Campsor",
    category: "rent",
    price: 4,
    type: "rentBURNER",
    column: ["Материал: Алюминий", "Вес: 150 гр", "Пьезоподжиг"],
  },
  {
    id: 100051,
    title: "Горелка - плита с пьезоподжигом",
    titleEng: "GAS BURNER - STOVE",
    img: "burner44.jpg",
    desc: "ДЛЯ ЦАНГОВЫХ БАЛЛОНОВ",
    category: "rent",
    price: 4,
    type: "rentBURNER",
    column: ["Материал: Алюминий", "Вес: 150 гр", "Пьезоподжиг"],
  },
  {
    id: 11000947,
    title: "ГОРЕЛКА СТРУЙНАЯ",
    titleEng: "JET BURNER",
    img: "gas-flame.jpg",
    desc: "FLAME GUN 915",
    type: "rentBURNER",
    category: "rent",
    price: 4,
    column: [
      "Включается в одно касание",
      "С помощью пьезоподжига длина пламени регулируется",
      "В течение первых двух минут горелка должна разогреться",
      "о избежание вспышек газа на время разогрева не допускается отклонение горелки более чем на 75 градусов от вертикального положения",
      "После прогрева можно работать с горелкой под лобым углом",
      "Температура пламени достигает 1300°С",
    ],
    columnEng: [
      "Turns on with a single touch",
      "Flame length is adjustable using the piezoelectric igniter",
      "For the first two minutes, the stove needs to warm up",
      "To prevent gas flashes during warming up, the stove should not be tilted more than 75 degrees from the vertical position",
      "After warming up, the stove can be operated at any angle",
      "The flame temperature reaches 1300°C",
    ],
  },
  {
    id: 31200954308293,
    title: "Горелка-cистема нагревания",
    img: "gorelka-sistema-primus.png",
    desc: "Primus Lite Plus (без газового баллона)",
    category: "rent",
    price: 10,
    type: "rentBURNER",
    column: [
      "Горелка с ламинарным потоком чрезвычайно компактна и выдает пламя мощностью 4500 BTU (1500 Вт), кипятя 0,5 л воды менее чем за три минуты, при этом обеспечивая тихую готовку",
      "Материал горелки: нержавеющая сталь",
      "Материал котелка: анодированный алюминий",
      "Высота: 130 мм",
      "Диаметр: 100 мм",
      "Вес: 402 г",
      "Объем: 500 мл",
    ],
  },

  {
    id: 43120059308293,
    title: "Горелка-система нагревания",
    img: "9446dc3.png",
    desc: "Fire-Maple STAR X3 (без газового баллона)",
    category: "rent",
    price: 15,
    type: "rentBURNER",
    column: [
      "Размер: 254х246 мм",
      "Размер в сложенном виде: 133×185 мм",
      "Алюминиевый котелок, TPE-покрытие (термопластичный эластомер), сталь нержавеющая, ABS основа горелки",
      "Комбинированная система приготовления пищи 3-в-1,  с более компактным котелком на 0,8 л - STAR X3 от Fire-Maple",
      "Вес: 600 г ",
    ],
  },
  {
    id: 431200930825493,
    title: "Топор 37*14 см с деревянной ручкой",
    img: "v8_7846_6d8.png",
    desc: "в прокате с июня 2023",
    category: "rent",
    price: 2,
    type: "rentTOOLS",
    column: [],
  },
  {
    id: 43679308293,
    title: "Топор 37*14 см с пластиковой ручкой",
    img: "product_1207_1.jpg",
    desc: "в прокате с июня 2024",
    category: "rent",
    price: 4,
    type: "rentTOOLS",
    column: [],
  },
  {
    id: 4367954308293,
    title: "Лопата складная",
    img: "4-min.png",
    desc: "в чехле",
    category: "rent",
    price: 5,
    type: "rentTOOLS",
    column: [],
  },
  {
    id: 43679543083293,
    title: "Туристическая пила одноручная",
    img: "15.55.32.jpg",
    desc: "ножовка по дереву",
    category: "rent",
    price: 4,
    type: "rentTOOLS",
    column: [],
  },
  {
    id: 20054048,
    title: "Рейнкавер - Дождевик ",
    img: "13.41.27.jpg",
    desc: "на рюкзак 20-40 литров",
    category: "rent",
    price: 2,
    type: "rentHIKING",

    column: [],
  },
  {
    id: 200048,
    title: "CИДЕНЬЕ ТУРИСТИЧЕСКОЕ/хоба",
    img: "sitting_DECATHLON.png",
    desc: "Из пеноматериала с метал. слоем",
    category: "rent",
    price: 2,
    type: "rentHIKING",
    shortly:
      "Сиденье с металлическим каркасом из пеноматериала, подходящий для треккинга.",
    column: [
      "Вес: 60 г",
      "Размеры: 34*23,7*1,5 см",
      "Эластичная лента с клипсой обеспечивает комфортное использование",
    ],
  },
  {
    id: 200052,
    title: "КОВРИК  фольгированный",
    img: "mat_aluminum_foil_FORA.jpg",
    desc: "180*60/50*0,4 см ",
    category: "rent",
    type: "rentHIKING",
    price: 2,
    column: ["Однослойный"],
  },
  {
    id: 23100057,
    title: "КОВРИК QUECHUA",
    titleEng: "MAT QUECHUA",
    img: "matQuechua.jpg",
    desc: "180*50*0,7см ",
    descEng: "180*50*0,7сm",
    category: "rent",
    type: "rentHIKING",
    price: 2,
    column: [
      "Размеры: 180*50*0,7 см.",
      "Вес: 210 грамм",
      "Материал: 100% полиэтилен",
      "С алюминиевую пленкой, которая предотвращает истирание и улучшает теплопроводность",
      "Для удобства переноски оснащен эластичной лентой",
    ],
    columnEng: [
      "Dimensions: 180 x 50 x 0.7 cm",
      "Weight: 210 grams",
      "Material: 100% polyethylene",
      "With aluminum foil, which prevents abrasion and improves thermal conductivity",
      "For easy carrying, equipped with an elastic strap",
    ],
  },
  // {
  //   id: 34200051,
  //   title: "КОВРИК ДЛЯ ЙОГИ (ПЕНКА)",
  //   img: "mat2.jpg",
  //   desc: "175/180*60*1 см ",
  //   category: "rent",
  //   type:'rentHIKING',
  //   price: 4,
  //   column: ["Материал: ЭВА"],
  // },
  {
    id: 3423200051,
    title: "Коврик-пенка",
    img: "mat_with_case.jpg",
    desc: "175/180*60*1 см ",
    category: "rent",
    type: "rentHIKING",
    price: 4,
    column: ["Нескользящий"],
  },
  {
    id: 34200051,
    title: "КОВРИК ДЛЯ ЙОГИ (ПЕНКА)",
    img: "mat6.jpg",
    desc: "180*59 см ",
    category: "rent",
    type: "rentHIKING",
    price: 4,
    column: ["Материал: ЭВА"],
  },
  {
    id: 3420043051,
    title: "Коврик-пенка",
    img: "15.22.44.jpg",
    desc: "TEWSON 8мм",
    category: "rent",
    type: "rentHIKING",
    price: 4,
    column: [
      "Габариты (ДхШхВ) 180*60*1 см",
      "Цвет: камуфляж пиксельный",
      "Материал: ППЭ (пенополиэтилен)",
      "Вес 0.38 кг",
      "Габариты упаковки (ДхШхВ) 60*15*15 см",
      "",
    ],
  },
  {
    id: 34204330051,
    title: "Плащ-дождевик",
    img: "5031.jpg",
    desc: "Coverguard L (2000 mm)",
    category: "rent",
    type: "rentHIKING",
    price: 4,
    column: [""],
  },
  {
    id: 21104698,
    title: "ТРЕКИНГОВЫЕ ПАЛКИ",
    titleEng: "TREKKING POLES",
    img: "trekkingsticks1.jpg",
    descEng: "Set of 2, aluminum",
    desc: "Комплекст из 2шт, алюминиевые",
    category: "rent",
    type: "rentHIKING",
    price: 4,
    shortlyEng:
      "Trekking poles are designed to help you maintain balance and evenly distribute weight between your arms and legs.",
    shortly:
      "Треккинговые палки редназначены для того, чтобы помочь вам сохранить равновесие и правильно распределить нагрузку между руками и ногами.",
    column: [
      "Толстая губчатая ручка",
      "3D-образная и длинная",
      "3 телескопические части",
      "Размер в сложенном виде - 56 см",
      "Ширина при открытии: ~5 см",
      "Регулировка длины 105 - 130 см ",
      "Диаметр стержня 18/16/14 мм ",
      "Прочный вольфрамовый наконечник",
      "Вес: 240 гр",
    ],
    columnEng: [
      "Thick foam handle",
      "3D-shaped and long",
      "3 telescopic sections",
      "Folded size - 56 cm",
      "Width when open: ~5 cm",
      "Length adjustment 105 - 130 cm",
      "Shaft diameter 18/16/14 mm",
      "Durable tungsten tip",
      "Weight: 240 g",
    ],
  },
  {
    id: 200049,
    title: "ФОНАРЬ НАЛОБНЫЙ 30 и 80 (с батарейкой)",
    img: "lantern_black.jpg",
    desc: "1 режима освещения",
    category: "rent",
    type: "rentHIKING",
    price: 4,
    column: [
      "Питание:2 шт ААА(в наборе)",
      "Автономность: 25 часов",
      "Вес: 65г",
      "Одна кнопка ON/OFF для удобства использования в перчатках или рукавицах",
      "Регулируемый ремешок",
      "Защита от брызг и ударов",
    ],
  },
  {
    id: 190349993044,
    title: "Фонарь налобный Black Diamond",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "black-diamond-storm-450.jpg",
    desc: "350 люмен",
    category: "rent",
    type: "rentHIKING",
    price: 7,
    column: [
      "Световой поток: до 350 люмен",
      "Максимальное расстояние(я) при максимальной настройке: 100 м",
      "Максимальное расстояние(я) при самой низкой настройке: 9 м",
      "Максимальное время работы при максимальных настройках: FL1 5 ч; резерв 42 ч",
      "Максимальное время работы при минимальных настройках: 150 ч.",
      "Водонепроницаемость ",
      "Память яркости позволяет включать и выключать свет с выбранной яркостью, не возвращаясь к настройкам по умолчанию.",
    ],
  },
  {
    id: 230053,
    title: "Каска Petzl",
    img: "VERTEXVENT.jpg",
    desc: "Альпинистская",
    category: "rent",
    type: "rentCLIMBING",
    price: 8,
    column: [
      "Каски Petzl отличаются легкостью, удобством и простотой регулировки.",
      "Они играют существенную роль в качестве оборудования для обеспечения безопасности для вертикальных видов активности.",
    ],
  },
  {
    id: 2300590,
    title: "Каска Simond",
    img: "helmet.jpg",
    desc: "Альпинистская",
    category: "rent",
    type: "rentCLIMBING",
    price: 7,
    column: [
      "Каркас из ABS-пластика защищает шлем от царапин",
      "Регулировка с помощью ремня",
      "Защита от ударов",
    ],
  },
  {
    id: 230320590,
    title: "Каска Petzl METEOR",
    img: "kaska_petzl_meteor_2_fuchsia.png",
    desc: "Альпинистская",
    category: "rent",
    type: "rentCLIMBING",
    price: 10,
    column: ["малинового/розового цвета", "53-61 см", "Защита от ударов"],
  },
  {
    id: 23005309,
    title: "Ледоруб Vento",
    img: "ice-axe.jpg",
    desc: "75 см (чёрный)",
    category: "rent",
    type: "rentCLIMBING",
    price: 10,
    column: [
      "Состав: Сталь",
      "Модель типа B (basic) для занятий альпинизмом и горным туризмом",
      "Предназначен для хождения по ледникам и снежным склонам, организации точек страховки в снегу",
      "Стальной клюв с отверстиями для подключения самостраховки и дополнительного карабина",
      "Широкая лопатка для вырубания ступеней во льду или фирне",
    ],
  },
  {
    id: 2300530909,
    title: "Ледоруб Alpine Equipment",
    img: "ice-axe2.jpg",
    desc: "72 см (золотистого цвета)",
    category: "rent",
    type: "rentCLIMBING",
    price: 10,
    column: [
      "Состав: Сталь",
      "Предназначен для хождения по ледникам и снежным склонам, организации точек страховки в снегу",
      "Стальной клюв с отверстиями для подключения самостраховки и дополнительного карабина",
      "Широкая лопатка для вырубания ступеней во льду или фирне",
    ],
  },
  {
    id: 2300530978,
    title: "Ледоруб Simond",
    img: "ice-axe3.jpeg",
    desc: "70 см (синий)",
    category: "rent",
    type: "rentCLIMBING",
    price: 10,
    column: [
      "Лезвие: 100 % сталь",
      " Рукоятка: 100 % алюминий ",
      "Предназначен для хождения по ледникам и снежным склонам, организации точек страховки в снегу",
      "Стальной клюв с отверстиями для подключения самостраховки и дополнительного карабина",
      "Широкая лопатка для вырубания ступеней во льду или фирне",
    ],
  },
  {
    id: 23019709798,
    title: "Кошки для альпинизма",
    img: "Rock.jpg",
    type: "rentCLIMBING",
    desc: "(Rock Empire/Vento/Petzl)",
    category: "rent",
    price: 10,
    column: ["Материал: Сталь и пластик", "Размер: Универсальный"],
  },
  {
    id: 23005309798,
    title: "Система-обвязка",
    img: "01.30.46.jpg",
    desc: "альпинистская Vento",
    category: "rent",
    type: "rentCLIMBING",
    price: 6,
    column: [
      "Страховочные привязи",
      "Вес, гр. — 300",
      "Технология «силового каркаса» обеспечивает распределение нагрузки в момент остановки падения на всю площадь пояса и ножных петель",
      "Имеются петли для развески снаряжения",
    ],
  },
  {
    id: 230405309798,
    title: "Система-обвязка",
    img: "12.58.23.jpg",
    desc: "альпинистская Аскан",
    category: "rent",
    type: "rentCLIMBING",
    price: 3,
    column: [],
  },
  {
    id: 230405443309798,
    title: "Варежки-перчатки YASHIL GORE-TEX ",
    img: "IMG_0809.PNG",
    desc: "до 3000-3500 м",
    category: "rent",
    type: "rentCLIMBING",
    price: 5,
    column: [],
  },
  {
    id: 233040544309798,
    title: "Варежки пуховые (верхонки) ",
    img: "IMG_0810.png",
    desc: "THE NORTH FACE (not original)",
    category: "rent",
    type: "rentCLIMBING",
    price: 5,
    column: [],
  },
  {
    id: 2330430544309798,
    title: "Варежки пуховые",
    img: "21.28.18.jpg",
    desc: "BVN travel М",
    category: "rent",
    type: "rentCLIMBING",
    price: 8,
    column: [],
  },
  {
    id: 2335040544309798,
    title: "Гамаши туристические ",
    img: "IMG_0813.JPG",
    desc: "- комплект (фонарики)",
    category: "rent",
    type: "rentCLIMBING",
    price: 3,
    column: [],
  },
  {
    id: 20005456,
    title: "Лодка надувная для 3-4 человек",
    img: "board.jpg",
    desc: "Hello Plus 260 gray ",
    category: "rent",
    price: 80,
    type: "rentSUP",
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      "(2 камеры+надувное дно) / Сумка",
      " 2 весла",
      "ножной насос",
      "260 на 130 см",
      "22 кг",
      "грузоподъемность 180 кг",
    ],
  },
  // {
  //   id: 20003454,
  //   title: "Сап-борд Runeed Everest blue",
  //   img: "sup1.png",
  //   desc: "350х84х15 см полный комплект",
  //   category: "rent",
  //   price: 70,
  //   type:'rentSUP',
  //   shortly: "КОМПЛЕКТАЦИЯ: ",
  //   column: [
  //     " -Водонепроницаемый мешок 5 литров - 1 шт",
  //     "-1 съемный центральный киль - 1 шт.",
  //     "-Съемный боковой киль - 2 шт.",
  //     "Ручной насос высокого давления - 1 шт.",
  //     "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
  //     "Алюминиевое весло - 1 шт.",
  //     "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
  //     "Водонепроницаемый мешок - 1 шт.",
  //     "Безопасный ремень - 1 шт.",
  //     "Ширина:84см",
  //     "Толщина: 15см",
  //     "Длина: 350 см",
  //     "Вес: 9.5 кг",
  //     "Макс. нагрузка: 145 кг",
  //   ],
  // },
  {
    id: 1000171,
    title: "Сап-борд (шаман)",
    img: "sup5.jpg",
    desc: "350х84х15 см полный комплект",
    category: "rent",
    price: 70,
    type: "rentSUP",
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:84см",
      "Толщина: 15см",
      "Длина: 350 см",
      "Вес: 9.5 кг",
      "Макс. нагрузка: 145 кг",
    ],
  },
  {
    id: 200054,
    title: "Сап-борд ",
    img: "sup3.jpg",
    desc: "350х84х15 см полный комплект",
    category: "rent",
    price: 70,
    type: "rentSUP",
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:84см",
      "Толщина: 15см",
      "Длина: 350 см",
      "Вес: 9.5 кг",
      "Макс. нагрузка: 145 кг",
    ],
  },
  {
    id: 200443054,
    title: "Гермомешок плотный 30L",
    img: "IMG_8384.PNG",
    desc: "красный",
    price: 5,
    category: "rent",
    type: "rentSUP",
    column: [],
  },
  {
    id: 2004343054,
    title: "Гермомешок плотный",
    img: "IMG_8380.png",
    desc: "Old River 40L (зеленый)	",
    price: 5,
    category: "rent",
    type: "rentSUP",
    column: [],
  },
  {
    id: 20043054,
    title: "Жилет для сап-бордов Hello Plus",
    img: "IMG_0666.JPG",
    desc: "красный, 3 фастекса",
    price: 7,
    category: "rent",
    type: "rentSUP",
    column: [
      "Наполнитель жилета- пена",
      "Внешний материал жилета- оксфорд",
      "Для максимального комфорта, жилет имеет стяжки, благодаря которым можно регулировать размер",
    ],
  },
  {
    id: 1000146,
    title: "Водонепроницаемый чехол",
    img: "packageBTRACE.jpg",
    desc: "для телефона (плавающий)	",
    category: "rent",
    type: "rentSUP",
    price: 3,
    column: [
      "Водонепроницаемость до 2 метров, в течение 30 минут при закрытии",
      "Cтандарт IPX8 / легкая и быстрая застежка-молния",
      "Регулируемая веревка для переноски это позволяет использовать сенсорные функции за пределами воды",
      "Размеры корпуса ширина 110 мм / высота 210 мм / толщина 8.5 мм",
      "Вес: 40 г",
      "Максимальный размер телефона: ширина 90 мм / высота 160 мм.",
    ],
  },
  {
    id: 2040016324,
    title: "Портативный аккумулятор ",
    img: "xiaomi-redmi-fast-charge-power-bank-20000-mah-18w.png",
    desc: "Powerbank Xiaomi Redmi Fast Charge 20000 mAh 18W	",
    category: "rent",
    type: "rentTECHNICS",
    price: 4,
    column: [
      "Количество выходных разъемов USB Type-A: 2шт",
      "Входные разъемы на корпусе: USB Type-C, micro USB",
      "Вес: 445 г",
      "Высота: 154 мм",
      "Ширина:  73.6 мм",
      "Глубина:  27.3 мм",
    ],
  },
  {
    id: 204001324,
    title: "Комплект: 2 радиостанций Baofeng BF-888s	",
    img: "raciya_baofeng_bf-888s_komplekt_2.png",
    desc: "работает в частотах от 400-470 МГц",
    category: "rent",
    type: "rentTECHNICS",
    price: 10,
    column: [
      "Baofeng BF-888S [2 Pack] – комплект из 2 миниатюрных раций с дальностью связи до 7 километров",
      "Каждое устройство оснащено емким аккумулятором 1500 мАч, что гарантирует до 10 часов автономной работы рации на одном заряде аккумулятора",
      "Комплект поставки: 2 х Радиостанция / 2 х Антенна / 2 х Аккумулятор / 2 х Клипса на пояс / 2 х Гарнитура / 2 х Зарядное устройство / Инструкция",
      "Страна производитель: КИТАЙ",
      "Мощность	5 Вт",
      "Размеры устройства: 115x60x33 мм",
      "Количество каналов: 16",
      "Рабочая температура: От -20 °C до +60 °C",
      "Питание: Li-ion аккумулятор, 3.7 В",
      "Вес изделия: 620 г",
    ],
  },
  {
    id: 20001324,
    title: "БИНОКЛЬ QUECHUA MH B560",
    img: "quechuabinoculars.jpg",
    desc: "12-кратное приближение",
    type: "rentTECHNICS",
    category: "rent",
    price: 15,
    shortly:
      "Компактный и легкий бинокль с простой регулировкой. Идеален для обзора панорамы во время походов. Большие объективы (32 мм) и специальное покрытие оптики для обеспечения четкости изображения. Подходит для тех, кто носит очки, благодаря выдвижному глазку, который можно откинуть назад.",
    column: [
      "Вес: 333г",
      "Оправа : 100% Поликарбонат",
      "В комплекте шнурок на шею и футляр из полиэстера для защиты от ударов и пыли, а также для легкости переноски.",
    ],
  },
  {
    id: 20901324,
    title: "Экшн-камера Sony FDR-X3000",
    img: "sony.png",
    desc: "8.2МП, 3840x2160 с защитным боксом",
    category: "rent",
    type: "rentTECHNICS",
    price: 40,
    column: [
      "Kачество съемки: UHD 4K",
      "тип стабилизатора изображения: оптический",
      "Беспроводная связь: Bluetooth, GPS, NFC, Wi-Fi",
      "Число пикселей матрицы: 8.20 МП",
      "Число кадров в секунду при 1920x1080: 120",
      "Число кадров в секунду при разрешении 4K: 30",
      "Время работы от аккумулятора: 2.35 ч",
      "Вес: 114 г",
      "Режимы съемки: замедленная, ночная",
    ],
  },
];
