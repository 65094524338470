export const PRODUCT = [
  {
    id: 1030032343110,
    title: "Маска",
    img: "IMG_6870.JPG",
    desc: "Shark Fit",
    category: "skigoggles",
    price: 70,
    newPrice: 55,
    column: [
      "Размер: Универсальный",
      "Вид линзы: Одинарные",
      "Форма линзы: Торическая",
      "Материал линз: Поликарбонат",
      "Материал оправы: ABS пластик",
      "Защита от запотевания, Совместимость со шлемом",
      "Поляризация",
    ],
  },
  {
    id: 9330034414100,
    title: "Шорты защитные",
    img: "18.38.22.jpg",
    desc: "S-XXL",
    category: "thermalUnderwear",
    price: 118,
    newPrice: 88,
    column: [
      "Защита копчика",
      "Трикотажная ткань из микрофибры, высокая эластичность",
      "С амортизирующими подушками",
      "Для катания на лыжах, коньках, велоспорта, четырехсезонные",
      "Ручная стирка, не требующая химчистки",
      "92% Полиэстер,8% Спандекс",
    ],
  },
  {
    id: 103003426034311060,
    title: "Шлем MOON",
    img: "19.18.34.png",
    desc: "синий и оранжевый",
    category: "skigoggles",
    price: 190,
    column: [
      "Съемные очки",
      "Съемный регулятор",
      "Съемная пряжка",
      "Из ПК и пенополистирола",
      "Утолщеная подкладка",
    ],
  },
  {
    id: 10300342634311060,
    title: "Шлем MOON",
    img: "9.10.09.jpg",
    desc: "регулируемым размер",
    category: "skigoggles",
    price: 178,
    column: [
      "изготовленный из цельного поликарбоната",
      "с дышащими вентиляционными отверстиями",
      "c регулируемым размером головы",
      "c подбородочным ремнем, фиксатором для очков, съемными ушными подушечками для взрослых мужчин и женщин",
    ],
  },
  {
    id: 103003263431106,
    title: "Горнолыжный шлем взрослый",
    img: "yetiskin-kayak-kaski-g.avif",
    desc: "56-59 см, 59-62 см",
    category: "skigoggles",
    order: true,
    price: 140,
    column: [
      "Разработан для нерегулярных занятий на трассе. Простой шлем, чтобы безопасно совершать свои первые виражи на склоне.",
      "Фиксация: Колесико для регулировки по обхвату головы сзади.",
      "Вентиляция: 6 отверстий для вентиляции и отвода пота.",
      "Вес 475 г для размера М.",
      "Сохранение тепла: Тонкие наушники и комфортная пена.",
      "Легкость использования: Фиксация маски двумя застёжками, соединёнными со шлемом.",
      "Легкость ухода: Съемные вставки из пеноматериала стираются в машине при 30°С.",
    ],
  },
  {
    id: 103003263454331108,
    title: "Горнолыжный шлем подросткой",
    img: "cocuk-kayak-kaski.png",
    desc: "48-52 см, 53-56 см",
    category: "skigoggles",
    price: 120,
    column: [
      "Этот простой и прочный шлем идеален для обучения горнолыжному спорту в полной безопасности.",
      "Фиксация: Колесико для регулировки по обхвату головы сзади.",
      "Вентиляция: 6 отверстий для вентиляции и отвода пота.",
      "Вес 410 г для размера S.",
      "Сохранение тепла: Тонкие наушники и комфортная пена.",
      "Легкость использования: Фиксация маски двумя застёжками, соединёнными со шлемом.",
      "Легкость ухода: Съемные вставки из пеноматериала стираются в машине при 30°С.",
    ],
  },
  {
    id: 103040323343110,
    title: "Маска",
    img: "36f_17303055608.png",
    desc: "Shark Fit",
    category: "skigoggles",
    price: 70,
    column: [
      "Cменные зеркальные линзы",
      "Защита от запотевания и УФ-излучения",
    ],
  },
  {
    id: 1030403237743110,
    title: "Маска",
    img: "4181e24a-ebc3-4f13-a857-f9b67a82568e.webp",
    desc: "ONE SKI GOGGLES",
    category: "skigoggles",
    price: 85,
    column: [
      "Cменные зеркальные линзы",
      "Поляризационные",
      "Двухслойная, противотуманная, классическая маска",
      "Маска UV400",
    ],
  },
  {
    id: 10304032343110,
    title: "Маска",
    img: "snowboard-maskesi.png",
    desc: "Wedze",
    category: "skigoggles",
    price: 85,
    order: true,
    column: [
      "На 100% анти-УФ. Желтая линза S1 для тумана и снегопада.",
      "Антизапотевающее покрытие ESSENTIEL, двойная линза с антизапотевающим покрытием.",
      "Цилиндрическая линза, широкий обзор.",
      "Широкая резинка – 40 мм.",
      "1 клипса для регулировки длины ремешка.",
    ],
  },

  {
    id: 1033003234110,
    title: "Маска",
    img: "IMG_6872.JPG",
    desc: "Shark Fit",
    category: "skigoggles",
    order: true,
    price: 70,
    column: [
      "Размер: Универсальный",
      "Вид линзы: Одинарные",
      "Форма линзы: Торическая",
      "Материал линз: Поликарбонат",
      "Материал оправы: ABS пластик",
      "Защита от запотевания, Совместимость со шлемом",
      "Поляризация",
    ],
  },
  {
    id: 10323003234110,
    title: "Маска",
    img: "IMG_6873.JPG",
    desc: "Shark Fit",
    category: "skigoggles",
    price: 70,
    column: [
      "Размер: Универсальный",
      "Вид линзы: Одинарные",
      "Форма линзы: Торическая",
      "Материал линз: Поликарбонат",
      "Материал оправы: ABS пластик",
      "Защита от запотевания, Совместимость со шлемом",
      "Поляризация",
    ],
  },
  {
    id: 103230013234110,
    title: "Маска",
    img: "IMG_8841.PNG",
    desc: "YUEDIO",
    category: "skigoggles",
    price: 116,
    column: [
      "Легкая, что позволяет носить их без давления",
      "Двухслойные линзы эффективно предотвращают запотевание",
    ],
  },
  {
    id: 101323003234110,
    title: "Маска",
    img: "IMG_8843.PNG",
    desc: "YUEDIO",
    category: "skigoggles",
    price: 116,
    column: [
      "Легкая, что позволяет носить их без давления",
      "Двухслойные линзы эффективно предотвращают запотевание",
    ],
  },
  {
    id: 103230032341109,
    title: "Маска",
    img: "IMG_8842.PNG",
    desc: "YUEDIO",
    category: "skigoggles",
    price: 116,
    column: [
      "Легкая, что позволяет носить их без давления",
      "Двухслойные линзы эффективно предотвращают запотевание",
    ],
  },
  {
    id: 103230032344110,
    title: "Маска",
    img: "IMG_6874.JPG",
    desc: "Shark Fit",
    category: "skigoggles",
    order: true,
    price: 70,
    column: [
      "Размер: Универсальный",
      "Вид линзы: Одинарные",
      "Форма линзы: Торическая",
      "Материал линз: Поликарбонат",
      "Материал оправы: ABS пластик",
      "Защита от запотевания, Совместимость со шлемом",
      "Поляризация",
    ],
  },
  {
    id: 1032300382344110,
    title: "перчатки",
    img: "001405-2-yinhe.png_468x468Q75.jpg_.avif",
    desc: "Goski",
    category: "gloves",
    price: 42,
    column: [
      "Состав: Мех, нейлон",
      "Фактура материала: Флисовый",
      "Материал подкладки: Толстый флис; мех",
      "Для сенсорных экранов; светоотражающие элементы; утепленные",
      "Температурный режим: От +10 °C до -30 °C",
      "Ладонь оснащена нескользящей силиконовой поверхностью, которая защищает аксессуар от быстрого износа и обеспечивает крепкий хват.",
    ],
  },
  {
    id: 10323003892344110,
    title: "перчатки",
    img: "IMG_7228.png",
    desc: "Goski",
    category: "gloves",
    price: 42,
    column: [
      "Состав: Мех, нейлон",
      "Фактура материала: Флисовый",
      "Материал подкладки: Толстый флис; мех",
      "Для сенсорных экранов; светоотражающие элементы; утепленные",
      "Температурный режим: От +10 °C до -30 °C",
      "Ладонь оснащена нескользящей силиконовой поверхностью, которая защищает аксессуар от быстрого износа и обеспечивает крепкий хват.",
    ],
  },
  {
    id: 10399390382344110,
    title: "перчатки",
    img: " 20.36.32.jpg",
    desc: "Pita",
    category: "gloves",
    price: 42,
    column: [
      "Фактура материала: плащевая ткань",
      "Материал подкладки: полиэстер",
    ],
  },
  {
    id: 10399390308884110,
    title: "перчатки",
    img: "IMG_7227.png",
    desc: "Proud life",
    category: "gloves",
    price: 42,
    column: ["Материал подкладки: полиэстер"],
  },
  {
    id: 103993940438884110,
    title: "перчатки",
    img: "e39a3.png",
    desc: "Sports(розовые и салатовые)",
    category: "gloves",
    price: 55,
    column: ["Флисовая подкладка", "Материал: Полиэстер"],
  },
  {
    id: 10393939038884110,
    title: "перчатки",
    img: "05999887307cb1b7dfca05fcbadaccc6.webp",
    desc: "Sports",
    category: "gloves",
    price: 55,
    column: ["Флисовая подкладка", "Материал: Полиэстер"],
  },

  {
    id: 109932333441100,
    title: "Термобелье мужское",
    img: "5dbd5040c34.png",
    desc: "S, M, L, XL, XXL",
    category: "thermalUnderwear",
    price: 58,
    column: [
      "94% Полиэстер,6% Эластан",
      "Машинная стирка, не подвергать химчистке",
    ],
  },
  {
    id: 99333441100,
    title: "Термобелье",
    img: "19.38.07.jpg",
    desc: "зеленое, серое, черное  от 48 до 54",
    category: "thermalUnderwear",
    price: 132,
    column: [
      "Состав комплекта: кальсоны, лонгслив",
      "Состав материала: полиэстер 92%, спандекс 8%",
      "Особенности: быстросохнущая, влагоотведение, плоские швы",
    ],
  },
  {
    id: 100032333441100,
    title: "Термобелье мужское Viking",
    img: " 21.04.10.jpg",
    desc: "S, L",
    category: "thermalUnderwear",
    price: 210,
    column: [
      "Термобелье Fusion Man Set от Viking – отличный выбор для тех, кто ищет исключительную защиту от холода и комфорт во время активного отдыха.",
      "Материал быстро сохнет, поэтому кожа остается сухой даже при интенсивных нагрузках.",
      "Благодаря своей гибкости и прилегающему крою он идеально моделирует фигуру и обеспечивает свободу движений в любой ситуации.",
      "В комплект также входит дополнительная маска, защищающая шею и подбородок от холода и ветра.",
      "Комплект термобелья имеет плотность материала 320 г/м2.",
      "59% полиамид: обеспечивает прочность, устойчивость к износу и эффективное отведение влаги.",
      "33% полипропилен: улучшает теплоизоляцию и ускоряет процесс высыхания.",
      "8% эластан: добавляет эластичность, обеспечивая плотное прилегание и свободу движений.",
    ],
  },
  {
    id: 1000323334414100,
    title: "Термобелье мужское Viking",
    img: "2024-12000.png",
    desc: "M, L, XL",
    category: "thermalUnderwear",
    price: 180,
    column: [
      "Термобелье Lockness Man Set от Viking – решение для тех, кто ценит качество, комфорт и защиту во время активного отдыха.",
      "Главной особенностью Lockness Man Set является использование пряжи из бамбукового волокна, которая обладает природными антибактериальными, гипоаллергенными и дезодорирующими свойствами",
      "Бесшовная конструкция обеспечивает максимальный комфорт во время движения.",
      "Плотность ткани комплекта составляет 135 г/м2.",
      "65% полиэстер: отвечает за быстрое отведение влаги от тела и сохранение тепла.",
      "30% вискоза (бамбуковое волокно): добавляет мягкость, улучшает терморегуляцию и обладает антибактериальными свойствами.",
      "5% эластан: обеспечивает эластичность и комфорт при движении.",
    ],
  },
  {
    id: 100999953334414100,
    title: "Термобелье женское Viking",
    img: "gCEXMm7YJAle16EPmlI0ZGtmM14tfX.jpg",
    desc: "S, M, L",
    category: "thermalUnderwear",
    price: 165,
    column: [
      "Нижнее белье Viking Base Layer ilsa Lady Set black имеет полоски от пота и усиления на коленях и локтях, которые защищают их от ударов.",
      "Техническое и эластичное термоактивное белье гарантирует хороший отвод влаги и правильное сохранение тепла в холодные и морозные дни.",
      "Его можно использовать как дополнительный слой или как самостоятельную одежду для различных занятий.",
      "Бесшовная структура позволяет нижнему белью идеально прилегать к телу и не сминаться.",
    ],
  },
  {
    id: 93334414100,
    title: "Термобелье женское Viking",
    img: "nqhspqU6Qytpx1xvIBTu4ycpUqkhTV.jpg",
    desc: "S, M, L",
    category: "thermalUnderwear",
    price: 165,
    column: [
      "Нижнее белье Viking Ilsa имеет полоски от пота и усиления на коленях и локтях, которые защищают их от ударов.",
      "Техническое и эластичное термоактивное белье гарантирует хороший отвод влаги и правильное сохранение тепла в холодные и морозные дни.",
      "Его можно использовать как дополнительный слой или как самостоятельную одежду для различных занятий.",
      "Бесшовная структура позволяет нижнему белью идеально прилегать к телу и не сминаться.",
    ],
  },
  {
    id: 933344414100,
    title: "Термобелье женское Viking",
    img: "0.57.36.jpg",
    desc: "M, L, XL",
    category: "thermalUnderwear",
    price: 165,
    column: [
      "Термобелье мужское ViKing Baselayer Dante Man Set",
      "Нижнее белье имеет полоски от пота и усиления на коленях и локтях, защищающие от ударов.",
      "Техническое и эластичное термоактивное белье гарантирует хороший отвод влаги и правильное сохранение тепла в холодные и морозные дни.",
      "Его можно использовать как дополнительный слой или как самостоятельную одежду для различных занятий.",
      "Бесшовная структура делает белье идеально прилегающим к телу и не мнущимся.",
      "",
    ],
  },

  {
    id: 93300934414100,
    title: "Защита коленей, локтей, запястья",
    img: "8.42.28.jpg",
    desc: "",
    category: "thermalUnderwear",
    price: 115,
    column: [],
  },
  {
    id: 10003233341100,
    title: "Балаклава",
    img: "12.42.11.jpg",
    desc: "",
    category: "buff",
    price: 24,
    column: ["C УФ-защитой", "Дышащая полиэстеровая маска для взрослых"],
  },
  {
    id: 199903233341100,
    title: "Балаклава",
    img: "21.11.34.jpg",
    desc: "cалатовая, оранжевая, синяя, черная",
    category: "buff",
    price: 45,
    column: [
      "85% полиэстер, 15% спандэкс",
      "Дышащая полиэстеровая маска для взрослых",
    ],
  },
  {
    id: 10044433341100,
    title: "Балаклава",
    img: "maskesi-siyah.png",
    desc: "ФЛИСОВАЯ ДЕТСКАЯ ",
    category: "buff",
    price: 30,
    column: [
      "Мягкость флиса для обеспечения тепла и комфорта",
      "Дышащая полиэстеровая маска для взрослых",
    ],
  },
  {
    id: 100043233341100,
    title: "Балаклава",
    img: "45ecf31c-118f-41d5-8659-c05701cc3177_800x800.webp",
    desc: "",
    category: "buff",
    price: 19,
    column: [
      "Основной материал: Полиэстер (полиэфирное волокно)",
      "Можно использовать в качестве подкладки для шлема, и это обеспечивает более полную защиту от солнца при путешествии в шлеме.",
      "Высокая эластичность и отсутствие стягивания",
      "подходит для всех форм лица",
    ],
  },
  {
    id: 1000443322333401100,
    title: "Балаклава",
    img: "2-a565-7d36a6734.png",
    desc: "",
    category: "buff",
    price: 30,
    column: ["Состав: 40% Полиэстер,42% Полиамид,18% Спандекс"],
  },
  {
    id: 10004432333401100,
    title: "Балаклава",
    img: "0cd6c87a-ea51-434b-af64.png",
    desc: "",
    category: "buff",
    price: 30,
    column: ["Состав: 40% Полиэстер,42% Полиамид,18% Спандекс"],
  },
  {
    id: 1000532334110,
    title: "БАФФ",
    img: "a859ba2a23244dffbe4752de732459cb-goods.webp",
    desc: "",
    category: "buff",
    price: 25,
    column: ["95% Полиэстер, 5% Эластан"],
  },
  {
    id: 100053234110,
    title: "БАФФ",
    img: "picture.png",
    desc: "MT500",
    category: "buff",
    price: 25,
    column: [
      "Мериносовая шерсть обеспечивает хороший баланс тепла и компактного дизайна",
      "Существует 10 способов ношения этого изделия",
    ],
  },
  {
    id: 1043044034110,
    title: "Бафф Wedze",
    img: "adult-ski-neck-warmer.avif",
    desc: "(сиреневый и черный) флис",
    category: "buff",
    price: 19,
    column: [
      "Изделие из 100% вторичного полиэстера, что гарантирует его хорошее использование",
      "Мягкость и тепло флисового материала.",
      "Легкий и компактный. Легко складывается в карман или в рюкзак.",
    ],
  },
  {
    id: 1043230034110,
    title: "Бафф Wedze",
    img: "2579353.png",
    desc: "(красный и розовый) трикотаж",
    category: "buff",
    price: 20,
    column: [
      "Комбинация 2 материалов позволяет носить шарф-воротник 6 различными способами.",
      "Если надеть повязку стороной из джерси к лицу, гарантируется отвод пота наружу.",
    ],
  },
  {
    id: 10003234110,
    title: "БАФФ",
    img: "ed64224e6e76406db9631b009cb8ba2f-goods.webp",
    desc: "MT500",
    category: "buff",
    price: 25,
    column: ["дышащий, ветрозащитный", "полиэстер 95%, эластан 5%"],
  },

  {
    id: 104332230034110,
    title: "Бафф Forclaz - MT100 ",
    img: "100036856709b0.jpg",
    desc: "полиэстер; эластан",
    category: "buff",
    price: 25,
    column: [
      "90% полиэстер/10% эластан",
      "Сезон: демисезон",
      "Назначение: для треккинга",
    ],
  },
  {
    id: 1000110,
    title: "Бафф Roadr 100",
    img: "buff.jpg",
    desc: "(салатовый, серый)",
    category: "buff",
    price: 25,
    column: [
      "Матовый флисовый компонент с внутренней стороны - сохраняет тепло, а снаружи - очень тонкий полиэстер, отводящий пот",
      "Его можно носить в разных вариантах, использовать в разных погодных условиях",
      "Стирать в стиральной машине при температуре 30°C (используйте синтетическую программу)",
    ],
  },
  {
    id: 104300110,
    title: "Бафф KIPRUN",
    img: "890f111c9e23173ec90d287f054bc.png",
    desc: "трикотаж",
    category: "buff",
    price: 25,
    column: [
      '"Дышащий" трикотаж способствует отводу пота и быстро сохнет.',
      "Материал повязки поддерживает оптимальную температуру тела во время бега.",
      "Универсальность: повязка, шапка, снуд и т. д.",
      "Универсальная повязка для обхвата головы около 59 см. Эластичный материал повязки позволяет подогнать ее к обхвату головы 51–63 см.",
    ],
  },
  {
    id: 10430011009,
    title: "Бандана - бафф",
    img: "6698800576.jpg",
    desc: "Simond",
    category: "buff",
    price: 25,
    column: [
      "Материал ПЭТ (Полиэтилентерефталат)",
      "Длина товара в упаковке, мм 320",
    ],
  },
  {
    id: 100022229,
    titleEng: "",
    title: "Палатка",
    img: "ed3b9.png",
    descEng: "2-person, double-layer 130*210*106",
    desc: "2-х местная 140*200",
    category: "tent",
    price: 95,
    shortlyEng: "",
    shortly: "",
    column: [
      "Закрытие палатки: Молния",
      "Форма изделия: Овал",
      "Уровень водонепроницаемости: Нормальный водонепроницаемый",
      "Материал кронштейна: Стекловолокно",
      "Материал ткани: Оксфордская ткань",
    ],
    columnEng: [],
  },
  {
    id: 10006,
    titleEng: "TENT QUECHUA MH-100",
    title: "ПАЛАТКА QUECHUA MH-100",
    img: "DECATHLON2ух.jpg",
    descEng: "2-person, double-layer 130*210*107",
    desc: "2-х местная двухслойная 130*210*107",
    category: "tent",
    price: 230,
    shortlyEng:
      "Simple and easy to set up, this eco-friendly tent is designed for your camping trips for 2 people. An environmentally friendly and affordable tent. Thanks to the freestanding dome-shaped structure, you can move the tent to choose the most suitable camping spot for you.",
    shortly:
      "Простая и легкая в установке, эта эко-палатка предназначена для ваших лагерей на 2 человек. Экологичная и недорогая палатка. Благодаря отдельно стоящей куполообразной конструкции вы можете перемещать палатку, чтобы выбрать наиболее подходящее для вас место для кемпинга.",
    column: [
      "Комната: 130*210 см",
      "Рост: 107см",
      "Размер корпуса: 58*16*16 см / 12 л",
      "Вес: 2.6 кг ",
      "Ткань с защитой SPF 30: Пропускает дневной свет, фильтрует УФ-лучи",
      "Водостойкость навес (мм в. ст.): 2000",
      "Водостойкость дна (мм в. ст.): 2400",
    ],
    columnEng: [
      "Room size: 130*210 cm",
      "Height: 107 cm",
      "Tent body size: 58*16*16 cm / 12 liters",
      "Weight: 2.6 kg",
      "Fabric with SPF 30 protection: Allows daylight to pass through, filters UV rays",
      "Rainfly waterproof rating (mm H2O): 2000",
      "Floor waterproof rating (mm H2O): 2400",
    ],
  },
  {
    id: 10007,
    titleEng: "TENT QUECHUA MH-100",
    title: "ПАЛАТКА QUECHUA MH-100",
    img: "tent3dec.jpg",
    descEng: "3-person, double-layer 130*210*107",
    desc: "3-х местная двухслойная 130*210*107",
    category: "tent",
    order: true,
    price: 310,
    shortlyEng:
      "Simple and easy to set up, this eco-friendly tent is designed for your 3-person camping trips. With a freestanding dome structure, you can move the tent to choose the most suitable camping spot for you.",
    shortly:
      "Простая и легкая в установке, эта эко-палатка предназначена для ваших лагерей на 3 человека. Благодаря отдельно стоящей куполообразной конструкции вы можете перемещать палатку, чтобы выбрать наиболее подходящее для вас место для кемпинга.",
    column: [
      "Задняя вентиляция",
      "Выдерживает ветер силой 5 баллов (40 км/ч)",
      "Комната 195*210 см",
      "Высота: 120 см",
      "Размер корпуса: 59*17*17 см / 13 л",
      "Вес: 3.4 кг",
      "Ткань с защитой SPF 30: Прропускает дневной свет, фильтрует УФ-лучи",
      "Водостойкость навес (мм в. ст.): 2000",
      "Водостойкость дна (мм в. ст.): 2400",
    ],
    columnEng: [
      "Rear ventilation",
      "Withstands wind force of 5 points (40 km/h)",
      "Room size: 195*210 cm",
      "Height: 120 cm",
      "Tent body size: 59*17*17 cm / 13 liters",
      "Weight: 3.4 kg",
      "Fabric with SPF 30 protection: Allows daylight to pass through, filters UV rays",
      "Rainfly waterproof rating (mm H2O): 2000",
      "Floor waterproof rating (mm H2O): 2400",
    ],
  },
  {
    id: 10009,
    titleEng: "TENT QUECHUA MH-100 FRESH&BLACK",
    title: "ПАЛАТКА QUECHUA MH-100 FRESH&BLACK",
    img: "camping-tent-mh100-2-person-fresh-and-black.jpg",
    descEng: "2-person, double-layer 130*210*106",
    desc: "2-х местная двухслойная 130*210*106",
    category: "tent",
    price: 389,

    shortlyEng:
      "The Fresh&Black two-person tent, which is easy and quick to set up. Thanks to its freestanding dome structure, it can be moved to a better location after setup.",
    shortly:
      "Двухместную палатку Fresh&Black, которую легко и просто установить. Благодаря отдельно стоящей купольной конструкции его можно переместить в лучшее место после установки.",
    column: [
      "Задняя вентиляция",
      "Выдерживает ветер силой 5 баллов (40 км/ч)",
      "Комната 130*210 см",
      "Высота: 106 см",
      "Размер сумки: 58*15*14 см / 10 л",
      "Вес: 3 кг",
      "Ткань с защитой SPF 30: Прропускает дневной свет, фильтрует УФ-лучи",
      "Водостойкость навес (мм в. ст.): 2000",
      "Водостойкость дна (мм в. ст.): 2400",
    ],
    columnEng: [
      "Rear ventilation",
      "Withstands wind force of 5 points (40 km/h)",
      "Room size: 130*210 cm",
      "Height: 106 cm",
      "Bag size: 58*15*14 cm / 10 liters",
      "Weight: 3 kg",
      "Fabric with SPF 30 protection: Allows daylight to pass through, filters UV rays",
      "Rainfly waterproof rating (mm H2O): 2000",
      "Floor waterproof rating (mm H2O): 2400",
    ],
  },

  {
    id: 100013,
    titleEng: "TENT BTRACE CHALLENGE 2",
    title: "ПАЛАТКА BTRACE CHALLENGE 2",
    img: "tent20.png",
    descEng: "2-person, double-layer 300*210*120",
    desc: "2-х местная двухслойная, 300*210*120",
    category: "tent",
    price: 650,
    shortlyEng:
      "Two-layer tent with two entrances. The outer tent is UV-resistant. It also has a fire-retardant coating. It features a large spacious vestibule, two ventilation valves, and reflective guy lines and elements on the ventilation. All seams are sealed. Ideal for hiking in spring, summer, and autumn.",
    shortly:
      "Двухслойная палатка с двумя входами. Внешний тент палатки устойчив к ультрафиолетовому излучению. Внешний тент имеет пропитку, задерживающую распространение огня. Большой вместительный тамбур. Два вентиляционных клапана. Светоотражающие оттяжки и элементы на вентиляции. Все швы проклеены. Идеальна для туристических походов в весеннее, летнее и осеннее время.",
    column: [
      "Производитель: BTRACE",
      "Материал каркаса: Durapol 8,5 мм",
      "Материал тента: 100% полиэстер 75D/190Т RipStop",
      "Водостойкость тента (мм в. ст): 6000",
      "Материал дна: 100% полиэстер 75D/190Т",
      "Водостойкость дна (мм в. ст.): 8000",
      "Материал внутренней палатки: 100% полиэстер",
      "Цвет внутренней палатки: Желтый",
      "Вес (г): 4000",
      "Количество слоев: 2",
      "Устойчивость к ультрафиолетовому излучению: Да",
      "Количество мест: 2",
      "Количество входов: 2",
      "Комплектация: Колышки, оттяжки, инструкция",
      "Размер в упаковке (см): 57*17,5*15,5",
      "Высота (см): 120",
      "Наружний габарит (см): 300*210",
      "Тамбур (см): 1*100, 1*50",
      "Упаковка: Тканевая сумка",
    ],
    columnEng: [
      "Manufacturer: BTRACE",
      "Frame Material: Durapol 8.5 mm",
      "Tent Material: 100% Polyester 75D/190T RipStop",
      "Tent Waterproof Rating (mm water column): 6000",
      "Floor Material: 100% Polyester 75D/190T",
      "Floor Waterproof Rating (mm water column): 8000",
      "Inner Tent Material: 100% Polyester",
      "Inner Tent Color: Yellow",
      "Weight (g): 4000",
      "Number of Layers: 2",
      "UV Resistance: Yes",
      "Number of Seats: 2",
      "Number of Entrances: 2",
      "Equipment: Pegs, guy lines, instructions",
      "Packed Size (cm): 57*17.5*15.5",
      "Height (cm): 120",
      "Overall Dimensions (cm): 300*210",
      "Vestibule (cm): 1*100, 1*50",
      "Packaging: Fabric bag",
    ],
  },
  {
    id: 19804,
    titleEng: "TENT NATUREHIKE CLOUD UP 3 20D SNOW SKIRT",
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 3 20D",
    img: "20DGREYWITHSKIRT.webp",
    descEng: "3-person, double-layer 180*300*110",
    desc: "3-х местная двухслойная 180*300*110",
    category: "tent",
    order: true,
    price: 650,
    shortlyEng: "Ultralight backpacking tent with a skirt.",
    shortly: "Ультралёгкая туристическая палатка с юбкой.",
    column: [
      "Cо снежной юбкой",
      "Тент изготовлен из силиконизированного нейлона 20D",
      "Комната 215*180 см",
      "Высота: 110 см",
      "Юбка по краю тента защитит от снега, дождя и сильного ветра",
      "Основная часть внутренней палатки, выполненная из сетки B3, защищает от насекомых и обеспечивает циркуляцию воздуха",
      "Ткань устойчива к ультрафиолету, не растягивается при намокании",
      "В комплект входит дополнительный пол (футпринт), защищающий дно палатки от повреждений и загрязнения",
      "Вес: 2,05 кг",
      "Палатка комплектуется фирменными колышками Y-образного сечения. Вес одного всего 9 грамм. На каждом из них предусмотрены выемки для фиксации оттяжки и петелька из шнура для извлечения из грунта",
      "Водостойкость навес (мм в. ст.): > 4000",
      "Водостойкость дна (мм в. ст.): > 4000",
      "Чехол для хранения и транспортировки",
    ],
    columnEng: [
      "The tent is made of silicone-coated nylon 20D.",
      "Room size: 215*180 cm.",
      "Height: 110 cm.",
      "The skirt along the edge of the tent will protect from snow, rain, and strong winds.",
      "The main part of the inner tent, made of B3 mesh, protects against insects and provides air circulation.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 2.05 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >4000.",
      "Floor waterproof rating (mm H2O): >4000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 1989804,
    titleEng: "",
    title: "ПАЛАТКА Naturehike Cloud UP 1 210T",
    img: "1-cloudup1-green-210T.jpg",
    descEng: "1-person, double-layer 120*295*110",
    desc: "1-х местная двухслойная 180*300*110",
    category: "tent",
    order: true,
    price: 470,
    shortlyEng: "Ultralight backpacking tent with a skirt.",
    shortly:
      "Ультралегкая одноместная палатка Naturehike Cloud UP1 для пеших, водных и вело-походов. Полный вес 1,57 кг. Легкий вес, компактность в сложенном виде и просторность в разложенном - отличный выбор независимого одиночки.",
    column: [
      "Количество мест: 1",
      "Размер спального места	230 x 110",
      "Водостойкость навес (мм в. ст.): >3000",
      "Водостойкость дна (мм в. ст.): >3000",
      "Материал каркаса: Анодированный алюминий 7001",
      "Размер в сложенном виде (см): 40х12",
      "Материал тента: Полиэстер 210T",
      "Материал дна: 150D Оксфорд",
    ],
    columnEng: [],
  },
  {
    id: 20302,
    titleEng: "TENT NATUREHIKE CLOUD UP 3 210T",
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 3 210T",
    img: "CloudUp3-orange-210T.jpg",
    descEng: "3-person, double-layer 180*300*110",
    desc: "3-х местная двухслойная 180*300*110",
    category: "tent",
    order: true,
    price: 565,
    shortlyEng:
      "Ultralight two-person tent for hiking, water, and biking trips. The spacious vestibule can accommodate a couple of large-sized backpacks.",
    shortly:
      "Ультралегкая двухместная палатка для пеших, водных и вело-походов. В просторный тамбур можно разместить пару рюкзаков внушительных размеров.",
    column: [
      "Каркас изготовлен из очень прочного, но гибкого легированного алюминия марки 7001",
      "Комната 215*180 см",
      "Высота: 100 см",
      "Внешний тент выполнен из тонкого и легкого полиэстера 210T с плетением RipStop и PU пропиткой",
      "Ткань устойчива к ультрафиолету, не растягивается при намокании",
      "В комплект входит дополнительный пол (футпринт), защищающий дно палатки от повреждений и загрязнения",
      "Вес: 2,4 кг",
      "Палатка комплектуется фирменными колышками Y-образного сечения. Вес одного всего 9 грамм. На каждом из них предусмотрены выемки для фиксации оттяжки и петелька из шнура для извлечения из грунта",
      "Водостойкость навес (мм в. ст.): >3000",
      "Водостойкость дна (мм в. ст.): >3000",
      "Чехол для хранения и транспортировки",
    ],
    columnEng: [
      "The frame is made of very durable but flexible alloy aluminum grade 7001.",
      "Room size: 215*180 cm.",
      "Height: 100 cm.",
      "The outer tent is made of thin and lightweight 210T polyester with RipStop weave and PU coating.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 2.4 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >3000.",
      "Floor waterproof rating (mm H2O): >3000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 46003,
    titleEng: "TENT NATUREHIKE CLOUD UP 2 20D SNOW SKIRT",
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 2 20D",
    img: "naturehike_show_skirt.jpg",
    descEng: "2-person, double-layer 150*275*110",
    desc: "2-х местная двухслойная 150*275*110",
    category: "tent",
    order: true,
    price: 610,
    shortlyEng:
      "Ultralight backpacking tent with a skirt, designed for comfortable accommodation of 2 people.",
    shortly:
      "Ультралёгкая туристическая палатка с юбкой, рассчитанная на комфортное размещение 2 человек.",
    column: [
      "Cо снежной юбкой",
      "Тент изготовлен из силиконизированного нейлона 20D",
      "Комната 210*125 см",
      "Высота: 110 см",
      "Юбка по краю тента защитит от снега, дождя и сильного ветра",
      "Основная часть внутренней палатки, выполненная из сетки B3, защищает от насекомых и обеспечивает циркуляцию воздуха",
      "Ткань устойчива к ультрафиолету, не растягивается при намокании",
      "В комплект входит дополнительный пол (футпринт), защищающий дно палатки от повреждений и загрязнения",
      "Вес: 1,7 кг",
      "Палатка комплектуется фирменными колышками Y-образного сечения. Вес одного всего 9 грамм. На каждом из них предусмотрены выемки для фиксации оттяжки и петелька из шнура для извлечения из грунта",
      "Водостойкость навес (мм в. ст.): >4000",
      "Водостойкость дна (мм в. ст.): >4000",
      "Чехол для хранения и транспортировки",
    ],
    columnEng: [
      "The tent is made of silicone-coated nylon 20D.",
      "Room size: 210*125 cm.",
      "Height: 110 cm.",
      "The skirt along the edge of the tent will protect from snow, rain, and strong winds.",
      "The main part of the inner tent, made of B3 mesh, protects against insects and provides air circulation.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 1.7 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >4000.",
      "Floor waterproof rating (mm H2O): >4000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 12001,
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 2 210T",
    titleEng: "TENT NATUREHIKE CLOUD UP 2 210T",
    img: "cloudup2-green-210T.jpg",
    desc: "2-х местная двухслойная 150*275*110",
    descEng: "2-person, double-layer 150*275*110",
    category: "tent",
    price: 554,
    order: true,
    shortlyEng:
      "Ultralight two-person tent for hiking, water, and biking trips. The spacious vestibule can accommodate a couple of large-sized backpacks.",
    shortly:
      "Ультралегкая двухместная палатка для пеших, водных и вело-походов. В просторный тамбур можно разместить пару рюкзаков внушительных размеров.",
    column: [
      "Каркас изготовлен из очень прочного, но гибкого легированного алюминия марки 7001",
      "Комната 210*125 см",
      "Высота: 100 см",
      "Внешний тент выполнен из тонкого и легкого полиэстера 210T с плетением RipStop и PU пропиткой",
      "Ткань устойчива к ультрафиолету, не растягивается при намокании",
      "В комплект входит дополнительный пол (футпринт), защищающий дно палатки от повреждений и загрязнения",
      "Вес: 1,83 кг",
      "Палатка комплектуется фирменными колышками Y-образного сечения. Вес одного всего 9 грамм. На каждом из них предусмотрены выемки для фиксации оттяжки и петелька из шнура для извлечения из грунта",
      "Водостойкость навес (мм в. ст.): >3000",
      "Водостойкость дна (мм в. ст.): >3000",
      "Чехол для хранения и транспортировки",
    ],
    columnEng: [
      "The frame is made of very durable but flexible alloy aluminum grade 7001.",
      "Room size: 210*125 cm.",
      "Height: 100 cm.",
      "The outer tent is made of thin and lightweight 210T polyester with RipStop weave and PU coating.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 1.83 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >3000.",
      "Floor waterproof rating (mm H2O): >3000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 1200941,
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 2 20D",
    titleEng: "TENT NATUREHIKE CLOUD UP 2 20D",
    img: "tent18.png",
    desc: "2-х местная двухслойная",
    descEng: "2-person, double-layer 150*275*110",
    category: "tent",
    price: 580,
    order: true,
    shortlyEng:
      "Ultralight two-person tent for hiking, water, and biking trips. The spacious vestibule can accommodate a couple of large-sized backpacks.",
    shortly:
      "Ультралегкая двухместная палатка для пеших, водных и вело-походов. В просторный тамбур можно разместить пару рюкзаков внушительных размеров.",
    column: [
      "Каркас изготовлен из очень прочного, но гибкого легированного алюминия марки 7001",
      "Комната 210*125 см",
      "Высота: 100 см",
      "Вес: 1.55 кг",
      "Водостойкость навес (мм в. ст.): >4000",
      "Водостойкость дна (мм в. ст.): >4000",
      "Чехол для хранения и транспортировки",
      "Материал тента: Нейлон 20D с силиконовым покрытием",
      "Материал дна	Нейлон 20D с силиконовым покрытием",
      "Материал каркаса	Анодированный алюминий 7001",
      "Размер в сложенном виде (см)	40х13",
    ],
    columnEng: [
      "The frame is made of very durable but flexible alloy aluminum grade 7001.",
      "Room size: 210*125 cm.",
      "Height: 100 cm.",
      "The outer tent is made of thin and lightweight 210T polyester with RipStop weave and PU coating.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 1.83 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >3000.",
      "Floor waterproof rating (mm H2O): >3000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 1204091,
    title: "ПАЛАТКА NATUREHIKE CLOUD UP 2 20D",
    titleEng: "TENT NATUREHIKE CLOUD UP 2 20D",
    img: "tent209.png",
    desc: "2-х местная двухслойная",
    descEng: "2-person, double-layer 150*275*110",
    category: "tent",
    order: true,
    price: 580,
    shortlyEng:
      "Ultralight two-person tent for hiking, water, and biking trips. The spacious vestibule can accommodate a couple of large-sized backpacks.",
    shortly:
      "Ультралегкая двухместная палатка для пеших, водных и вело-походов. В просторный тамбур можно разместить пару рюкзаков внушительных размеров.",
    column: [
      "Каркас изготовлен из очень прочного, но гибкого легированного алюминия марки 7001",
      "Комната 210*125 см",
      "Высота: 100 см",
      "Вес: 1.55 кг",
      "Водостойкость навес (мм в. ст.): >4000",
      "Водостойкость дна (мм в. ст.): >4000",
      "Чехол для хранения и транспортировки",
      "Материал тента: Нейлон 20D с силиконовым покрытием",
      "Материал дна	Нейлон 20D с силиконовым покрытием",
      "Материал каркаса	Анодированный алюминий 7001",
      "Размер в сложенном виде (см)	40х13",
    ],
    columnEng: [
      "The frame is made of very durable but flexible alloy aluminum grade 7001.",
      "Room size: 210*125 cm.",
      "Height: 100 cm.",
      "The outer tent is made of thin and lightweight 210T polyester with RipStop weave and PU coating.",
      "The fabric is resistant to ultraviolet rays and does not stretch when wet.",
      "The kit includes an additional groundsheet (footprint) to protect the tent floor from damage and dirt.",
      "Weight: 1.83 kg.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams. They have notches for attaching guy lines and cord loops for extraction from the ground.",
      "Rainfly waterproof rating (mm H2O): >3000.",
      "Floor waterproof rating (mm H2O): >3000.",
      "Storage and transport bag.",
    ],
  },
  {
    id: 10005,
    titleEng: "TENT NATUREHIKE CLOUD UP UP 1 210T",
    title: "ПАЛАТКА NATUREHIKE CLOUD UP UP 1 210T ",
    img: "clouduporange210T.jpg",
    descEng: "1-person, double-layer 120*295*110",
    desc: "1-местная двухслойная 120*295*110",
    category: "tent",
    price: 390,

    shortlyEng: "Ultralight backpacking tent with a skirt.",
    shortly: "Ультралёгкая туристическая палатка с юбкой.",
    column: [
      "Внешний тент выполнен из тонкого и легкого полиэстера 210T с плетением RipStop и PU пропиткой",
      "Водостойкость навес (мм в. ст.): > 3000",
      "Водостойкость дна (мм в. ст.): > 3000",
      "Размер спального места составляет 230 x 910 x 100 см",
      "Вес: 1,57 кг.",
      "На потолке внутри имеется крючок для подвески фонарика. На стенках удобные кармашки из сетки для различных мелочей, которые будут всегда под рукой",
      "Все швы качественно проклеены специальной лентой препятствуют проникновению влаги через нитки",
      "Каркас изготовлен из очень прочного, но гибкого легированного алюминия марки 7001",
      "Палатка комплектуется фирменными колышками Y-образного сечения. Вес одного всего 9 грамм",
      "Дуги и колышки покрыты электрохимическим анодированием, что не только уберегает их от коррозии",
    ],
    columnEng: [
      "The outer tent is made of thin and lightweight 210T polyester with RipStop weave and PU coating.",
      "Rainfly waterproof rating (mm H2O): >3000.",
      "Floor waterproof rating (mm H2O): >3000.",
      "The sleeping area measures 230 x 910 x 100 cm.",
      "Weight: 1.57 kg.",
      "There is a hook on the ceiling inside for hanging a flashlight. Convenient mesh pockets on the walls for various small items that will always be at hand.",
      "All seams are quality sealed with special tape to prevent moisture from penetrating through the threads.",
      "The frame is made of very durable but flexible alloy aluminum grade 7001.",
      "The tent comes with branded Y-shaped section stakes. Each stake weighs only 9 grams.",
      "The poles and stakes are covered with electrochemical anodizing, which not only protects them from corrosion.",
    ],
  },

  // {
  //   id: 10008,
  //   titleEng: "TENT QUECHUA MH-50",
  //   title: "ПАЛАТКА QUECHUA MH-50",
  //   img: "tentGreen.jpg",
  //   descEng: "2-person, single-layer 140*200*100",
  //   desc: "2-х местная однослойная 140*200*100",
  //   category: "tent",
  //   price: 169,
  //   shortly: "",
  //   column: [
  //     "Вес: 1,7 кг",
  //     "Выдерживает ветер силой 5 баллов (40 км/ч)",
  //     "Размер в чехле: 63 см X 22 см X 22 см",
  //     "Высота: 100 см",
  //     "2 вида вентиляции - москитная сетка двери и сетка потолка палатки",
  //     "Простая установка благодаря 2 полюсам",
  //     "Устойчивость к ветру до 30 км/ч (интенсивность 4): Одобрено испытаниями в аэродинамической трубе",
  //     "Индекс наружной водоустойчивости палатки: 1000-1500 мм",
  //     "Ткань: Нейлон",
  //   ],
  //   columnEng:["Weight: 1.7 kg",
  //   "Withstands wind force of 5 points (40 km/h)",
  //   "Size in the bag: 63 cm X 22 cm X 22 cm",
  //   "Height: 100 cm",
  //   "2 types of ventilation - mesh door and ceiling vents",
  //   "Easy setup with 2 poles",
  //   "Wind resistance up to 30 km/h (intensity 4): Approved by aerodynamic tube tests",
  //   "Outer tent waterproof index: 1000-1500 mm",
  //   "Fabric: Nylon"]
  // },

  // {
  //   id: 100010,
  //   titleEng: "TENT BTRACE VANG 3",
  //   title: "ПАЛАТКА BTRACE VANG 3",
  //   img: "BTRACE-VANG3.png",
  //   descEng: "3-person, double-layer 340*220*120",
  //   desc: "3-х местная двухслойная, 340*220*120",
  //   category: "tent",
  //   price: 687,
  //   shortlyEng:"Double-layer tent with two entrances. The entrance to the sleeping compartment is duplicated with mosquito netting. Increased vestibule, two ventilation valves, all seams are sealed. Ideal for hiking in spring, summer, and fall.",
  //   shortly:
  //     "Двухслойная палатка с двумя входами.Вход спального отделения продублирован москитной сеткой.Увеличенный тамбур, два вентиляционных клапана, все швы проклеены. Идеальна для туристических походов в весеннее, летнее и осеннее время.",
  //   column: [
  //     "Производитель: BTRACE",
  //     "Материал каркаса: Фибергласс 8,5 мм",
  //     "Материал тента: 100% полиэстер 75D/190T PU W/R",
  //     "Водостойкость тента (мм в. ст): 4000",
  //     " Материал внутренней палатки: 100% полиэстер",
  //     "Цвет внутренней палатки: Желтый",
  //     "Вес (г): 3400",
  //     "Количество слоев: 2",
  //     "Устойчивость к ультрафиолетовому излучению:Да",
  //     "Количество мест: 3",
  //     "Количество входов: 2",
  //     "Комплектация: Колышки, оттяжки, инструкция",
  //     "Размер в упаковке (см): 63*16*14",
  //     "Высота (см): 120",
  //     "Наружний габарит (см): 340*220",
  //     "Тамбур (см): 2*80",
  //     "Упаковка: Тканевая сумкa",
  //   ],
  //   columnEng:["Manufacturer: BTRACE",
  //   "Frame material: 8.5 mm Fiberglass",
  //   "Awning material: 100% polyester 75D/190T PU W/R",
  //   "Awning water resistance (mm in. ct): 4000",
  //   " Inner tent material: 100% polyester",
  //   "Color of the inner tent: Yellow",
  //         "Weight (g): 3400",
  //   "Number of layers: 2",
  //   "UV resistance:Yes",
  //         "Number of seats: 3",
  //   "Number of entrances: 2",
  //   "Equipment: Pegs, braces, instructions",
  //         "Package size (cm): 63*16*14",
  //   " Height (cm): 120",
  //         "Outer dimension (cm): 340*220",
  //   "Vestibule (cm): 2*80",
  //   "Packing: Cloth bag",]
  // },
  // {
  //   id: 100011,
  //   titleEng: "TENT BTRACE TRAVEL 2",
  //   title: "ПАЛАТКА BTRACE TRAVEL 2",
  //   img: "BTRACE_TRAVEL2.jpg",
  //   descEng: "2-person, double-layer 250*220*120",
  //   desc: "2-х местная двухслойная, 250*220*120",
  //   category: "tent",
  //   price: 550,
  //   shortlyEng:'Double-layer tent with two entrances. The outer tent of the tent is resistant to ultraviolet radiation. The outer tent has a fire-retardant coating. Two ventilation valves. Reflective guy lines and elements on the ventilation. All seams are glued. Ideal for hiking in spring, summer, and autumn.',
  //   shortly:
  //     "Двухслойная палатка с двумя входами. Внешний тент палатки устойчив к ультрафиолетовому излучению .Внешний тент имеет пропитку, задерживающую распространение огня. Два вентиляционных клапана. Светоотражающие оттяжки и элементы на вентиляции. Все швы проклеены. Идеальна для туристических походов в весеннее, летнее и осеннее время.",
  //   column: [
  //     "Производитель: BTRACE",
  //     "Материал каркаса: Durapol 8,5 мм",
  //     "Материал тента: 100% полиэстер 75D/190Т RipStop",
  //     "Водостойкость тента (мм в. ст): 6000",
  //     "Материал дна: 100% полиэстер 75D/190Т",
  //     "Водостойкость дна (мм в. ст.): 8000",
  //     " Материал внутренней палатки: 100% полиэстер",
  //     "Цвет внутренней палатки: Желтый",
  //     "Вес (г): 3750",
  //     "Количество слоев: 2",
  //     "Устойчивость к ультрафиолетовому излучению:Да",
  //     "Количество мест: 2",
  //     "Количество входов: 2",
  //     "Комплектация: Колышки, оттяжки, инструкция",
  //     "Размер в упаковке (см): 57*17*14,5",
  //     "Высота (см): 120",
  //     "Наружний габарит (см): 250*220",
  //     "Тамбур (см): 2*50",
  //     "Упаковка:Тканевая сумка",
  //   ],
  //   columnEng:[
  //   "Manufacturer: BTRACE",
  //   "Frame material: Durapol 8.5 mm",
  //   "Tent material: 100% polyester 75D/190T RipStop",
  //   "Tent waterproof rating (mm H2O): 6000",
  //   "Floor material: 100% polyester 75D/190T",
  //   "Floor waterproof rating (mm H2O): 8000",
  //   "Inner tent material: 100% polyester",
  //   "Inner tent color: Yellow",
  //   "Weight (g): 3750",
  //   "Number of layers: 2",
  //   "UV resistance: Yes",
  //   "Number of seats: 2",
  //   "Number of entrances: 2",
  //   "Equipment: Stakes, guy lines, instruction manual",
  //   "Packed size (cm): 57*17*14.5",
  //   "Height (cm): 120",
  //   "Outer dimensions (cm): 250*220",
  //   "Vestibule (cm): 2*50",
  //   "Packaging: Fabric bag"]
  // },
  // {
  //   id: 100012,
  //   titleEng: "TENT BTRACE TRAVEL 3",
  //   title: "ПАЛАТКА BTRACE TRAVEL 3",
  //   img: "tent_travel3.png",
  //   descEng: "3-person, double-layer 200*220*120",
  //   desc: "3-х местная двухслойная, 200*220*120",
  //   category: "tent",
  //   price: 679,
  //   shortlyEng:"Two-layer tent with two entrances. The outer tent of the tent is resistant to ultraviolet radiation. The outer tent is impregnated to retard the spread of fire. Two ventilation valves. Reflective guy lines and elements on ventilation. All seams are glued. Ideal for hiking in spring, summer, and autumn.",
  //   shortly:
  //     "Двухслойная палатка с двумя входами. Внешний тент палатки устойчив к ультрафиолетовому излучению. Внешний тент имеет пропитку, задерживающую распространение огня. Два вентиляционных клапана. Светоотражающие оттяжки и элементы на вентиляции. Все швы проклеены. Идеальна для туристических походов в весеннее, летнее и осеннее время.",
  //   column: [
  //     "Производитель: BTRACE",
  //     "Материал каркаса: Durapol 8,5 мм",
  //     "Материал тента: 100% полиэстер 75D/190Т RipStop",
  //     "Водостойкость тента (мм в. ст): 6000",
  //     "Материал дна: 100% полиэстер 75D/190Т",
  //     "Водостойкость дна (мм в. ст.): 8000",
  //     "Материал внутренней палатки: 100% полиэстер",
  //     "Цвет внутренней палатки: Желтый",
  //     "Вес (г): 3900",
  //     "Количество слоев: 2",
  //     "Устойчивость к ультрафиолетовому излучению: Да",
  //     "Количество мест: 3",
  //     "Количество входов: 2",
  //     "Комплектация: Колышки, оттяжки, инструкция",
  //     "Размер в упаковке (см): 57*17*14,5",
  //     "Высота (см):120",
  //     "Наружний габарит (см):320*220",
  //     "Тамбур (см): 2*70",
  //     "Упаковка: Тканевая сумка",
  //   ],
  //   columnEng:[
  //     "Manufacturer: BTRACE",
  //     "Frame material: Durapol 8.5 mm",
  //     "Tent material: 100% polyester 75D/190T RipStop",
  //     "Tent waterproof rating (mm water column): 6000",
  //     "Floor material: 100% polyester 75D/190T",
  //     "Floor waterproof rating (mm water column): 8000",
  //     "Inner tent material: 100% polyester",
  //     "Inner tent color: Yellow",
  //     "Weight (g): 3900",
  //     "Number of layers: 2",
  //     "UV resistance: Yes",
  //     "Number of places: 3",
  //     "Number of entrances: 2",
  //     "Equipment: Pegs, guy lines, instruction",
  //     "Packed size (cm): 57*17*14.5",
  //     "Height (cm): 120",
  //     "External dimensions (cm): 320*220",
  //     "Vestibule (cm): 2*70",
  //     "Packing: Fabric bag"]
  // },
  {
    id: 107700137,
    title: "Ультразвуковой отпугиватель",
    img: "18.51.22.jpg",
    desc: "собак",
    category: "accessories",
    price: 38,
    column: [
      "USB",
      "Перезаряжаемое устройство",
      "Аккумулятор: Литиевая батарея",
      "Одним щелчком можно отогнать собак на 8 метров",
      "Ультразвук, отпугивающий злых собак",
    ],
  },
  {
    id: 10770000137,
    title: "Водонепроницаемый компас",
    img: "59e987c9-037c-4.png",
    desc: "со шкалой 10.5 Х 6.2 см ",
    category: "accessories",
    price: 10,
    column: [],
  },
  {
    id: 1000134,
    title: "Тент нижний, ДЛЯ ПАЛАТКИ И ТАМБУРА",
    img: "kovrik.jpg",
    order: true,
    desc: "2 x 2,5 метра",
    category: "accessories",
    price: 59,
    column: [
      "Вес:593г",
      "Тент легко использовать благодаря металлическим встроенным кольцам",
      "Устойчив к истиранию и перфорации",
      "Состав : 100% водонепроницаемый полиэтилен",
    ],
  },

  {
    id: 1000137,
    title: "УГЛОВЫЕ СТЕРЖНИ (КОЛЫШКИ)",
    img: "pegs.jpg",
    desc: " V-образные ( 10 ШТ )",
    category: "accessories",
    price: 37,
    column: [
      "v-образная форма",
      "Подходят для влажных и рыхлых грунтов",
      "Основной компонент - 100% сталь",
      "Длина 23 см",
      "Ширина : 20 мм",
      "Вec: 50г",
      "Подходит для всех типов палаток",
    ],
  },

  {
    id: 100015437,
    title: "Комплект стальных колышек",
    img: "10-steel-tent-pegs-various-terrains-quechua-1163836.jpg",
    desc: "Круглые ( 10 ШТ )",
    category: "accessories",
    order: true,
    price: 28,
    column: [
      "Для всех кемпинговых палаток на различных рельефах",
      "Основной компонент - 100% сталь",
      "Внимание: для мягкой почвы используйте угловые колышки.",
      "Чтобы продлить срок службы изделия, мы рекомендуем хранить его вместе с остальным туристическим снаряжением. Вдали от влаги, не подвергая его воздействию ультрафиолета.",
      "Вec: 44г",
      "Длина: 18 см",
    ],
  },
  {
    id: 1000415437,
    title: "Колышки из алюминиевого сплава",
    img: "S545914f908.jpg",
    desc: "треугольные ( 1 ШТ )",
    category: "accessories",
    order: true,
    price: 3,
    column: ["Для песка", "Вес: 13 г"],
  },
  {
    id: 1054040415437,
    title: "Колышки из алюминиевого сплава",
    img: "23e514t.jpg_640x640.webp",
    desc: "круглые ( 1 ШТ )",
    category: "accessories",
    price: 4,
    order: true,
    column: ["18 см"],
  },
  {
    id: 105400415437,
    title: "Колышки из алюминиевого сплава",
    img: "97F.jpg_640x640.webp",
    desc: "круглые ( 10 ШТ )",
    category: "accessories",
    price: 29,
    order: true,
    column: ["18 см"],
  },
  {
    id: 1004390415437,
    title: "Эластичный шнурок Quechua",
    img: "hoops-6-metres-quechua.png",
    desc: "6 метров 2,5мм",
    category: "accessories",
    price: 25,
    column: [
      "Длина: 6 м | Диаметр: 2,5 мм | Вес: 10 г",
      "Основная ткань: 100,0% синтетический каучук",
    ],
  },
  {
    id: 100430415437,
    title: "Комплект из 2 стяжек",
    img: "1749019.jpg",
    desc: "по 10 метров",
    category: "accessories",
    price: 27,
    column: [
      "Подходит для любых палаток",
      "Длина: 2 x 10 м ",
      "Стяжка на каждом конце",
      "возможна установка 4 оттяжек.",
    ],
  },
  {
    id: 190014,
    title: "СПАЛЬНЫЙ МЕШОК",
    titleEng: "SLEEPING BAG",
    img: "sleeping_bag_green.jpg",
    descEng: "Hiker (комфорт от +18)",
    desc: "Hiker (комфорт от +18)",
    category: "sleepingbag",
    price: 79,
    order: true,
    columnEng: ["Weight: 960g", "Outer size: 35*26cm"],
    column: ["Вес: 960гр", "Размер внешний: 35*26см", "Размер: 195*60см"],
  },
  {
    id: 100015,
    title: "СПАЛЬНЫЙ МЕШОК QUECHUA ARPENAZ",
    titleEng: "SLEEPING BAG QUECHUA ARPENAZ",
    img: "sleepingBad20.jpg",
    descEng: "190*72cm from +20 to +15°C",
    desc: "190*72см от +20 до +15°C",
    category: "sleepingbag",
    price: 99,
    shortlyEng:
      "This eco-friendly sleeping bag is designed for users who want to sleep comfortably at temperatures up to 20°C while camping.",
    shortly:
      "Этот эко-спальный мешок предназначен для пользователей, которые хотят комфортно спать при температуре до 20°C во время кемпинга.",
    column: [
      "Вес: 755гр",
      "Размер: 190*72 см | Молния от шеи",
      "Объем: 9 л",
      "Наполнитель: 100% полиэстер",
    ],
    columnEng: [
      "Weight: 755g",
      "Size: 190*72 cm | Zipper from the neck",
      "Volume: 9 liters",
      "Filling: 100% polyester",
    ],
  },
  {
    id: 100016,
    title: "СПАЛЬНЫЙ МЕШОК QUECHUA ARPENAZ",
    titleEng: "SLEEPING BAG QUECHUA ARPENAZ",
    img: "sleepingBag+15.jpg",
    descEng: "190*72cm from +15 to +8°C",
    desc: "190*72см от +15 до +8",
    category: "sleepingbag",
    order: true,
    price: 140,
    shortlyEng:
      "This eco-sleeping bag is designed for users who want to sleep comfortably at temperatures up to 15°C during camping.",
    shortly:
      "Этот эко-спальный мешок предназначен для пользователей, которые хотят комфортно спать при температуре до 15°C во время кемпинга.",
    column: [
      "Вес: 755гр",
      "Размер: 190*72 см | Молния от шеи",
      "Объем: 9 л",
      "Наполнитель: 100% полиэстер",
    ],
    columnEng: [
      "Weight: 755g",
      "Size: 190*72 cm | Zipper from the neck",
      "Volume: 9 liters",
      "Filler: 100% polyester.",
    ],
  },
  {
    id: 1030016,
    title: "СПАЛЬНЫЙ МЕШОК QUECHUA ARPENAZ",
    titleEng: "SLEEPING BAG QUECHUA ARPENAZ",
    img: "260654.png",
    descEng: "190*72cm from +5 to +10°C",
    desc: "190*72см от +5 до +10",
    category: "sleepingbag",
    order: true,
    price: 140,
    shortly:
      "Этот эко-спальный мешок предназначен для пользователей, которые хотят комфортно спать при температуре до 15°C во время кемпинга.",
    column: [
      "Вес: 1300гр",
      "Размер: 190*72 см | Молния от шеи",
      "Объем: 15 л",
      "Наполнитель: 100% полиэстер",
    ],
  },
  {
    id: 10001709,
    title: "Спальный мешок Forclaz MT500 ",
    titleEng: "SLEEPING BAG CAMPSOR",
    img: "2585160.png",
    desc: "комфорт 0/ лимит -5",
    descEng: "210*66cm from +10 to 0°C",
    category: "sleepingbag",
    order: true,
    price: 395,
    column: [
      "Комфортная темп.: 0°C / пред. темп.: -5°C.",
      "Наполнитель из синтетической ваты",
      "Вес: 1450 г",
      "Объем: 12,9 л.",
      "Компрессионный чехол",
      "Застегните молнию на 3/4 длины чехла, оснащенного спец. системой застежки-молнии",
      "Регулируемый капюшон с помощью эластичного шнура и фиксатора",
      "Пропитка снаружи (PFC Free) препятствует проникновению воды",
    ],
    columnEng: ["Weight: 1300g", "External size: 33*24cm."],
  },
  {
    id: 1040043317,
    title: "Спальный мешок Desert Fox",
    titleEng: "SLEEPING BAG CAMPSOR",
    img: "HTB1Sgtu.png",
    desc: "оранжевый 190+30*70 см",
    descEng: "210*66cm from +10 to 0°C",
    category: "sleepingbag",
    price: 84,
    order: true,
    column: [
      "Вес: 1500гр",
      "Материал:	Прочная полиэфирная ткань 210T (водонепроницаемая)",
    ],
    columnEng: ["Weight: 1300g", "External size: 33*24cm."],
  },
  {
    id: 10400093317,
    title: "Спальный мешок Desert Fox ",
    titleEng: "SLEEPING BAG CAMPSOR",
    img: "HTB1E__6aLfsK.png",
    desc: "-5 экстрим / комфорт +10/+20",
    descEng: "210*66cm from +10 to 0°C",
    category: "sleepingbag",
    price: 170,
    order: true,
    column: ["Вес: 1800гр", "Материал внутри:	190T синтепон;"],
    columnEng: ["Weight: 1300g", "External size: 33*24cm."],
  },
  // {
  //   id: 100018,
  //   title: "СПАЛЬНЫЙ МЕШОК DESERT FOX",
  //   titleEng: "SLEEPING BAG DESERT FOX",
  //   img: "desert-fox-sleeping.webp",
  //   desc: "180+20*70см от +15 до -5",
  //   descEng:'180+20*70cm from +15 to -5°C',
  //   category: "sleepingbag",
  //   price: 170,
  //   column: ["Вес: 1370гр", "Материал: 100% полиэстерн, полиэфрное волокно"],
  //   columnEng:["Weight: 1370g",
  //   "Material: 100% polyester, polyethylene fiber."]
  // },
  // {
  //   id: 100019,
  //   title: "СПАЛЬНЫЙ МЕШОК DEMI-SEASON",
  //   titleEng: "SLEEPING BAG DESERT FOX",
  //   img: "sleeping_bag_blie.jpg",
  //   desc: "195*60см от +10",
  //   descEng:'195*60cm from +10°C',
  //   category: "sleepingbag",
  //   price: 125,
  //   column: [
  //     "Вес: 1060гр",
  //     "Размер внешний: 40*25см",
  //     "Материал: полиэстерн, СИНТЕПОН",
  //   ],
  //   columnEng:["Weight: 1060g",
  //   "Outer size: 40*25cm",
  //   "Material: polyester, synthetic padding."]
  // },
  {
    id: 1008017,
    title: "Спальный мешок (Mountain)",
    titleEng: "",
    img: "22.01.46.jpg",
    desc: "летний +15",
    descEng: "",
    category: "sleepingbag",
    price: 70,
    column: [],
    columnEng: [],
  },
  {
    id: 100017,
    title: "СПАЛЬНЫЙ МЕШОК CAMPSOR",
    titleEng: "SLEEPING BAG CAMPSOR",
    img: "sleeping_bag_red.jpg",
    desc: "210*66см от +10 до 0",
    descEng: "210*66cm from +10 to 0°C",
    category: "sleepingbag",
    price: 140,
    order: true,
    column: ["Вес: 1300гр", "Размер внешний: 33*24см"],
    columnEng: ["Weight: 1300g", "External size: 33*24cm."],
  },
  {
    id: 100022,
    titleEng: "SLEEPING BAG BTRACE ZERO S SIZE RIGHT",
    title: "СПАЛЬНЫЙ МЕШОК BTRACE ZERO S SIZE ПРАВЫЙ",
    img: "BTRACE_ZERO_S.png",
    descEng: "Super lightweight 190*80(50) from +15 to +5",
    desc: "Супер лёгкий 190*80(50) от +15 до +5 ",
    category: "sleepingbag",
    price: 240,
    shortlyEng:
      "New for the 2021 season - the super lightweight BTrace Zero mummy sleeping bag. It is perfect for short trips in warm weather. Taking up very little space, this sleeping bag will allow you to sleep comfortably in camping conditions. Like other mummy sleeping bags in this series, the BTrace Zero can be zipped together with another. You can also choose one of 2 sizes to save even more space and weight if your height allows you to use a smaller sleeping bag.",
    shortly:
      "Новинка сезона 2021 - супер лёгкий спальный мешок-кокон BTrace Zero. Он идеально подойдёт для небольших путешествий в теплую погоду. Занимая совсем мало места, этот спальный мешок позволит с комфортом переночевать в походных условиях. Как и другие спальные мешки-коконы этой серии, BTrace Zero имеет возможность состёгиваться. Также можно выбрать один из 2х размеров, чтоб сэкономить ещё места и веса, если рост позволяет пользоваться спальным мешком меньшего размера.",
    column: [
      "Производитель:BTRACE",
      "Температура экстрима:0",
      "Температура комфорта:+15",
      "Вес (г):600",
      "Материал внешний: Полиэстер 20D 380Т Nylon RipStop WR",
      "Внутренний материал: 100% полиэстер 210T Soft Nylon",
      "Утеплитель: Izoshell Pro 2*80 г/м2",
      "Размер в упаковке (см): 52х25х25",
      "Размер (см): 190*80(50)",
      "Упаковка: Тканевый мешок",
    ],
    columnEng: [
      "Manufacturer: BTRACE",
      "Extreme temperature: 0°C",
      "Comfort temperature: +15°C",
      "Weight (g): 600",
      "Outer material: Polyester 20D 380T Nylon RipStop WR",
      "Inner material: 100% Polyester 210T Soft Nylon",
      "Insulation: Izoshell Pro 2*80 g/m²",
      "Packed size (cm): 52x25x25",
      "Size (cm): 190x80(50)",
      "Packaging: Fabric bag",
    ],
  },
  {
    id: 100021,
    titleEng: "SLEEPING BAG BTRACE ZERO L SIZE RIGHT",
    title: "СПАЛЬНЫЙ МЕШОК BTRACE ZERO L SIZE ПРАВЫЙ",
    img: "BTRACE_ZERO_L.png",
    descEng: "Super lightweight 220*90(55) from +15 to +5",
    desc: "Супер лёгкий 220*90(55) от +15  до +5",
    category: "sleepingbag",
    price: 269,
    order: true,
    shortlyEng:
      "The 2021 season novelty - the super lightweight BTrace Zero mummy sleeping bag. It is perfect for short trips in warm weather. Taking up very little space, this sleeping bag allows for a comfortable overnight stay in camping conditions. Like other mummy sleeping bags in this series, the BTrace Zero can be zipped together with another bag. Additionally, you can choose one of two sizes to save even more space and weight if your height allows for using a smaller sleeping bag.",
    shortly:
      "Новинка сезона 2021 - супер лёгкий спальный мешок-кокон BTrace Zero. Он идеально подойдёт для небольших путешествий в теплую погоду. Занимая совсем мало места, этот спальный мешок позволит с комфортом переночевать в походных условиях. Как и другие спальные мешки-коконы этой серии, BTrace Zero имеет возможность состёгиваться. Также можно выбрать один из 2х размеров, чтоб сэкономить ещё места и веса, если рост позволяет пользоваться спальным мешком меньшего размера.",
    column: [
      "Производитель:BTRACE",
      "Температура экстрима:0",
      "Температура комфорта:+5",
      "Вес (г):800",
      "Материал внешний: Полиэстер 20D 380Т Nylon RipStop WR",
      "Внутренний материал: 100% полиэстер 210T Soft Nylon",
      "Утеплитель: Izoshell Pro 2*80 г/м2",
      "Размер в упаковке (см): 52*25*25",
      "Размер (см): 220*90(55)",
      "Упаковка: Тканевый мешок",
    ],
    columnEng: [
      "Manufacturer: BTRACE",
      "Extreme temperature: 0°C",
      "Comfort temperature: +5°C",
      "Weight (g): 800",
      "Outer material: Polyester 20D 380T Nylon RipStop WR",
      "Inner material: 100% Polyester 210T Soft Nylon",
      "Insulation: Izoshell Pro 2*80 g/m²",
      "Packed size (cm): 52*25*25",
      "Dimensions (cm): 220*90(55)",
      "Packaging: Fabric bag",
    ],
  },
  {
    id: 100020,
    titleEng: "SLEEPING BAG BTRACE MEGA LEFT",
    title: "СПАЛЬНЫЙ МЕШОК BTRACE MEGA ЛЕВЫЙ",
    img: "sleeping_bag_BTRACE_MEGA.webp",
    descEng: "Insulation: 195(+35)*100, from 0°C to -21°C",
    desc: "Утеплитель 195(+35)*100 от 0°C до -21°C",
    category: "sleepingbag",
    price: 320,
    shortlyEng:
      "Insulation. The insulation used is lzoShell 40. The multi-directional super-fine hollow fibers of the lzoShell 4D insulation create numerous microvoids where warm air is trapped. The fibers of the insulation are bonded together with synthetic resin, making the lzoShell 4D insulation more airy, durable, and easy to maintain. Its low thermal conductivity, high hydrophobic and consumer properties make lzoShell 4D insulation one of the best insulators for sleeping bags. The outer material of the upper part of the sleeping bag is Polyester 190T with Rip Stop weave, a special breathable fabric that allows moisture to escape without condensing inside the sleeping bag. The outer material of the lower part of the sleeping bag is Polyester 190T with Diamond Rip Stop weave with a special waterproof PU coating, providing reliable protection from damage and moisture. The inner material is Flannel 100% Cotton - silky, soft, and comfortable fabric.",
    shortly:
      "Утеплитель.В качестве утеплителя используется lzoShell 40.Разнонаправленные супертонкие полые волокна утеплителя lzoShell 4D создают множество микрополостей, где удерживается теплый воздух. Волокна утеплителя соединены между собой синтетической смолой, что делает утеплитель lzoShell 4D более воздушным, долговечным и неприхотливым в эксплуатации. Низкий коэффициент теплопроводности, высокие гидрофобные и потребительские качества делают утеплитель lzoShell 4D одним из лучших утеплителей для спальных мешков. Внешний материал верхней части спальника Polyester 190Т с плетением Rip Stop, специальная паропроницаемая ткань, которая позволяет выводить пар не конденсируя влагу внутри спальника. Внешний материал нижней части спальника Polyester 190Т с плетением Diamond Rip Stop со специальным водонепроницаемым покрытием PU 250 мм водяного столба, надёжно защищает спальник от повреждения и влаги. Внутренний материал Фланель 100% Cotton - шелковистая, мягкая и комфортная ткань.",
    column: [
      "Производитель: BTRACE",
      "Температура экстрима: -21",
      "Температура комфорта: 0",
      "Вес (г):3210",
      "Материал внешний: Полиэстер 190Т RipStop/Полиэстер 190Т Diamond RipStop WR",
      "Внутренний материал: 100% хлопок (фланель)",
      "Утеплитель: Izoshell 4D 2*150 г/м2",
      "Размер в упаковке (см): 56*25*25",
      "Размер (см): (195+35)х100",
      "Упаковка: Тканевый мешок",
    ],
    columnEng: [
      "Manufacturer: BTRACE",
      "Extreme temperature: -21°C",
      "Comfort temperature: 0°C",
      "Weight (g): 3210",
      "Outer material: Polyester 190T RipStop / Polyester 190T Diamond RipStop WR",
      "Inner material: 100% cotton (flannel)",
      "Insulation: Izoshell 4D 2*150 g/m2",
      "Packed size (cm): 56*25*25",
      "Size (cm): (195+35)х100",
      "Packaging: Fabric bag",
    ],
  },
  {
    id: 1030020,
    titleEng: "",
    title: "Спальный мешок HUSKY GALA",
    img: "4p08zIUAdqqO9xgzppAV6jjjaNcXAt.jpg",
    descEng: "",
    desc: "комфорт 14, лимит 10, экстрим 0",
    category: "sleepingbag",
    price: 224,
    shortlyEng: "",
    shortly: "",
    column: [
      "Утеплитель: 1 x 165 g/m2, 4-х канальное полое волокно",
      "Ткань внутренняя: Polycotton",
      "Ткань внешняя: 70D 190T Nylon Taffeta",
      "Вес: 1650 г",
      "Размер: 220 х 90 см",
      "Размер упаковки: 39 x Ø21 см",
      "Рекомендованный рост: max 195 см",
      "Аксессуары: Компрессионный мешок",
      "Т комфорта: +6°С",
      "Т лимит комфорта: +2°С",
      "Т экстремальная: 0°С",
    ],
    columnEng: [],
  },
  {
    id: 100035,
    titleEng: "CLIMBING BACKPACK 20L",
    title: "FOLDING BACKPACK 10 L",
    img: "backpackForclaz.jpg",
    descEng: "FORCIAZ TRAVEL",
    desc: "FORCIAZ TRAVEL",
    category: "backpack",
    price: 27,
    shortlyEng:
      "This compact extra backpack is to make it easier for you to hike around the world with a backpack.",
    shortly:
      "Этот компактный дополнительный рюкзак, чтобы облегчить Вам походы по всему миру с рюкзаком.",
    column: [
      "Может вместить до 10 л.",
      "В пустом виде весит 45 г.",
      "Усиленные лямки",
      "Рюкзак складывается в себя для хранения, чтобы занимать как можно меньше места",
      "1 большое основное отделение + 1 карман на молнии",
    ],
    columnEng: [
      "Can hold up to 10 liters.",
      "Weighs 45 g when empty.",
      "Reinforced straps.",
      "The backpack folds into itself for storage, taking up as little space as possible.",
      "1 large main compartment + 1 zippered pocket.",
    ],
  },
  {
    id: 1004035,
    titleEng: "",
    title: "Сумка поясная складная",
    img: "compact-2-litre-travel-bum.png",
    descEng: "",
    desc: " 2 L",
    category: "backpack",
    price: 24,
    order: true,
    shortlyEng: "",
    shortly: "",
    column: [
      "Банан весит 45 г в пустом виде.",
      "Сумка складывается сама в себя, чтобы занимать как можно меньше места.",
      "1 большое основное отделение + 1 внешний карман на молнии.",
    ],
    columnEng: [""],
  },
  {
    id: 100033,
    title: "РЮКЗАК 17Л QUECHUA",
    titleEng: "BACKPACK 17L QUECHUA",
    img: "backpackBlack17L.jpg",
    desc: "NH ESCAPE 100",
    descEng: "NH ESCAPE 100",
    category: "backpack",
    price: 57,
    shortlyEng:
      "Environmentally friendly 17-liter NH Escape 100 backpack for casual strolls and commutes.",
    shortly:
      "Экологичный 17-литровый рюкзак NH Escape 100 для случайных прогулок и перемещений",
    column: [
      "Регулируемые спинка и лямки из пеноматериала",
      "1 отделение с 1 перегородкой для ПК 15'",
      "Устойчивые к истиранию материалы ",
      "Дно: усиленный пеноматериал",
      "Объем: 17 л",
      "Вес: 250 г",
      "Размеры: 40 x 26 x 13 см",
      "Подкладка: 100.0% Полиэтилентерефталат",
      "Основной материал: 100.0% Полиэтилентерефталат",
      "Внимание: материалы не водонепроницаемые. Чехол не прилагается.",
    ],
    columnEng: [
      "Adjustable backrest and straps made of foam material",
      "1 compartment with 1 divider for 15' laptop",
      "Materials resistant to wear and tear",
      "Bottom: reinforced foam material",
      "Volume: 17 liters",
      "Weight: 250 g",
      "Dimensions: 40 x 26 x 13 cm",
      "Lining: 100.0% Polyethylene Terephthalate",
      "Main material: 100.0% Polyethylene Terephthalate",
      "Attention: materials are not waterproof. Case not included.",
    ],
  },
  {
    id: 100032,
    titleEng: "BACKPACK QUECHUA 10L",
    title: "РЮКЗАК QUECHUA 10Л",
    img: "backpack10.webp",
    descEng: "Soft back panel",
    desc: "Мягкая спинка и лямки",
    category: "backpack",
    price: 39,
    shortlyEng:
      "The 10-liter backpack is designed so that you can safely carry essential items on all trails with gentle slopes.",
    shortly:
      "Рюкзак объемом 10 литров разработан таким образом, чтобы вы могли безопасно переносить предметы первой необходимости на всех тропах с пологими склонами.",
    column: ["Объем: 10л", "Вес: 0,145кг", "Размеры:39*21*12"],
    columnEng: ["Capacity: 10L", "Weight: 0.145kg", "Dimensions: 39*21*12"],
  },
  {
    id: 10003432,
    titleEng: "BACKPACK QUECHUA MH100 10L",
    title: "РЮКЗАК QUECHUA MH100 10Л",
    img: "kids-hiking-backpack-10l-mh100.png",
    descEng: "Soft back panel",
    desc: "Мягкая спинка и лямки",
    category: "backpack",
    price: 63,
    column: [
      "Объем: 10л",
      "Вес: 230г",
      "Размеры:37*21,5*19",
      "Спинка из пеноматер. и S-образные лямки, подходящие для детей Нагрудный ремень",
    ],
    columnEng: ["Capacity: 10L", "Weight: 0.145kg", "Dimensions: 39*21*12"],
  },
  {
    id: 100030,
    titleEng: "BACKPACK QUECHUA NH-100 30L ",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ QUECHUA NH-100",
    img: "backpack30L.jpg",
    descEng: "A 30L eco-friendly backpack, durable and wear-resistant.",
    desc: "экологичный рюкзак",
    category: "backpack",
    price: 115,
    shortlyEng:
      "The back panel and straps with overlays, 2 support loops on the straps, a chest strap. Side compression straps for optimal load distribution.",
    shortly:
      "Спинка и лямки с накладками, 2 опорные петли на ремнях, нагрудная лямка. Боковые компрессионные ремни для оптимального распределения нагрузки.",
    column: ["Объем: 30л", "Вес:0,62кг", "Размеры:50*26*20", "износостойкий"],
    columnEng: ["Volume: 30L", "Weight: 0.62 kg", "Dimensions: 50*26*20"],
  },
  {
    id: 1000330,
    titleEng: "BACKPACK QUECHUA 30L",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ QUECHUA NH-100",
    img: "2629836.png",
    desc: "20L",
    category: "backpack",
    price: 79,
    column: [
      "Объем: 20л",
      "Вес: 470 г",
      "Размеры:47*25*14",
      "2 вертикальные вставки для вентиляции спины",
      "Быстрый доступ к внешнему карману при горизонтальном положении рюкзака",
      '1 отделение | 5 карманов: 2 на молнии, 2 держателя фляги, 1 разделитель (ПК 15")',
      "Спинка с пеной – 2 накладки|Лямки с пеной|2 петли для пальцев|Нагрудный ремень",
    ],
    columnEng: ["Volume: 30L", "Weight: 0.62 kg", "Dimensions: 50*26*20"],
  },
  {
    id: 100037,
    title: "ТУРИСТИЧЕСКИЙ РЮКЗАК 5OЛ",
    titleEng: "BACKPACK 50L",
    img: "backpack10.jpg",
    desc: "Travel100 FORCLAZ",
    category: "backpack",
    order: true,
    price: 294,
    shortlyEng:
      "A backpack for all your first outdoor adventures, whether it's a mountain hike or a journey to the ends of the earth.",
    shortly:
      "Рюкзак для всех Ваших первых приключений на воздухе, будь то горный поход или путешествие на край света",
    column: [
      "Ремешок из пеноматериала. Нагруд. ремень. Оттяжки. Петли д/рук. Регулируемая спинка.",
      "Рюкзак весит примерно 1,4 кг.",
      "Карманы: 3 внеш., 1 внут., 1 поясн. Доступ в осн.отдел. и отсекам",
      "Держатели палок",
      "Основная. ткань — П600, с пропиткой внутри для усиления прочности",
      "Объем: 50 л",
      "Вес: 1,4 кг",
      "Размеры: 62 x 32 x 24 см",
      "Любые пряжки рюкзака можно заменить в качестве поломки",
    ],
    columnEng: [
      "Foam material strap. Chest strap. Compression straps. Hand loops. Adjustable backrest.",
      "The backpack weighs approximately 1.4 kg.",
      "Pockets: 3 external, 1 internal, 1 waist. Access to the main compartment and compartments.",
      "Trekking pole holders.",
      "Main fabric - P600, with interior coating for strength reinforcement.",
      "Volume: 50 liters",
      "Weight: 1.4 kg",
      "Dimensions: 62 x 32 x 24 cm",
      "Any buckles of the backpack can be replaced in case of breakage.",
    ],
  },
  {
    id: 10003437,
    title: "ТУРИСТИЧЕСКИЙ РЮКЗАК 5OЛ",
    titleEng: "BACKPACK 50L",
    img: "ryukzak-fit-50-l.jpg",
    desc: "Easyfit MT100 FORCLAZ",
    category: "backpack",
    price: 355,
    column: [
      "Объем: 50 л",
      "Вес: 1,6 кг",
      "Основная ткань из тонкого, но прочного полиэстера, усиленное дно",
      "Алюминиевый каркас ограничивет площадь опоры и обеспечивает вентиляцию",
      "Для удобства питья предусмотрен карман для питьевой системы (продается отдельно)",
      "Наличие разделителей, доступ снизу, 5 карманов",
    ],
    columnEng: [],
  },
  {
    id: 1034003437,
    title: "Рюкзак мужской FORCLAZ 70L",
    titleEng: "BACKPACK 70L",
    img: "1799211.jpg",
    desc: "Easyfit MT100 FORCLAZ ",
    category: "backpack",
    price: 485,
    order: true,
    column: [
      "Объем: 70 л",
      "Вес: 1,9 кг",
      "Основная ткань – полиэстер 600 денье, усиленное дно (1600 денье)",
      "Алюминиевый каркас ограничивет площадь опоры и обеспечивает вентиляцию",
      "Внутренний отсек для кармана для воды (не комплектуется)",
    ],
    columnEng: [],
  },
  {
    id: 100027,
    titleEng: "BACKPACK",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "THENORTHFACE_blue.jpg",
    descEng: "50L THE NORTH FACE(REPLICA)",
    desc: "50L THE NORTH FACE(REPLICA)",
    category: "backpack",
    price: 165,
    order: true,
    shortlyEng:
      "A lightweight, reliable, and fully adjustable backpack for multi-day trips and lightweight travel. With a volume of 50 liters, the NorthFace backpack is suitable for climbing, hiking, and traveling.",
    shortly:
      "Легкий, надежный и полностью регулируемый рюкзак для многодневных выходов и путешествий налегке. Рюкзак NorthFace обладая объемом 50л, подойдет для скалолазания, походов и путешествий.",
    column: ["Объем: 50л", "Конструкция: Мягкий (без элементов жесткости)"],
    columnEng: [
      "Volume: 50 liters",
      "Construction: Soft (without rigid elements)",
    ],
  },
  {
    id: 100025,
    titleEng: "BACKPACK",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "THENORTHFACE_red.jpg",
    descEng: "55L THE NORTH FACE(REPLICA)",
    desc: "55L THE NORTH FACE(REPLICA)",
    category: "backpack",
    price: 175,
    order: true,
    shortlyEng:
      "The TNF Terra 55 medium-volume trekking backpack is perfect for travel. The straps can be adjusted to fit your specifications.",
    shortly:
      "Треккинговый рюкзак среднего объема TNF Terra 55 отлично подходит для путешествий. Лямкам рюкзак можно настроить под свои параметры.",
    column: ["Вес: 1.8 кг", "Материал: Нейлон"],
    columnEng: ["Weight: 1.8 kg", "Material: Nylon"],
  },
  {
    id: 100028,
    titleEng: "BACKPACK (REPLICA) 60L",
    title: "РЮКЗАК (РЕПЛИКА) 60Л",
    img: "backpackTheNord.jpg",
    descEng: "THE NORTH FACE 60",
    desc: "THE NORTH FACE 60",
    category: "backpack",
    price: 195,
    order: true,
    shortlyEng:
      "It is designed for medium-length hikes and is made of durable and wear-resistant material.",
    shortly:
      "Предназначен для походов средней протяженности и изготовлен из прочного и износостойкого материала.",
    column: [
      "Размеры: 70*39*26",
      "Объем: 60Л",
      "Изготовлен из прочной водоотталкивающей и устойчивой к царапинам ткани ч20д",
      "E-VA™ плечевые ремни",
      "Фиксация на груди и на поясе отстегивающаяся жесткая спинка aircomfort с поясными лямками",
      "Есть отдел для ноутбука и отверстие для наушников",
      "Дождевик в комплекте",
    ],
    columnEng: [
      "Sizes: 70*39*26",
      "Volume: 60L",
      "Made of durable, water-repellent, and scratch-resistant 20D fabric",
      "E-VA™ shoulder straps",
      "Chest and waist straps with detachable rigid aircomfort back panel with hip straps",
      "Laptop compartment and headphone port",
      "Includes a rain cover",
    ],
  },
  {
    id: 100026,
    titleEng: "BACKPACK",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "backpack_grey1.jpg",
    descEng: "80L THE NORTH FACE(REPLICA)",
    desc: "80L THE NORTH FACE(REPLICA)",
    category: "backpack",
    order: true,
    price: 260,
    shortlyEng: "Incredibly spacious model with many useful features.",
    shortly: "Невероятно вместительная модель с множеством полезных функций.",
    column: ["Обьем: 80 л", "Анатомическая спинка", "Компрессионные ремни"],
    columnEng: ["Volume: 80L", "Anatomical back panel", "Compression straps"],
  },
  {
    id: 100024,
    titleEng: "BACKPACK",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ",
    img: "Cakard_black_backpack.jpg",
    desc: "95+10L Сakard ",
    descEng: "95+10L Сakard",
    category: "backpack",
    order: true,
    price: 185,
    column: ["Объем: 95+10 л"],
    columnEng: ["Volume: 95+10 L"],
  },
  {
    id: 100023,
    titleEng: "BACKPACK CAMPSOR",
    title: "РЮКЗАК ТУРИСТИЧЕСКИЙ CAMPSOR",
    img: "Сampsor_blue.jpg",
    descEng: "75+10L, includes rain cover",
    desc: "75+10L В комплекте дождевик",
    category: "backpack",
    order: true,
    price: 310,
    column: [
      "Объем: 75+10 л",
      "Цвет: Синий",
      "Жесткая, регулируемая спинка",
      "Материал: Нейлон",
      "Вес:1,55кг",
    ],
    columnEng: [
      "Volume: 75+10 L",
      "Color: Blue",
      "Rigid, adjustable back",
      "Material: Nylon",
      "Weight: 1.55 kg",
    ],
  },

  // {
  //   id: 100029,
  //   titleEng: "BACKPACK DEUTER FUTURA PRO",
  //   title: "РЮКЗАК ТУРИСТИЧЕСКИЙ DEUTER FUTURA PRO",
  //   img: "backpack_65.jpg",
  //   descEng:"65L (REPLICA).The rain cover is included",
  //   desc: "65L (REPLICA). В комплекте дождевик.",
  //   category: "backpack",
  //   price: 250,
  //   shortlyEng:"A lightweight, reliable, and fully adjustable backpack for multi-day outings and lightweight travel.",
  //   shortly:
  //     "Легкий, надежный и полностью регулируемый рюкзак для многодневных выходов и путешествий налегке.",
  //   column: ["Объем: 65л", "Вес:1,7кг"],
  //   columnEng:["Volume: 65L",'Weight:1,7kg']
  // },

  // {
  //   id: 100031,
  //   titleEng: "BACKPACK FORCLAZ",
  //   title: "РЮКЗАК ТУРИСТИЧЕСКИЙ FORCLAZ",
  //   img: "quechuaforclaz40+10.jpg",
  //   descEng:"Volume: 40+10L, Adjustable back length: 140/150/160/170 cm",
  //   desc: "40+10L Регулируемая спика 140/150/160/170/см",
  //   category: "backpack",
  //   price: 319,
  //   shortlyEng:"Durable components, reinforced bottom. Padded back and shoulder straps, 2 support loops on the straps, sternum strap. Side compression straps for optimal load distribution.",
  //   shortly:
  //     "Износостойкие компоненты, усиленное днище. Спинка и лямки с накладками, 2 опорные петли на ремнях, нагрудная лямка. Боковые компрессионные ремни для оптимального распределения нагрузки.",
  //   column: ["Объем: 40+10л", "Вес:1,4кг", "Размеры:64*26*30"],
  //   columnEng:["Volume: 40+10L", "Weight: 1.4kg", "Dimensions: 64*26*30"]
  // },

  // {
  //   id: 100034,
  //   titleEng: "CLIMBING BACKPACK 20L",
  //   title: "АЛЬПИНИСТСКИЙ РЮКЗАК 20Л",
  //   img: "backpackAlpin.jpg",
  //   descEng:'CUFF SIMOND',
  //   desc: "CUFF SIMOND",
  //   category: "backpack",
  //   price: 110,
  //   shortlyEng:"Minimalistic and compact with a volume of 20 liters, this is the perfect option for climbers who need a very lightweight backpack for day ascents.",
  //   shortly:
  //     "Минималистичный и компактный объемом 20 литров, это идеальный вариант для скалолазов, которым нужен очень легкий рюкзак для дневных восхождений.",
  //   column: [
  //     "Сверхлегкий рюкзак: 265 г.",
  //     "Ременные петли для переноски экипировки. Складывается во внутренний карман",
  //     "Система открывания Easy Access: залог легкости и скорости",
  //     "Высота: 50 см. Ширина: 24 см. Глубина: 18 см.",
  //     "- Сверхлегкие лямки с сетчатым покрытием. - Нагрудный ремень регулируется по ширине и высоте для того, чтобы лямки не сползали с плеч",
  //     "Петли для фиксации снаряжения (карабинов, оттяжек и т. д.). - Внутреннее отделение для питьевой системы с 2 подвесными застежками.",
  //     "Внешний карман на молнии 28 x 16 см для аксессуаров (телефона, карты, GPS)",
  //   ],
  //   columnEng:["Ultra-lightweight backpack: 265 g.",
  //   "Gear loops for carrying equipment. Folds into internal pocket.",
  //   "Easy Access opening system: ensures ease and speed.",
  //   "Height: 50 cm. Width: 24 cm. Depth: 18 cm.",
  //   "- Super-lightweight straps with mesh coating. - Adjustable chest strap for width and height to prevent straps from slipping off shoulders.",
  //   "Gear attachment loops (carabiners, slings, etc.). - Internal compartment for hydration system with 2 suspension clasps.",
  //   "External zippered pocket 28 x 16 cm for accessories (phone, map, GPS)."]
  // },

  {
    id: 100036,
    title: "ТУРИСТИЧЕСКИЙ РЮКЗАК",
    titleEng: "BACKPACK",
    descEng: "NATUREHIKE ROCK 60+51",
    img: "backpackNaturehike60.jpg",
    desc: "NATUREHIKE ROCK 60+51",
    category: "backpack",
    price: 350,
    order: true,
    shortlyEng:
      "Ultra-lightweight hiking backpack with a 60-liter capacity and a rigid frame weighing only 1.16 kg.",
    shortly:
      "Сверхлегкий туристический рюкзак объемом 60 литров с жесткой рамой весом всего 1,16 кг.",
    column: [
      "Выполнен из нейлона DYNEEMA 420D, который превосходит по прочности сталь в 20 раз",
      "Вместительное основное отделение",
      "Система подвески с алюминиевым каркасом для лучшей воздухопроницаемости и снятия нагрузки с плеч",
      "Регулируемая грудная стяжка",
      "S-образные регулируемые плечевые лямки со съемным карманом",
      "Широкий поясной ремень с карманами для мелочей или еды",
      "Отделение для питьевой системы",
      "Выход для трубки питьевой системы на лямке",
      "Боковые сетчатые карманы",
      "Боковые стяжки",
      "Объемный карман спереди",
      "В комплекте чехол от дождя (в отсеке на дне)",
      "Имеет большой объем и грузоподъемность 14 кг.",
      "Модель 2022г.",
    ],
    columnEng: [
      "Constructed from 420D DYNEEMA nylon, which is 20 times stronger than steel",
      "Spacious main compartment",
      "Suspension system with aluminum frame for better ventilation and weight distribution",
      "Adjustable chest strap",
      "S-shaped adjustable shoulder straps with detachable pocket",
      "Wide waist belt with pockets for small items or food",
      "Hydration system compartment",
      "Tube exit for hydration system on the shoulder strap",
      "Side mesh pockets",
      "Side compression straps",
      "Large front pocket",
      "Includes a rain cover (stored in the bottom compartment)",
      "Has a large volume and a load capacity of 14 kg.",
      "Model year: 2022.",
    ],
  },

  {
    id: 100038,
    title: "ТУРИСТИЧЕСКИЙ РЮКЗАК 65Л",
    titleEng: "BACKPACK 65L",
    img: "backpack11.jpg",
    descEng: "NATUREHIKE HIKING 65",
    desc: "NATUREHIKE HIKING 65",
    category: "backpack",
    price: 570,
    order: true,
    shortlyEng:
      "Designed for medium-length hikes and made from durable and abrasion-resistant material.",
    shortly:
      "Предназначен для походов средней протяженности и изготовлен из прочного и износостойкого материала.",
    column: [
      "Размеры: 78*33.5*25",
      "Вес: 2кг",
      "Материал: нейлон",
      "Система подвески имеет жесткий каркас, состоящий из двух алюминиевых пластин, что обеспечивает хорошую вентиляцию спины и снимает нагрузку с плеч",
      "Вместительное основное отделение",
      "Система подвески с алюминиевым каркасом для лучшей воздухопроницаемости и снятия нагрузки с плеч",
      "Клапан с регулируемым по высоте положением",
      "Широкий и мягкий поясной ремень с карманом для мелочей или еды",
      "Регулируемая грудная стяжка со спасательным свистком",
      "Регулируемые плечевые лямки",
      "Отделение для питьевой системы",
      "Резинки для фиксации трубки питьевой системы",
      "Боковые карманы",
      "Боковые стяжки",
      "Пластиковые полукольца для крепления снаряжения спереди",
      "Крепления для треккинговых палок",
      "Карман спереди для дождевика или веревки",
      "Внутренний сетчатый карман",
      "В комплекте чехол от дождя (в отсеке на дне)",
    ],
    columnEng: [
      "Dimensions: 78*33.5*25",
      "Weight: 2kg",
      "Material: nylon",
      "The suspension system features a rigid frame consisting of two aluminum plates, providing good back ventilation and relieving shoulder pressure.",
      "Spacious main compartment",
      "Suspension system with aluminum frame for better ventilation and weight distribution",
      "Height-adjustable flap",
      "Wide and soft waist belt with a pocket for small items or food",
      "Adjustable chest strap with a rescue whistle",
      "Adjustable shoulder straps",
      "Hydration system compartment",
      "Elastic loops for securing the hydration system tube",
      "Side pockets",
      "Side compression straps",
      "Plastic semi-rings for attaching gear in front",
      "Trekking pole attachments",
      "Front pocket for rain cover or rope",
      "Internal mesh pocket",
      "Includes a rain cover (stored in the bottom compartment)",
    ],
  },
  {
    id: 100039,
    title: "ТУРИСТИЧЕСКИЙ РЮКЗАК 55Л",
    titleEng: "BACKPACK 55L",
    img: "backpack12.jpg",
    desc: "NATUREHIKE HIKING 55",
    descEng: "NATUREHIKE HIKING 55",
    category: "backpack",
    price: 392,
    shortlyEng:
      "Designed for medium-length hikes and made from durable and abrasion-resistant material.",
    shortly:
      "Предназначен для походов средней протяженности и изготовлен из прочного и износостойкого материала.",
    column: [
      "Размеры: 78*32*23",
      "Вес: 1.9кг",
      "Материал: нейлон",
      "Система подвески имеет жесткий каркас, состоящий из двух алюминиевых пластин, что обеспечивает хорошую вентиляцию спины и снимает нагрузку с плеч",
      "Вместительное основное отделение",
      "Система подвески с алюминиевым каркасом для лучшей воздухопроницаемости и снятия нагрузки с плеч",
      "Клапан с регулируемым по высоте положением",
      "Широкий и мягкий поясной ремень с карманом для мелочей или еды",
      "Регулируемая грудная стяжка со спасательным свистком",
      "Регулируемые плечевые лямки",
      "Отделение для питьевой системы",
      "Резинки для фиксации трубки питьевой системы",
      "Боковые карманы",
      "Боковые стяжки",
      "Пластиковые полукольца для крепления снаряжения спереди",
      "Крепления для треккинговых палок",
      "Карман спереди для дождевика или веревки",
      "Внутренний сетчатый карман",
      "В комплекте чехол от дождя (в отсеке на дне)",
    ],
    columnEng: [
      "Dimensions: 78*32*23",
      "Weight: 1.9kg",
      "Material: nylon",
      "The suspension system features a rigid frame consisting of two aluminum plates, providing good back ventilation and relieving shoulder pressure.",
      "Spacious main compartment",
      "Suspension system with aluminum frame for better ventilation and weight distribution",
      "Height-adjustable flap",
      "Wide and soft waist belt with a pocket for small items or food",
      "Adjustable chest strap with a rescue whistle",
      "Adjustable shoulder straps",
      "Hydration system compartment",
      "Elastic loops for securing the hydration system tube",
      "Side pockets",
      "Side compression straps",
      "Plastic semi-rings for attaching gear in front",
      "Trekking pole attachments",
      "Front pocket for rain cover or rope",
      "Internal mesh pocket",
      "Includes a rain cover (stored in the bottom compartment)",
    ],
  },
  {
    id: 110039,
    title: "Рюкзак 30L",
    titleEng: "BACKPACK 50L",
    img: "2024-06-16.png",
    desc: "Gregory Arrio",
    category: "backpack",
    order: true,
    price: 399,
    column: [
      "Gregory Arrio 30 – рюкзак объемом 30 л для однодневных пешеходных, горных или велосипедных походов, экскурсий, активного отдыха на природе",
      "Вес: 725 г",
      "2 боковых кармана - на молнии и эластичный с быстрым доступом",
      "Подвеска FreeSpan с дышащей сетчатой ​​спинной панелью и стальным каркасом по периметру обеспечивает отличную вентиляцию и отвод влаги",
      "Легкие и хорошо вентилируемые лямки из перфорированной пены",
      "В комплекте идет легкая (вес 68 г) накидка от дождя, которая хранится в потайном кармане под клапаном",
      "Габариты: 57 x 27 x 22 см.",
      "Максимальная нагрузка: 9.1 кг",
    ],
    columnEng: [],
  },

  {
    id: 100059,
    titleEng: "OUTDOOR FOAM SEAT",
    title: "CИДЕНЬЕ ТУРИСТИЧЕСКОЕ",
    order: true,
    img: "sitting_DECATHLON.png",
    descEng: "Made of foam with a metallized layer",
    desc: "Из пеноматериала с металлизированным слоем",
    category: "mat",
    price: 15,
    shortlyEng: "Seat with foam metal frame, suitable for trekking",
    shortly:
      "Сиденье с металлическим каркасом из пеноматериала, подходящий для треккинга.",
    column: [
      "Вес: 60 г",
      "Размеры: 34*23,7*1,5 см",
      "Эластичная лента с клипсой обеспечивает комфортное использование",
    ],
    columnEng: [
      "Weight: 60 g",
      "Dimensions: 34*23.7*1.5 cm",
      "Elastic band with clip ensures comfortable use",
    ],
  },
  {
    id: 100062,
    title: "СКЛАДНАЯ СИДУШКА (ПЕНКА)",
    titleEng: "FOLDING SEAT PAD (FOAM)",
    img: "folding_mat.jpg",
    descEng: " folds into 4 sections",
    desc: " складывается в 4 раза",
    category: "mat",
    price: 29,
    order: true,
    column: ["Вес:60г"],
    columnEng: ["Weight: 60 g"],
  },
  {
    id: 1050062,
    title: "СКЛАДНАЯ СИДУШКА (ПЕНКА)",
    titleEng: "",
    img: "1711867476757-d3ed42d5f24d41a79a261670a709e7d1-goods.webp",
    descEng: "",
    desc: " 27,5 Х 39",
    category: "mat",
    price: 12,
    order: true,
    column: ["складывается в 4 раза"],
    columnEng: [],
  },
  {
    id: 100057,
    title: "Коврик фольгированный",
    titleEng: "MAT QUECHUA",
    img: "matQuechua.jpg",
    desc: "180*50*0,7см ",
    descEng: "180*50*0,7сm",
    category: "mat",
    order: true,
    price: 35,
    column: [
      "Размеры: 180*50*0,7 см.",
      "Вес: 210 грамм",
      "Материал: 100% полиэтилен",
      "С алюминиевую пленкой, которая предотвращает истирание и улучшает теплопроводность",
      "Для удобства переноски оснащен эластичной лентой",
    ],
    columnEng: [
      "Dimensions: 180 x 50 x 0.7 cm",
      "Weight: 210 grams",
      "Material: 100% polyethylene",
      "With aluminum foil, which prevents abrasion and improves thermal conductivity",
      "For easy carrying, equipped with an elastic strap",
    ],
  },
  {
    id: 1040057,
    title: "Коврик фольгированный",
    titleEng: "MAT QUECHUA",
    img: "c9ad750dff1bff7c0.jpeg",
    desc: "180*60*1 см",
    descEng: "180*60*1 сm",
    category: "mat",
    price: 44,
    column: [
      "Размеры: 180*60*0,7 см.",
      "Вес: 230 грамм",
      "Материал: 100% полиэтилен",
      "С алюминиевую пленкой, которая предотвращает истирание и улучшает теплопроводность",
      "Для удобства переноски оснащен эластичной лентой",
    ],
    columnEng: [
      "Dimensions: 180 x 50 x 0.7 cm",
      "Weight: 210 grams",
      "Material: 100% polyethylene",
      "With aluminum foil, which prevents abrasion and improves thermal conductivity",
      "For easy carrying, equipped with an elastic strap",
    ],
  },
  {
    id: 10340054,
    title: "Коврик TR Pilates",
    titleEng: "MAT",
    img: "CGiBiTNufHIJPB2V.jpg",
    desc: "180*60*1 см",
    descEng: "180*60*1 сm",
    category: "mat",
    price: 56,
    order: true,
    column: [
      "Верхняя поверхность из полиэтилена предотвращает скольжение и впитывает пот.",
    ],
    columnEng: ["Non-slip"],
  },
  {
    id: 1004054,
    title: "КОВРИК ПЕНКА",
    titleEng: "MAT",
    img: "coccuga83ve66.png",
    desc: "тонкие ( цвета в ассортименте )",
    descEng: "173*61*0.8сm",
    category: "mat",
    price: 26,
    column: ["Нескользящий"],
    columnEng: ["Non-slip"],
  },
  {
    id: 104304054,
    title: "Коврик для йоги прилипающий",
    titleEng: "MAT",
    img: "mat51.jpg",
    desc: "173*61*0.8см ( цвета в ассортименте )",
    descEng: "173*61*0.8сm",
    category: "mat",
    price: 35,
    column: ["Нескользящий"],
    columnEng: ["Non-slip"],
  },
  {
    id: 100054,
    title: "КОВРИК ПЕНКА",
    titleEng: "MAT",
    img: "mat_with_case.jpg",
    desc: "173*61*0.8см ( цвета в ассортименте )",
    descEng: "173*61*0.8сm",
    category: "mat",
    price: 45,
    order: true,
    column: ["Нескользящий"],
    columnEng: ["Non-slip"],
  },

  {
    id: 100063,
    title: "СКЛАДНОЙ ВЛАГОСТОЙКИЙ КОВРИК ДЛЯ КЕМПИНГА",
    titleEng: "FOLDABLE WATER-RESISTANT CAMPING MAT",
    img: "mat6.jpg",
    descEng: "185*59*1.5 cm, ultralight",
    desc: "185*59*1.5 см, сверхлекгий",
    category: "mat",
    price: 59,
    column: ["применим для пляжа / кемпинга / йоги"],
    columnEng: ["suitable for beach / camping / yoga"],
  },
  {
    id: 100058,
    title: "САМОНАДУВАЮЩИЙСЯ КОВРИК BASIC",
    titleEng: "SELF-INFLATING CAMPING MAT",
    img: "matBasic.webp",
    desc: "180*60*3 см",
    descEng: "180*60*3 сm",
    category: "mat",
    price: "-",
    order: true,
    column: [
      "Размеры: 185 x 60 x 3 см",
      "1-местный 60 см зеленый",
      "Вес: 1,3 кг.",
      "Надувается без насоса",
      "В комплекте транспортеровочный чехол",
      "Размеры чехла: 31*16*16 см",
      "Занимаемый объем: 8 л",
    ],
    columnEng: [
      "Dimensions: 185 x 60 x 3 cm",
      "Single person, 60 cm wide, green",
      "Weight: 1.3 kg",
      "Inflates without a pump",
      "Includes a carrying case",
      "Case dimensions: 31 x 16 x 16 cm",
      "Packed volume: 8 liters",
    ],
  },
  {
    id: 110063,
    title: "САМОНАДУВАЮЩИЙСЯ КОВРИК",
    titleEng: "SELF-INFLATING MAT",
    img: "917800_675x.jpg",
    desc: "FORGLAZ MT100 180*52*25 см",
    descEng: "FORGLAZ MT100 180*52*25cm",
    category: "mat",
    price: 209,
    column: [
      "Вес: 820 грамм",
      "Объем: 7 литров",
      "Диаметр: 18 см",
      "Длина: 29 см",
      " Подходит для использования в 2 сезонах при температуре выше 5°C ",
      "Нескользящие силиконовые накладки предотвращают скольжение спального мешка.",
      "Мат автоматически надувается и имеет эффективную одностороннюю двойную пробку, обеспечивающую простоту использования.",
      "Чтобы настроить жесткость мата, необходимо регулировать давление накачивания, аккуратно нажимая на клапан с надписью 'IN' при открытой крышке.",
    ],
    columnEng: [
      "Weight: 820 grams",
      "Volume: 7 liters",
      "Diameter: 18 cm",
      "Length: 29 cm",
      "Suitable for 2-season use at temperatures above 5°C",
      "Non-slip silicone pads prevent sleeping bag slippage.",
      "The mat self-inflates automatically and has an efficient one-way double valve for ease of use.",
      "To adjust the mat's firmness, regulate the inflation pressure by gently pressing the 'IN' valve while the cap is open.",
    ],
  },
  // {
  //   id: 100060,
  //   titleEng:'OUTDOOR FOAM SEAT',
  //   title: "КОВРИК СКЛАДНОЙ",
  //   img: "mat_portable.jpg",
  //   descEng:'180*59*1 cm',
  //   desc: "180*59*1см",
  //   category: "mat",
  //   price: 59,
  //   column: ["Вес:370г"],
  //   columnEng:["Weight: 370 g"]
  // },
  {
    id: 100061,
    title: "НАДУВНОЙ КОВРИК ДЛЯ ТРЕККИНГА",
    titleEng: "INFLATABLE TREKKING MAT",
    img: "matair.png",
    desc: "180 × 52 × 1 CM (L) MT500 AIR",
    descEng: "180 × 52 × 1 CM (L) MT500 AIR",
    category: "mat",
    price: 256,

    column: [
      "Вес: 510 г",
      "Объем: 1,7 литра",
      "ЧЕХОЛ ДЛЯ ТРАНСПОРТИРОВКИ",
      "Размеры: 180 x 52 x 5 см.",
      "Удобная толстая спальная поверхность.8 сегментов",
      "Накачивается без насоса",
      "Двойной антивозвратный клапан",
      "Вставки из силикона от скольжения",
      "Идеальный матрас для температуры > 8°C",
      "10 см х Ø 22 см",
    ],
    columnEng: [
      "Weight: 510 g",
      "Volume: 1.7 liters",
      "CARRY CASE FOR TRANSPORT",
      "Dimensions: 180 x 52 x 5 cm",
      "Comfortable thick sleeping surface with 8 segments",
      "Inflates without a pump",
      "Double non-return valve",
      "Anti-slip silicone inserts",
      "Ideal mattress for temperatures > 8°C",
      "10 cm x Ø 22 cm",
    ],
  },
  {
    id: 100332061,
    title: "Надувной коврик HUSKY SLEEPING MAT FLITE",
    titleEng: "",
    img: "7e84840386d2875c3f3c518ed9af5197.webp",
    desc: "blue 180 × 50 × 5 cm",
    descEng: "",
    category: "mat",
    price: 230,
    order: true,
    column: [
      "Слой утеплителя толщиной 5 см  для защиты от неровностей местности и холода.",
      "Вес: 320 г",
      "Размеры (в упаковке):  16 × 9 см",
      "Легкий и компактный",
      "Изготовлен из  нейлона",
    ],
    columnEng: [],
  },
  {
    id: 120320332061,
    title: "Надувной коврик HUSKY SLEEPING MAT FEEZY",
    titleEng: "",
    img: "xWRuFmnKxogIpQmUB7cQfFgCwb0yVT.jpg",
    desc: "GREEN 180 × 61 × 6 cm",
    descEng: "",
    category: "mat",
    price: 175,
    order: true,
    column: [
      "Слой утеплителя толщиной 6 см  для защиты от неровностей местности и холода.",
      "Вес: 400 г",
      "Размеры (в упаковке): 26 × 10 см",
      "Легкий и компактный",
      "Изготовлен из  нейлона",
      "Умный клапан  облегчает надувание и упаковку.",
    ],
    columnEng: [],
  },
  {
    id: 1200332061,
    title: "Надувной коврик HUSKY SLEEPING MAT FURY ",
    titleEng: "",
    img: "16.03.40.png",
    desc: "187 × 55 × 5 cm",
    descEng: "",
    category: "mat",
    price: 260,

    column: [
      "Слой утеплителя толщиной 6 см  для защиты от неровностей местности и холода.",
      "Вес: 410 г",
      "Размеры (в упаковке): 22 × 10 см",
      "Легкий и компактный",
      "Изготовлен из  нейлона",
      "Умный клапан  облегчает надувание и упаковку.",
    ],
    columnEng: [],
  },

  {
    id: 100064,
    title: "ТРЕКИНГОВЫЕ ПАЛКИ",
    titleEng: "TREKKING POLES",
    img: "trekkingsticks1.jpg",
    descEng: "Set of 2, aluminum",
    desc: "Комплекст из 2шт, алюминиевые",
    category: "trekkingsticks",
    price: 80,

    shortlyEng:
      "Trekking poles are designed to help you maintain balance and evenly distribute weight between your arms and legs.",
    shortly:
      "Треккинговые палки редназначены для того, чтобы помочь вам сохранить равновесие и правильно распределить нагрузку между руками и ногами.",
    column: [
      "Толстая губчатая ручка",
      "3D-образная и длинная",
      "3 телескопические части",
      "Размер в сложенном виде - 56 см",
      "Ширина при открытии: ~5 см",
      "Регулировка длины 105 - 130 см ",
      "Диаметр стержня 18/16/14 мм ",
      "Прочный вольфрамовый наконечник",
      "Вес: 240 гр",
    ],
    columnEng: [
      "Thick foam handle",
      "3D-shaped and long",
      "3 telescopic sections",
      "Folded size - 56 cm",
      "Width when open: ~5 cm",
      "Length adjustment 105 - 130 cm",
      "Shaft diameter 18/16/14 mm",
      "Durable tungsten tip",
      "Weight: 240 g",
    ],
  },
  // {
  //   id: 100065,
  //   title: "ТРЕКИНГОВЫЕ ПАЛКИ FORCLAZ MT500",
  //   titleEng:'TREKKING POLES FORCLAZ MT500',
  //   img: "trekking_sticks_forclaz.jpg",
  //   descEng:'Set of 2, aluminum',
  //   desc: "Комплекст из 2шт, алюминиевые",
  //   category: "trekkingsticks",
  //   price: 174,
  //   shortlyEng:"Simple push-pin adjustment system (with buttons) 110/115/120/125/130 cm.",
  //   shortly:
  //     "Простая система регулировки push-pin (с кнопками) 110/115/120/125/130 см.",
  //   column: [
  //     "3 телескопические части",
  //     "Размер яруса: 55 см",
  //     "Ширина в раскрытом виде: ~5см",
  //     "Алюминиевые стержни 6061-T6",
  //     "Диаметр стержня 20/18/16 мм",
  //     "Стальной наконечник",
  //     "Вес: 200 гр.",
  //   ],
  //   columnEng:["3 telescopic sections",
  //   "Canopy size: 55 cm",
  //   "Width when open: ~5 cm",
  //   "6061-T6 aluminum shafts",
  //   "Shaft diameter 20/18/16 mm",
  //   "Steel tip",
  //   "Weight: 200 g."]
  // },
  {
    id: 100066,
    title: "ТРЕКИНГОВЫЕ ПАЛКИ FORCLAZ MT100",
    titleEng: "TREKKING POLES FORCLAZ MT100",
    img: "trekking_sticks_black.jpg",
    descEng: "Set of 2, aluminum with tungsten tips",
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 119,
    order: true,
    shortlyEng:
      "Simple push-pin adjustment system (with buttons) 110/115/120/125/130 cm.",
    shortly:
      "Простая система регулировки push-pin(с кнопками) 110/115/120/125/130 см.",
    column: [
      "3 телескопические части",
      "Длинная 2D-рукоятка, покрытая пеноматериалом",
      "Размер яруса: 55 см",
      "Ширина в раскрытом виде: ~5см",
      "Алюминиевые стержни 6061-T6",
      "Диаметр стержня 20/18/16 мм",
      "Стальной наконечник",
      "Вес: 230 гр.",
    ],
    columnEng: [
      "3 telescopic sections",
      "Long 2D handle covered with foam material",
      "Canopy size: 55 cm",
      "Width when open: ~5 cm",
      "6061-T6 aluminum shafts",
      "Shaft diameter 20/18/16 mm",
      "Steel tip",
      "Weight: 230 g.",
    ],
  },
  {
    id: 10560066,
    title: "Палки треккинговые VIKING Kalio",
    titleEng: "TREKKING POLES FORCLAZ MT100",
    img: "098764567890.jpg",
    descEng: "Set of 2, aluminum with tungsten tips",
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 96,
    order: true,
    column: [
      "Трехсекционное древко из качественного алюминиевого сплава 6061",
      "Эргономичная рукоятка из резины",
      "Амортизирующее звено ANTISHOCK-System",
      "Роторная (поворотная) система фиксации колен",
      "Регулируемый темляк из комбинации нейлона и фланелевой ткани",
      "Прочный стальной наконечник",
      "Резиновые наконечники и кольца в комплекте",
      "Вес (г / шт): 220",
      "Длина (см): 67-135 регулируемая",
      "Производство: Польша",
    ],
  },
  {
    id: 10560049866,
    title: "Палки треккинговые VIKING Bambu",
    titleEng: "TREKKING POLES FORCLAZ MT100",
    img: "viking_630.jpg",
    descEng: "Set of 2, aluminum with tungsten tips",
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 162,
    column: [
      "Легкая эргономичная рукоятка из комбинации пробки и синтетической пены EVA",
      "Трехсекционное древко из высококачественного алюминиевого сплава 7075 с бамбуковым волокном",
      "Cъемные наконечники",
      "Регулируемый темляк из комбинации нейлона и фланелевой ткани",
      "Длина (см): 67-135 регулируемая",
      "Kоличество секций: 3 шт.",
      "Наконечник: карбид вольфрама ",
      "Вес (г / шт): 215",
      "Производство: Польша",
    ],
  },
  {
    id: 1056009866,
    title: "Палки треккинговые VIKING Lost Peak",
    titleEng: "TREKKING POLES FORCLAZ MT100",
    img: "21.39.39.jpg",
    descEng: "Set of 2, aluminum with tungsten tips",
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 135,
    order: true,
    column: [
      "Легкая эргономичная рукоятка из синтетической пены EVA",
      "Система быстрой фиксации Fast Lock",
      "Трехсекционное древко из качественного алюминиевого сплава 6061",
      "Съемные наконечники",
      "Регулируемый темляк из комбинации нейлона и фланелевой ткани",
      "Прочный победитовый наконечник",
      "Резиновые наконечники и кольца в комплекте",
      "Вес (г / шт): 250",
      "Длина (см): 65-135 регулируемая",
      "Производство: Польша",
    ],
  },
  {
    id: 105605609866,
    title: "Палки треккинговые VIKING Shiva ",
    titleEng: "",
    img: "6.16.56.jpg",
    descEng: "",
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 155,
    order: true,
    column: ["Размеры: 4 x 15 x 69 см", "Основной материал Алюминий"],
  },
  {
    id: 105600329866,
    title: "Палки треккинговые VIKING Kettera Pro Long Grip",
    titleEng: "",
    img: "k97OD73I9ZmJtAL7K4rzwJyCn8dgge.jpg",
    descEng: "",
    order: true,
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 210,

    column: [
      "Материал полюса: алюминий 7075.",
      "5 складных секций",
      "Диаметр 18/16/14 мм",
      "Удлиненная ручка (ручка) из пены ЭВА.",
      "Вес одного шеста – 243 г.",
      "Регулировка высоты 37-135 см.",
      "Регулируемый ремешок из полиэстера.",
      "Система быстрой блокировки",
      "Сменные насадки",
    ],
  },
  {
    id: 10004367,
    title: "ПАЛКИ ДЛЯ СКАНДИНАВСКОЙ ХОДЬБЫ VIKING Kube Pro Red",
    titleEng: " NORDIC WALKING POLES",
    img: "Kije-Nordic-Walking-Viking-Kube-Pro-66-135-cm.jpeg",
    descEng: "Set of 2, made of duralumin",
    desc: "Комплекст из 2шт",
    category: "trekkingsticks",
    price: 125,
    shortly: "",
    column: [
      "Вес (г / шт): 250",
      "Минимальная длина палки: 66см",
      "Максимальная длина палки: 133см",
      "Эргономичные рукоятки из пробки и пластика (PP) обеспечивают удобный, надежный захват",
      "Древко из качественного алюминиевого сплава 6061.",
      "3 секции компактно складываются.",
      "Победитовый наконечник палки можно закрыть резиновой пробкой.",
      "Кольца и резиновые наконечники в комплекте.",
      "Эргономичный регулируемый темляк с системой Clip-On",
    ],
    columnEng: [],
  },
  {
    id: 100068,
    titleEng: "TREKKING POLE TIP SET",
    title: "НАБОР НАКОНЕЧНИКОВ ДЛЯ ТРЕККИНГОВЫХ ПАЛОК",
    img: "tip.jpg",
    desc: "FORCIAZ (KOMПЛЕКТ ИЗ 2 ШТ)",
    descEng: '"FORCIAZ, Set of 2',
    category: "trekkingsticks",
    order: true,
    price: 16,
    column: [
      "Основной компонент - 95% резина синтетическая, 5% сталь",
      "Мягкие закругленные резиновые накладки: гасят шум и удары",
      "Долговечность: очень прочная, устойчивая к истиранию и перфорации резина",
      "Вес: 12 г каждый",
    ],
    columnEng: [
      "Main component - 95% synthetic rubber, 5% steel",
      "Soft rounded rubber caps: dampen noise and shocks",
      "Durability: very strong, resistant to wear and puncture",
      "Weight: 12 g each",
    ],
  },
  {
    id: 100069,
    titleEng: "TREKKING POLE TIP SET",
    title: "ЛЕТНИЕ КОЛЬЦА",
    img: "rings.jpg",
    desc: "ДЛЯ ТРЕККИНГОВЫХ ПАЛОК (KOMПЛЕКТ ИЗ 2 ШТ)",
    descEng: "SUMMER RINGS, (Set of 2)",
    category: "trekkingsticks",
    price: 17,
    column: [
      "бренд forciaz",
      "Прочный гибкий пластик (100% этиленвинилацетат)",
      "Долговечность: очень прочная, устойчивая к истиранию и перфорации резина",
      "Вес: 5 г каждый",
      "15 мм внутренний диаметр",
    ],
    columnEng: [
      "Brand: forciaz",
      "Durable flexible plastic (100% ethylene-vinyl acetate)",
      "Durability: very strong, resistant to wear and puncture",
      "Weight: 5 g each",
      "Inner diameter: 15 mm",
    ],
  },

  {
    id: 10030114,
    title: "Плащ-дождевик одноразовый ",
    img: "26656.jpg",
    desc: "цвета в ассортименте",
    category: "hermo",
    price: 7,
    column: [],
  },
  {
    id: 1030330114,
    title: "Плащ-дождевик одноразовый (плотнее)",
    img: "h600_3926592351.webp",
    desc: "цвета в ассортименте",
    category: "hermo",
    price: 9,
    column: [],
  },
  {
    id: 1000114,
    title: "ДОЖДЕВИК С капюшоном 150см",
    img: "raincoat.jpg",
    desc: "цвета в ассортименте",
    category: "hermo",
    price: 12,
    column: [
      "Имеет универсальный размер",
      "Быстро сохнет",
      "Стопроцентная водонепроницаемость, полностью защищает от дождя",
    ],
  },
  {
    id: 104300114,
    title: "Плащ - дождевик детский, 50 х 85 см",
    img: "5GQ2RAD.png",
    desc: "цвета в ассортименте",
    category: "hermo",
    price: 15,
    column: [
      "Быстро сохнет",
      "Стопроцентная водонепроницаемость, полностью защищает от дождя",
    ],
  },
  {
    id: 10430430114,
    title: "Дождевик-плащ плотный",
    img: "IMG_8620.JPG",
    desc: "розовый и синий",
    category: "hermo",
    price: 16,
    column: [],
  },
  {
    id: 1000115,
    title: "ДОЖДЕВИК-ПОНЧО",
    img: "raincoutBlack.jpg",
    desc: "Caperlan 100",
    category: "hermo",
    price: 45,
    order: true,
    column: [
      "Складной сложенный размер в собственном кармане: 20*18*3 см",
      "Вес: 350 г",
      "Водонепроницаемый компонент EVA защищает от 30 см сильного дождя (большого шторма) в течение 3 часов",
    ],
  },
  {
    id: 104500115,
    title: "Пончо Forclaz 10L",
    img: "media-600x600.jpg",
    desc: "Gri - MT 50",
    category: "hermo",
    order: true,
    price: 49,
    column: [
      "Ткань ПВХ 100% водонепроницаемая. Герметичные швы.",
      "Внутренний объем позволяет защищать рюкзак до 10 литров.",
      "Имеет собственный чехол для хранения.",
    ],
  },
  {
    id: 10450045115,
    title: "Дождевик-тент-плащ палатка ",
    img: "6_1000x1000.png",
    desc: "3 В 1 ",
    category: "hermo",
    price: 37,
    column: [],
  },
  {
    id: 143000115,
    title: "Дождевик - плащ Smile ",
    img: "21.38.49.png",
    desc: "Smile ",
    category: "hermo",
    price: 42,
    column: [],
  },
  // {
  //   id: 1000116,
  //   title: "ПОНЧО SOLOGNAC",
  //   img: "ponchoGreen.jpg",
  //   desc: "165-195 CM",
  //   category:'hermo',
  //   price: 79,
  //   column: [
  //     "Вес пончо составляет 280 грамм",
  //     "Водонепроницаемость составляет 1000 мм водяного столба ",
  //     "Размеры пончо в сложенном виде составляют 23,5 см x 20 см",
  //     "Основная ткань выполнена из 100% полиэтилентерефталата, а покрытие - из 100% полиуретана",
  //     "Хранить только после полного высушивания",
  //   ],
  // },
  // {
  //   id: 1000117,
  //   title: "ПЛАЩ-ДОЖДЕВИК CAPERIAN",
  //   img: "rainCoverCaperlan.jpg",
  //   desc: "Размер 1 на рост 150 - 173 см. Размер 2 на рост 173 - 190 см.",
  //   category:'hermo',
  //   price: 29,
  //   column: [
  //     "Состав: Материал ЭВА (этиленвинилацетат)",
  //     "Складывается в собственный карман для удобства переноски",
  //     "Стопроцентная водонепроницаемость, полностью защищает от дождя",
  //     "Хранить только после полного высушивания",
  //   ],
  // },
  {
    id: 104500118,
    title: "ЧЕХОЛ ОТ ДОЖДЯ ДЛЯ РЮКЗАКА ",
    img: "backpackcase.jpg",
    desc: "10-20 Л",
    category: "hermo",
    price: 29,
    column: [
      "Ткань с покрытием для защиты рюкзака от дождя.",
      "Подходит для всех туристических рюкзаков объемом от 10 до 20 л.",
      "2 эластичные ленты с крючками для крепления к лямкам рюкзака.",
      "Компактность при хранении в чехле.",
      "Легкий вес, всего 60 г.",
      "Ручная стирка. Дайте высохнуть перед хранением. Машинная стирка запрещена.",
    ],
  },
  {
    id: 1000118,
    title: "ЧЕХОЛ ОТ ДОЖДЯ ДЛЯ РЮКЗАКА ",
    img: "backpackcase.jpg",
    desc: "20-40 Л",
    category: "hermo",
    price: 34,
    column: [
      "Вес: 71 г.",
      "Крепится с помощью простой резинки, охватывающей Ваш рюкзак",
      "Водонепроницаемые чехлы из полиэстера и непромокаемые швы",
      "Предназначен для рюкзаков объемом от 40 до 60 литров",
    ],
  },
  {
    id: 100044118,
    title: "ЧЕХОЛ ОТ ДОЖДЯ ДЛЯ РЮКЗАКА ",
    img: "backpackcase.jpg",
    desc: "40-60 Л",
    category: "hermo",
    order: true,
    price: 38,
    column: [
      "Вес: 71 г.",
      "Крепится с помощью простой резинки, охватывающей Ваш рюкзак",
      "Водонепроницаемые чехлы из полиэстера и непромокаемые швы",
      "Предназначен для рюкзаков объемом от 40 до 60 литров",
    ],
  },
  {
    id: 1000119,
    title: "ЧЕХОЛ ОТ ДОЖДЯ ДЛЯ РЮКЗАКА",
    img: "backpackcase70-100.jpg",
    desc: "70-100 Л",
    category: "hermo",
    price: 46,
    column: [
      "Вес: 71 г.",
      "Крепится с помощью простой резинки, охватывающей Ваш рюкзак",
      "Водонепроницаемые чехлы из полиэстера и непромокаемые швы",
      "Предназначен для рюкзаков объемом от 70 до 100 литров",
    ],
  },
  {
    id: 142304300146,
    title: "Гермопакет для телефона",
    img: "ci4aqul40v.png",
    desc: "плавающий",
    category: "hermo",
    price: 15,
    column: [
      "Высота: 170 мм",
      "Ширина: 100 мм",
      "Изделие подходит для абсолютного большинства моделей смартфонов",
      "Кроме телефона, в него можно вложить деньги, украшения и прочие ценные вещи небольшого размера",
    ],
  },
  {
    id: 14304300146,
    title: "Гермопакет для телефона",
    img: "22.16.11.jpg",
    desc: "плавающий",
    category: "hermo",
    price: 12,
    column: [
      "Изделие подходит для абсолютного большинства моделей смартфонов",
      "Кроме телефона, в него можно вложить деньги, украшения и прочие ценные вещи небольшого размера",
    ],
  },
  {
    id: 1000146,
    title: "Гермопакет для телефона",
    img: "23.11.48.jpg",
    desc: "плавающий",
    category: "hermo",
    order: true,
    price: 24,
    column: [
      "Водонепроницаемость до 2 метров, в течение 30 минут при закрытии",
      "Cтандарт IPX8 / легкая и быстрая застежка-молния",
      "Регулируемая веревка для переноски это позволяет использовать сенсорные функции за пределами воды",
      "Размеры корпуса ширина 110 мм / высота 210 мм / толщина 8.5 мм",
      "Вес: 40 г",
      "Максимальный размер телефона: ширина 90 мм / высота 160 мм.",
    ],
  },
  {
    id: 104300146,
    title: "Гермопакет для телефона",
    img: "s-l1600.png",
    desc: "",
    category: "hermo",
    price: 19,
    order: true,
    column: [
      "Водонепроницаемость до 2 метров, в течение 30 минут при закрытии",
      "Регулируемая веревка для переноски это позволяет использовать сенсорные функции за пределами воды",
      "Размеры корпуса ширина 110 мм / высота 210 мм / толщина 8.5 мм",
      "Вес: 40 г",
      "Максимальный размер телефона: ширина 90 мм / высота 160 мм.",
    ],
  },

  {
    id: 11000147,
    title: "ВОДОНЕПРОНИЦАЕМАЯ СУМКА",
    img: "su-sizdirmaz-silindir-canta-10l-turuncu-2.jpg",
    desc: "ГЕРМОМЕШОК 10 Л ITIWT",
    category: "hermo",
    order: true,
    price: 79,
    column: [
      "Новая двойная полиэфирная ткань с ПВХ-покрытием для большей износостойкости",
      "Есть ручка для удобной переноски в качестве рюкзака",
      "Стандарт IPX4: устойчив к брызгам и кратковременному погружению, что делает ваши электронные устройства безопасными и надежными",
      "Однако перед тем, как поместить их в эту сумку, рекомендуется обеспечить дополнительную защиту, поместив телефоны в водонепроницаемые чехлы",
      "Размеры закрытой сумки: высота 43 см, диаметр 18 см.",
      "Oбъем использования 10 л",
      "Bес пустого составляет 315 г",
      "Основная ткань состоит из 60% полиэстера и 40% полиуретанового термогланца.",
    ],
  },
  {
    id: 1000147,
    title: "ВОДОНЕПРОНИЦАЕМАЯ СУМКА",
    img: "su-sizdirmaz-silindir-canta-30l-turuncu-3.jpg",
    desc: "ГЕРМОМЕШОК 30 Л ITIWT",
    category: "hermo",
    price: 99,
    column: [
      "Новая двойная полиэфирная ткань с ПВХ-покрытием для большей износостойкости",
      "Есть ручка для удобной переноски в качестве рюкзака",
      "Стандарт IPX4: устойчив к брызгам и кратковременному погружению, что делает ваши электронные устройства безопасными и надежными",
      "Однако перед тем, как поместить их в эту сумку, рекомендуется обеспечить дополнительную защиту, поместив телефоны в водонепроницаемые чехлы",
      "Размеры закрытой сумки: высота 63 см, диаметр 26 см.",
      "Oбъем использования 30 л",
      "Bес пустого составляет 655 г",
      "Основная ткань состоит из 60% полиэстера и 40% полиуретанового термогланца.",
    ],
  },
  {
    id: 1000148,
    title: "ВОДОНЕПРОНИЦАЕМЫЙ КОМПРЕССИОННЫЙ МЕШОК",
    img: "trekking-su-gecirmez-sikistirma-kilif-25-litre.jpg",
    desc: "25L FORCIAZ",
    category: "hermo",
    price: 45,
    column: [
      "Емкость: 25 литров.",
      "Вес: 200 грамм.",
      "Размеры: 78 см x 40 см.",
      "Чехол полностью водонепроницаемый при закрытии.",
      "Основная ткань состоит из 100% полиэтилентерефталатного покрытия.",
      "Этот чехол предназначен для того, чтобы вы могли разместить свои вещи, безопасно защитить их и носить в рюкзаке. Он защищает и хранит ваши вещи от влаги, дождя и пыли.",
    ],
  },
  // {
  //   id: 1000149,
  //   title: "ТРЕККИНГОВЫЙ ВОДОНЕПРОНИЦАЕМЫЙ МЕШОК",
  //   img: "Pokrowiec1.png",
  //   desc: "20L FORCIAZ",
  //   category: "hermo",
  //   price: 49,
  //   column: [
  //     "Вес: 107 грамм.",
  //     "Ширина: 48 см",
  //     "Глубина: 6 см",
  //     "В сложенном виде: Длина: 32 см x Ширина: 27 см x Высота: 38 см",
  //     "Закрытие типа типа - Canyon (без молнии), его можно затянуть сверху и по бокам",
  //     "Ткань с полиэфирным покрытием защищает ваши вещи от влаги, а также швы в мешке водонепроницаемы",
  //   ],
  // },
  {
    id: 100047,
    title: "ГАЗ ТУРИСТИЧЕСКИЙ",
    titleEng: "TOURIST GAS",
    img: "orcamp227-gr-gaz.jpg",
    descEng: "227GR COLLET",
    desc: "227GR ЦАНГОВЫЙ ",
    category: "gas/burner",
    price: 5,
    column: [
      "Горит в среднем 2-2.5 часа",
      "Клапан мирового класс 7/16",
      "Двойная система уплотнений",
      "30% пропан/70% бутан",
      "Производство: Турция",
    ],
    columnEng: [
      "Burns for an average of 2-2.5 hours",
      "World-class 7/16 valve",
      "Double sealing system",
      "30% propane / 70% butane",
      "Manufactured in Turkey",
    ],
  },

  {
    id: 10033046,
    title: "Газовый баллон Kovea (до -23°C)",
    titleEng: "TOURIST GAS",
    img: "3256200_6.jpg",
    desc: "110GR РЕЗЬБОВОЙ",
    descEng: "110GR THREADED",
    category: "gas/burner",
    order: true,
    price: 19,
    column: [
      "Горит в среднем 1-3 часа",
      "Клапан мирового класс 7/16",
      "Двойная система уплотнений",
      "изобутан 85%, пропан 15%",
      "Вес:	200 г",
      "Масса нетто:	110 г",
      "Южная Корея",
    ],
    columnEng: [
      "Burns for an average of 2.5-3 hours",
      "World-class 7/16 valve",
      "Double sealing system",
      "30% propane / 70% butane",
      "Manufactured in Turkey",
    ],
  },
  {
    id: 100046,
    title: "ГАЗ ТУРИСТИЧЕСКИЙ",
    titleEng: "TOURIST GAS",
    img: "gaz-kartusu-kamp.png",
    desc: "230GR РЕЗЬБОВОЙ",
    descEng: "230GR THREADED",
    category: "gas/burner",
    price: 21,
    column: [
      "Горит в среднем 2.5-3 часа",
      "Клапан мирового класс 7/16",
      "Двойная система уплотнений",
      "30% пропан/70% бутан",
      "Производство: Турция",
    ],
    columnEng: [
      "Burns for an average of 2.5-3 hours",
      "World-class 7/16 valve",
      "Double sealing system",
      "30% propane / 70% butane",
      "Manufactured in Turkey",
    ],
  },
  {
    id: 100045,
    title: "ГАЗ ТУРИСТИЧЕСКИЙ",
    titleEng: "TOURIST GAS",
    img: "IMG_9231.JPG",
    desc: "450GR РЕЗЬБОВОЙ",
    descEng: "450GR THREADED",
    category: "gas/burner",
    price: 35,
    column: [
      "Горит в среднем 4-5 часа",
      "Клапан мирового класс 7/16",
      "Двойная система уплотнений",
      "30% пропан/70% бутан",
      "Производство: Турция",
    ],
    columnEng: [
      "Burns for an average of 3-4 hours",
      "World-class 7/16 valve",
      "Double sealing system",
      "30% propane / 70% butane",
      "Manufactured in Turkey",
    ],
  },
  {
    id: 10043045,
    title: "Газовый баллон резьбовой",
    titleEng: "",
    img: "large_06834_msr.jpg",
    desc: "MSR 227 gr ",
    descEng: "",
    category: "gas/burner",
    price: 40,
    column: [
      "Вес(кг):227 г",
      "Топливо: Isobutane/ Propane Mixture",
      "Встроенный поплавковый датчик для определения остатка количества топлива",
      "Высокая производительность при низких температурах",
      "Обеспечение поддержания горения до полного окончания топливного остатка",
    ],
    columnEng: [],
  },
  {
    id: 120004308293,
    title: "Ветрозащитный экран для горелки",
    img: "screen.jpg",
    desc: "13,50 см ",
    category: "gas/burner",

    price: 27,

    column: [
      "Экран для защиты огня газовой горелки от ветра во время стоянок",
      "Вес: 200 г.",
      "Размеры: 59 см х 22 см",
      "Размер в сложенном виде: 9 см x 22 см",
      "Высота заслонки 22 см, поэтому она не подходит для печей, присоединенных к газовому баллончику 450 г, поскольку он уже имеет высоту 15 см.",
      "Перед использованием определить направление ветра. Развернуть экран и установить два загнутых крючка под газовой емкостью горелки. Расположить экран так, чтобы он закрывал горелку от ветра со всех сторон, оставляя при этом достаточно пространства для расположения самой горелки и блюда на ней. Воткнуть в землю колышки, чтобы обеспечить оптимальную устойчивость изделия к ветру.",
    ],
  },
  {
    id: 12000434308293,
    title: "Ветрозащитный экран",
    img: "ff0e256f2223e3c576d5f5953acfea92.webp",
    desc: "24 X 8,5 см",
    category: "gas/burner",

    price: 39,

    column: ["Основной материал: Алюминиевый сплав"],
  },
  {
    id: 110047,
    title: "ГОРЕЛКА СТРУЙНАЯ",
    titleEng: "JET BURNER",
    img: "gas-flame.jpg",
    desc: "FLAME GUN 915",
    category: "gas/burner",
    price: 19,
    column: [
      "Включается в одно касание",
      "С помощью пьезоподжига длина пламени регулируется",
      "В течение первых двух минут горелка должна разогреться",
      "о избежание вспышек газа на время разогрева не допускается отклонение горелки более чем на 75 градусов от вертикального положения",
      "После прогрева можно работать с горелкой под лобым углом",
      "Температура пламени достигает 1300°С",
    ],
    columnEng: [
      "Turns on with a single touch",
      "Flame length is adjustable using the piezoelectric igniter",
      "For the first two minutes, the stove needs to warm up",
      "To prevent gas flashes during warming up, the stove should not be tilted more than 75 degrees from the vertical position",
      "After warming up, the stove can be operated at any angle",
      "The flame temperature reaches 1300°C",
    ],
  },
  {
    id: 100051,
    title: "Горелка - плита с пьезоподжигом",
    titleEng: "GAS BURNER - STOVE",
    img: "burner44.jpg",
    desc: "ДЛЯ ЦАНГОВЫХ БАЛЛОНОВ",
    descEng: "FOR COLLET CYLINDERS",
    category: "gas/burner",
    order: true,
    price: 37,
    column: ["Материал: Алюминий", "Вес: 150 гр", "Пьезоподжиг"],
    columnEng: ["Material: Aluminum", "Weight: 150 g", "Piezo ignition"],
  },
  {
    id: 100049,
    title: "ГОРЕЛКА ТУРИСТИЧЕСКАЯ",
    titleEng: "THE BURNER IS A TOURIST",
    img: "burner32.jpg",
    desc: " ",
    category: "gas/burner",
    price: 29,
    order: true,
    columnEng: [
      "Powerful stainless steel burner",
      "World-class 7/16 valve",
      "Durable brass tap",
      "Manufactured in Turkey",
    ],
    column: [
      "Мощная нержавеющая горелка",
      "Клапан мирового класс 7/16",
      "Долговечный латунный кран",
      "Производство: Турция",
    ],
  },
  {
    id: 100050,
    title: "ГОРЕЛКА ТУРИСТИЧЕСКАЯ",
    titleEng: "THE BURNER IS A TOURIST",
    img: "Campsor_folding.jpg",
    desc: "Campsor (складная) ",
    descEng: "Campsor (folding)",
    category: "gas/burner",
    price: 49,
    column: ["Материал: Алюминий", "Вес: 150 гр", "Пьезоподжиг"],
    columnEng: ["Material: Aluminum", "Weight: 150 g", "Piezo ignition"],
  },

  {
    id: 100052,
    title: "АДАПТЕР",
    titleEng: "ADAPTER",
    img: "adapter.jpg",
    desc: "ДЛЯ ЗАПРАВКИ РЕЗЬБОВОГО БАЛЛОНА",
    descEng: "FOR REFILLING THREADED CARTRIDGE",
    category: "gas/burner",
    price: 12,
    column: [
      "Многоразовый",
      "Вес: 10 гр",
      "Пополняйте баллоны только на открытом воздухе!",
      "Избегайте превышения объема газа",
    ],
    columnEng: [
      "Reusable",
      "Weight: 10 g",
      "Refill cartridges only outdoors!",
      "Avoid overfilling with gas",
    ],
  },
  {
    id: 100053,
    title: "АДАПТЕР",
    titleEng: "ADAPTER",
    img: "adapter2.jpg",
    desc: "ДЛЯ ГАЗОВОЙ ГОРЕЛКИ",
    descEng: "FOR GAS STOVE",
    category: "gas/burner",
    price: 24,
    column: [
      "Переключение с цанговых баллонов на резьбу",
      "Алюминиевый сплав",
      "Вес: 44 гр",
      "4.1*3*3.5",
    ],
    columnEng: [
      "Switching from bayonet cartridges to threaded",
      "Aluminum alloy",
      "Weight: 44 g",
      "Dimensions: 4.1 x 3 x 3.5 cm",
    ],
  },
  {
    id: 1004053,
    title: "Переходник",
    titleEng: "ADAPTER",
    img: "IMG_8621.JPG",
    desc: "для газовых баллонов",
    descEng: "FOR GAS STOVE",
    category: "gas/burner",
    price: 21,
    column: [],
    columnEng: [
      "Адаптер для бутанового бака и переключатель заправки канистры соедините адаптер с переключателем для достижения заправки газа между канистрой и плоским баком.",
      "Стандартный соединитель клапанов 7/16''-28NS.",
      "Двойные кольца FKM в переключателе заправки канистры для уплотнения и предотвращения утечки, очень безопасны в использовании.",
      "Как адаптер, так и заправочный переключатель имеют конструкцию из алюминиевого сплава, прочную и многоразовую.",
      "Материал: алюминиевый сплав + медь + ABS",
    ],
  },
  {
    id: 1003407653,
    title: "Горелка под угли ",
    titleEng: "ADAPTER",
    img: "IMG_8624.JPG",
    desc: "черная",
    descEng: "FOR GAS STOVE",
    category: "gas/burner",
    price: 29,
    column: [],
    columnEng: [
      "Скорость розжига угля	от 3 до 5 минут",
      "Газовая горелка без пьезоподжига",
      "Купив данную горелку, вам больше не нужно будет долго ждать у костра, пока уголь накалится – вы сможете ускорить процесс в несколько раз.",
    ],
  },
  {
    id: 10034053,
    title: "Портативная мини-горелка",
    titleEng: "",
    img: "IMG_8625.AVIF",
    desc: "для резьбовых баллонов",
    descEng: "",
    category: "gas/burner",
    price: 46,
    column: [],
    columnEng: ["48гр", "Нержавеющая сталь"],
  },
  {
    id: 100044,
    title: "ФОНАРИК НАЛОБНЫЙ",
    titleEng: "HEADLAMP",
    img: "lantern_green.jpg",
    desc: "УЗ-918",
    descEng: "UZ-918",
    category: "lantern",
    order: true,
    price: 23,
    column: [
      "2 режима работы",
      "Питание от встроенной батареи",
      "USB-кабель в комплекте",
      "Направление регулируется",
    ],
    columnEng: [
      "2 operating modes",
      "Powered by built-in battery",
      "USB cable included",
      "Direction adjustable",
    ],
  },
  // {
  //   id: 100041,
  //   titleEng: "HEADLAMP TREKKING ONNIGHT 100",
  //   title: "ФОНАРЬ НАЛОБНЫЙ TREKKING ONNIGHT",
  //   img: "lantern_onnight.jpg",
  //   desc: "Дальность  освещения: 25м (80 люмен).",
  //   descEng:'"Illumination range: 25m (80 lumens)"',
  //   category: "lantern",
  //   price: 42,
  //   order:true,
  //   column: [
  //     "3 режима освещения: красный, эко-белый, яркий белый.",
  //     "Питание:3 шт ААА(в наборе)",
  //     "Автономность: 30 ч в эко режиме.10 ч в интенсивном режиме",
  //     "Вес: 80г",
  //     "Защита от брызг и ударов",
  //   ],
  //   columnEng:["3 lighting modes: red, eco-white, bright white.",
  //   "Power: 3 x AAA batteries (included)",
  //   "Autonomy: 30 hours in eco mode, 10 hours in intense mode",
  //   "Weight: 80g",
  //   "Protection against splashes and shocks"]
  // },
  {
    id: 100042,
    title: "ФОНАРЬ НАЛОБНЫЙ 30 LUMEN ONNIGHT 50",
    titleEng: "HEADLAMP 30 LUMEN ONNIGHT 50",
    img: "lantern_black.jpg",
    desc: "Дальность  освещения: 15м (30 люмен). 1 режима освещения.",
    descEng: "Illumination range: 15m (30 lumens). Single lighting mode.",
    category: "lantern",

    price: 25,
    column: [
      "Питание:2 шт ААА(в наборе)",
      "Автономность: 25 часов",
      "Вес: 65г",
      "Одна кнопка ON/OFF для удобства использования в перчатках или рукавицах",
      "Регулируемый ремешок",
      "Защита от брызг и ударов",
    ],
    columnEng: [
      "Power: 2 x AAA batteries (included)",
      "Autonomy: 25 hours",
      "Weight: 65g",
      "One ON/OFF button for ease of use with gloves or mittens",
      "Adjustable strap",
      "Protection against splashes and shocks",
    ],
  },
  {
    id: 10032042,
    title: "Фонарь налобный ",
    titleEng: "",
    img: "2573021.png",
    desc: "3ААА 80 Lümen ONNIGHT 100 (3 режима)",
    descEng: "",
    category: "lantern",
    price: 42,
    column: [
      "Широкий световой луч дальностью 25 м (80 лм): идеален для походов и на стоянке",
      "30 ч в эко режиме.10 ч в интенсивном режиме. Для работы 3 бат.LR03-AAA (в комп)",
      "3 режима освещения: красный, белый энергосберегающий, белый интенсивный",
      "Устойчив к брызгам воды (IPX4) и ударам",
      "Вес: 80 г",
    ],
    columnEng: [],
  },
  {
    id: 100043,
    title: "USB-ФОНАРЬ НАЛОБНЫЙ FORCLAZ HL100",
    titleEng: "USB-HEADLAMP FORCLAZ HL100",
    img: "lantern-usb.jpg",
    descEng: "Illumination range: 25m (120 lumens)",
    desc: "Дальность  освещения: 25м (120 люмен)",
    category: "lantern",
    price: 69,
    column: [
      "3 режима освещения: красный, эко-белый, яркий белый",
      "Питание: зарядка с помощью микро-USB",
      "Время зарядки: около 2,5 - 3 ч",
      "Автономность: 17 ч в эко режиме. 4 ч в интенсивном режиме",
      "Вес: 72г",
      "Регулируемый ремешок",
      "Защита от брызг и ударов",
    ],
    columnEng: [
      "3 lighting modes: red, eco-white, bright white",
      "Power: rechargeable via micro-USB",
      "Charging time: approximately 2.5 - 3 hours",
      "Autonomy: 17 hours in eco mode, 4 hours in intense mode",
      "Weight: 72g",
      "Adjustable strap",
      "Protection against splashes and shocks",
    ],
  },
  {
    id: 10340043,
    title: "НАЛОБНЫЙ ФОНАРЬ ONNIGHT 100",
    // titleEng: "USB-HEADLAMP FORCLAZ HL100",
    img: "balikcilik-kafa-lambasi-onnight-100-uv.jpg",
    descEng: "Illumination range: 25m (80 lumens)",
    desc: "Дальность  освещения: 25м (80 люмен)",
    category: "lantern",
    price: 44,
    column: [
      "Широкий/направленный пучок, макс. дальность 25 м",
      "3 режима освещения: красный свет, белый эко, белый сильный",
      "30 ч. - эко-режим 10 ч. - сильный свет В комплекте 3 батарейки LR03-AAA.",
      "80 люмен в режиме сильного света",
      "Устойчивость к водяным брызгам (IPX4)",
    ],
    // columnEng:["3 lighting modes: red, eco-white, bright white",
    // "Power: rechargeable via micro-USB",
    // "Charging time: approximately 2.5 - 3 hours",
    // "Autonomy: 17 hours in eco mode, 4 hours in intense mode",
    // "Weight: 72g",
    // "Adjustable strap",
    // "Protection against splashes and shocks"]
  },

  {
    id: 110044,
    title: "ЛАМПА / ФОНАРЬ ДЛЯ КЕМПИНГА",
    titleEng: "CAMPING LAMP / LANTERN",
    img: "kamp-lambasi-100-lumen-kirmizi-bl100.jpg",
    desc: "QUECHUA BL100",
    category: "lantern",
    price: 55,
    column: [
      "Красный режим: 20 лумен (яркость света)",
      "Белый режим: 50 лумен",
      "Яркий белый режим: 100 лумен",
      "Высота: 12 см",
      "Диаметр: 8 см",
      "Вес: 240 г",
      "Лампа работает на 4 батареях типа AA-IR6",
      "26 часов работы на максимальной яркости (100 луменов)",
      "60 часов работы на белом режиме (50 луменов)",
      "130 часов работы в красном режиме (20 луменов)",
    ],
    columnEng: [
      "Red mode: 20 lumens (light brightness)",
      "White mode: 50 lumens",
      "Bright white mode: 100 lumens",
      "Height: 12 cm",
      "Diameter: 8 cm",
      "Weight: 240 g",
      "The lamp operates on 4 AA-IR6 batteries",
      "26 hours of operation at maximum brightness (100 lumens)",
      "60 hours of operation in white mode (50 lumens)",
      "130 hours of operation in red mode (20 lumens)",
    ],
  },
  {
    id: 1103443044,
    title: "ФОНАРЬ ДЛЯ КЕМПИНГА",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "8615283-product_image-p2440715_960x.png",
    desc: "BL40 (40 люменов)",
    category: "lantern",
    price: 35,
    column: [
      "Используется на открытом воздухе или в палатке, кемпинге или бивуаке.",
      "40 люменов | Рекомендуется для освещения одного человека",
      "50 часов автономной работы на полную мощность (40 люменов)",
      "Одна кнопка, один режим работы | Встроенный язычок для подвешивания",
      "Класс IPX4: защита от дождя в любых условиях",
      "Размеры фонаря: высота 4 см, диаметр 7 см.",
      "Вес: 145 г",
    ],
    // columnEng:["Red mode: 20 lumens (light brightness)",
    // "White mode: 50 lumens",
    // "Bright white mode: 100 lumens",
    // "Height: 12 cm",
    // "Diameter: 8 cm",
    // "Weight: 240 g",
    // "The lamp operates on 4 AA-IR6 batteries",
    // "26 hours of operation at maximum brightness (100 lumens)",
    // "60 hours of operation in white mode (50 lumens)",
    // "130 hours of operation in red mode (20 lumens)"]
  },
  {
    id: 1103444433044,
    title: "Фонарь для кемпинга 10 люмен",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "kishenkovij-likhtar-onbright-50-10-lyumen-chornij.jpg",
    desc: "на батарейках черно-желтый ",
    category: "lantern",
    price: 24,
    column: [
      "1 режим освещения, эластичный ремешок для крепления в любом месте",
      "Удобная кнопка Вкл/Выкл.",
      "Вес: 48 г.",
      "50 ч. Работает с одной батарейкой LR6-AA (в комплекте).",
      "Устойчив к брызгам воды",
      "Маленького размера, легко помещается в кармане",
      "Широкий луч 15 м (10 лм) идеально подходит для ходьбы и освещения лагеря",
    ],
  },
  {
    id: 11034444433044,
    title: "Лампа для кемпинга",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "FaPx6OhjaIcWKxTfR5KtEb43UuQHAa.jpg",
    desc: "BLACK DIAMOND MOJI R+ LANTERN",
    category: "lantern",
    price: 145,
    column: [
      "Перезаряжаемый: питание от встроенного литий-ионного аккумулятора емкостью 1500 мАч с портом зарядки micro-USB",
      "Максимальная мощность 200 люмен",
      "Матовый шар создает ровный и яркий полезный свет",
      "Интерфейс с двумя кнопками прост в использовании и обеспечивает регулировку яркости, полный спектр цветов и режимы освещения костра для создания подходящего освещения в любой ситуации.",
      "Установите непрерывный цикл цветов радуги с режимами стробоскопа и постоянного цвета для развлечений на кемпинге с использованием света",
      "Двойная петля для подвешивания позволяет легко подвешивать в палатке",
      "Защита от штормов IPX 4: выдерживает дождь и мокрый снег под любым углом",
      "Питание от встроенного литий-ионного аккумулятора с портом зарядки micro-USB или USB-C. (Тип порта USB см. в описании продукта)",
    ],
  },
  {
    id: 1903444433044,
    title: "Фонарик 100 люмен",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "1288707.png",
    desc: "на батарейках черно-желтый ",
    category: "lantern",
    price: 65,
    order: true,
    column: [
      "100 люмен в режиме 100% 40 люмен в режиме 50% Освещение LED",
      "Защита от брызг воды",
      "Диапазон 15 м – при широком освещении, 100 м – при интенсивном освещении.",
      "3 режима: 50%, полная мощность 100%, мигающий режим.",
      "8 часов в режиме 100%, 20 часов в режиме 50%.",
      "Вес: 108 г.",
    ],
  },

  {
    id: 1903444933044,
    title: "Фонарь Forclaz DYNAMO",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "el-feneri-15-lumen-ye.png",
    desc: "100 (15 люмен)",
    category: "lantern",
    price: 46,
    column: [
      "Этот фонарик создан для тех, кто ищет дополнительное освещение, которое всегда под рукой.",
      "Расстояние освещения шириной 10 метров (15 лм)",
      "Всего 1 режим освещения",
      "Благодаря эргономичной ручке и кнопке его можно использовать даже в перчатках",
      "1 минута зарядки = 6 минут свечения. Максимум 12 минут автономной работы",
      "Вес: 78 г.",
      "Устойчив к брызгам (IPX4) и ударам благодаря резиновой головной части.",
    ],
    // columnEng:["Red mode: 20 lumens (light brightness)",
    // "White mode: 50 lumens",
    // "Bright white mode: 100 lumens",
    // "Height: 12 cm",
    // "Diameter: 8 cm",
    // "Weight: 240 g",
    // "The lamp operates on 4 AA-IR6 batteries",
    // "26 hours of operation at maximum brightness (100 lumens)",
    // "60 hours of operation in white mode (50 lumens)",
    // "130 hours of operation in red mode (20 lumens)"]
  },
  {
    id: 190349933044,
    title: "Фонарь налобный Petzl TIKKA (blue)",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "IMG_9941.JPG",
    desc: "300 люмен",
    category: "lantern",
    order: true,
    price: 114,
    column: [
      "Световой поток: до 300 люмен",
      "Температура света: белый холодный 6000-7000К",
      "3 режима яркости: 300, 100, 7 люмен",
      "Время работы и дальность свечения:300 люмен - до 2 ч - до 65 метро;100 люмен - до 10 ч - до 40 метров;7 люмен - до 100 ч - до 10 метров;",
      "Кнопка включения/выключения/переключения режимов",
      "Крепление: съёмный головной ремень",
      "Защита от воды: IPX4",
      "Питание: 3хААА (батарейки или аккумуляторы 1.2-1.5V) или аккумулятор Petzl Core",
      "Размер: 34х45х56 мм",
      "Вес: 92 г",
      "Комплект поставки:фонарь; головной ремень; 3х ААА батарейки (в зависимости от партии поставки); блистерная упаковка",
    ],
    // columnEng:["Red mode: 20 lumens (light brightness)",
    // "White mode: 50 lumens",
    // "Bright white mode: 100 lumens",
    // "Height: 12 cm",
    // "Diameter: 8 cm",
    // "Weight: 240 g",
    // "The lamp operates on 4 AA-IR6 batteries",
    // "26 hours of operation at maximum brightness (100 lumens)",
    // "60 hours of operation in white mode (50 lumens)",
    // "130 hours of operation in red mode (20 lumens)"]
  },
  {
    id: 19034993433044,
    title: "Фонарь налобный Petzl",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "TIKKINA.jpg",
    desc: "TIKKINA 300 lumen (Hybrid)",
    category: "lantern",

    price: 125,
    column: [
      "Налобный фонарь TIKKINA с яркостью 300 люмен и равномерным комфортным освещением осветит ваши вечера под звездами! Он так же удобен для чтения в палатке, как и для прогулок или походов. Он прост в использовании, с одной кнопкой для управления и симметричным головным ремнем для регулировки размера. Компактный и легкий, он легко помещается в кармане, поэтому вы можете брать его с собой куда угодно. TIKKINA поставляется с 3 стандартными батарейками, а также совместима с перезаряжаемым аккумулятором CORE благодаря своей конструкции HYBRID CONCEPT.",
      "Компактный и легкий: всего 92 г",
      "- широкий равномерный луч для комфортного ближнего освещения",
      "- три уровня белого освещения: максимальное время работы, средний режим (лучший баланс между мощностью и временем работы) и максимальная яркость",
      "Легко использовать:- одна кнопка для быстрого и простого выбора яркости или цвета освещения, - удобная регулировка угла наклона освещения- съемный головной ремень с симметричными креплениями для легкой регулировки размера",
      "HYBRID CONCEPT: TIKKINA поставляется с 3 батарейками AAA/LR03, а также совместим с аккумулятором CORE (не входит в комплект). Фонарь автоматически определяет источник энергии и регулирует мощность освещения",
      "Совместим с креплениями HELMET ADAPT и BIKE ADAPT 2, вы можете закрепить фонарь на различных типах касок или на велосипеде",
      "ВНИМАНИЕ: Фонарь не сертифицирован для использования на дорогах: при использовании в зоне, регулируемой правилами дорожного движения, фонари Petzl не заменяют необходимые стандартные фонари.",
    ],
    // columnEng:
  },
  {
    id: 1903499393044,
    title: "Фонарь налобный Black Diamond ASTRO",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "lUqF06z0s1xcs.jpg",
    desc: "300 люмен",
    category: "lantern",
    order: true,
    price: 135,
    column: [
      "Перезаряжаемый: питание от встроенного литий-ионного аккумулятора емкостью 1500 мАч с портом зарядки micro-USB",
      "Максимальная мощность 300 люмен",
      "Эластичная повязка на голову из переработанного материала с удобной для кожи конструкцией из волокна Repreve",
      "Настройки включают полную мощность, затемнение и стробоскоп.",
      "Память яркости позволяет включать и выключать фару на последнем использованном уровне яркости без возврата к максимальной яркости.",
      "Защита от штормов IPX 4: выдерживает дождь и мокрый снег под любым углом",
      "Функция цифровой блокировки защищает от случайного использования при хранении в рюкзаке или кармане.",
    ],
  },
  {
    id: 190349993044,
    title: "Фонарь налобный Black Diamond COSMO",
    // titleEng: "CAMPING LAMP / LANTERN",
    img: "black-diamond-storm-450.jpg",
    desc: "350 люмен",
    category: "lantern",
    price: 143,
    column: [
      "Световой поток: до 350 люмен",
      "Максимальное расстояние(я) при максимальной настройке: 100 м",
      "Максимальное расстояние(я) при самой низкой настройке: 9 м",
      "Максимальное время работы при максимальных настройках: FL1 5 ч; резерв 42 ч",
      "Максимальное время работы при минимальных настройках: 150 ч.",
      "Водонепроницаемость ",
      "Память яркости позволяет включать и выключать свет с выбранной яркостью, не возвращаясь к настройкам по умолчанию.",
    ],
  },

  {
    id: 10040120,
    title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
    img: "17.04.49.jpg",
    desc: "3-Й КАТЕГОРИИ Julbo Sherpa Matt Black",
    category: "sunglasses",
    price: 219,

    column: [
      "лёгкие классические альпинистские очки с кожаными боковыми вставками",
      "стальной оправой и линзами из поликарбоната Spectron 3+",
      "Категория линз подойдет для многих  видов спорта, они отлично защищают от ультрафиолета и убирают блики с любых поверхностей.",
      "темные линзы SpectronX5 S3 категории защиты",
      "высокая эластичность оправы, небольшая глубина",
      "увеличенный угол обзора по вертикали и горизонтали",
      "классическая система вентиляции",
      "боковые шторки из кожи",
      "эластичные дужки",
      "длина дужек: 110 мм",
    ],
  },
  {
    id: 1043400120,
    title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
    img: "2480425.png",
    desc: "3-Й КАТЕГОРИИ QUECHUA MH140",
    category: "sunglasses",
    price: 108,

    column: [
      "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
      "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
      "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
      "Линзы из высокопрочного поликарбонатного пластика",
      "Вес: 26 г.",
      "100.0% Поликарбонат",
    ],
  },
  {
    id: 105400120,
    title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
    img: "2482114.png",
    desc: "3-Й КАТЕГОРИИ QUECHUA MH100",
    category: "sunglasses",
    price: 65,

    column: [
      "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды.",
      "Особая форма: защищает глаза от ветра, брызг, плохой погоды.",
      "Особая форма дужек: гарантирует фиксацию очков",
      "Вес: 18 г.",
      "100.0% Поликарбонат",
    ],
  },
  {
    id: 1000121,
    title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
    img: "2482151.png",
    desc: "3-Й КАТЕГОРИИ QUECHUA MH120A",
    category: "sunglasses",
    price: 98,
    column: [
      "Поляризационные линзы: ограничивают отражения от блестящих поверхностей",
      "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
      "Вес: 21 г.",
      "Особая форма дужек: гарантирует фиксацию очков",
      "Особая форма: защищает глаза от ветра, брызг, плохой погоды.",
    ],
  },
  {
    id: 100043122,
    title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
    img: "sunglasses3.jpg",
    desc: "3-Й КАТЕГОРИИ QUECHUA MH500",
    category: "sunglasses",
    price: 91,
    column: [
      "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
      "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
      "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
      "Большие линзы для лучшего периферического обзора",
      "Линзы из высокопрочного поликарбонатного пластика",
      "Вес: 29 г.",
      "100.0% Поликарбонат",
    ],
  },
  {
    id: 100540122,
    title: "Очки для велосипеда ST 100 ",
    img: "72317535.png",
    desc: "категория 0",
    category: "sunglasses",
    price: 49,
    column: [
      "Линзы категории 0, защита на 100% от УФ-лучей.",
      "Линзы третьей категории 0% защита от УФ-лучей: для облачности или ночных условий.",
      "Линзы большого размера: улучшенный периферийный обзор.",
      "Кончики дужек с противоскользящим покрытием: предотвращают соскальзывание.",
      "Прилегающая форма: защита глаз от ветра, брызг и непогоды.",
    ],
  },
  {
    id: 1100124,
    title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
    img: "sunglasses6.jpg",
    desc: "VAN RYSEL ROADR 500",
    category: "sunglasses",
    order: true,
    price: 110,
    column: [
      "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
      "Материал противоударный поликарбонат",
      "Геометрия: сферическая",
      "Линзы 3 категории фильтруют свет на 18% - 43%",
      "100% УФ-фильтр",
      "Большой размер стекла обеспечивает широкий угол обзора",
      "Воздушно-проницаемая структура  предотвращает туман ",
      "Вес: 32 г.",
    ],
  },
  {
    id: 110043124,
    title: "Винтажные солнцезащитные очки",
    img: "ef8fea74-cee3.png",
    desc: "KEITHION",
    category: "sunglasses",

    price: 41,
    column: [
      "Ветрозащитная рама из поликарбоната",
      "Линза с антибликовым покрытием",
    ],
  },
  {
    id: 14300540122,
    title: "Регулируемый ремешок для очков",
    img: "604225.png",
    desc: "MH ACC 100",
    category: "sunglasses",
    price: 23,
    column: [
      "Ремешок, предназначенный для туристов, желающих сохранить очки",
      "Вшитые дужки, совместимые с большинством заушников очков",
    ],
  },
  {
    id: 14300590122,
    title: "Плавучий ремешок для очков",
    img: "723184043.png",
    desc: "",
    category: "sunglasses",
    price: 26,
    column: [
      "Плавучий шнурок удерживает на воде очки весом до 38 грамм",
      "Удерживает очки на голове благодаря зажимному кольцу, и на шее",
      "Система застежки натягивается: совместима с больш-вом моделей спортивных очков",
    ],
  },
  {
    id: 1430900590122,
    title: "Чехол для очков",
    img: "case-560.jpg",
    desc: "",
    category: "sunglasses",
    price: 29,
    column: [
      "Жёсткий чехол с текстильным термически формованным корпусом для защиты очков от царапин, давления и ударов.",
      "Застежка-молния.",
      "Внутренняя поверхность из микрофибры: защищает от появления царапин.",
      "Размеры: 17 см х 7 см x 7,5 см",
      "Этот чехол весит 47 грамм.",
    ],
  },
  // {
  //   id: 1000123,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses4.jpg",
  //   desc: "3-Й КАТЕГОРИИ KALENJI RUNSTYLE 2",
  //   category: "sunglasses",
  //   price: 88,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Большие линзы для лучшего периферического обзора",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 24 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  // {
  //   id: 1000124,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses5.jpg",
  //   desc: "3-Й КАТЕГОРИИ ROCKRIDER ST100",
  //   category: "sunglasses",
  //   price: 84,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Большие линзы для лучшего периферического обзора",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 24 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  {
    id: 10033094,
    title: "TEPMOC STANLEY CLASSIC - 1 Л",
    img: "TEPMOCSTANIEY.jpg",
    desc: "Vacuum Bottle",
    category: "termoryukzak",
    price: 159,
    column: [
      "Имеет прочный двухслойный корпус, вакуумную термоизоляцию, устойчив к ударам и падениям",
      "Оснащен складной ручкой, поэтому термос удобно держать в одной руке, а также наливать напитки",
      "При изготовлении термоса используется высококачественная пищевая нержавеющая сталь, благодаря чему он не ржавеет, не окисляется, не влияет на вкусовые качества напитков",
      "Сохраняет тепло до 28 часов, холод - до 34 часов",
      "Крышка емкостью 325 мл используется как чашка для питья, что удобно в путешествии, для выезда на природу, охоту или рыбалку",
      "Материал: нержавеющая сталь 18/8",
      "Вес: 810 г",
      "Объем: 1000 мл",
      "Объем крышки: 325 мл",
      "Размеры: 117 х 94 х 358 мм",
    ],
  },
  {
    id: 100094,
    title: "TEPMOC STANLEY CLASSIC - 1.4 Л",
    img: "s-l960.png",
    desc: "Vacuum Bottle",
    category: "termoryukzak",
    price: 175,
    column: [
      "Имеет прочный двухслойный корпус, вакуумную термоизоляцию, устойчив к ударам и падениям",
      "Оснащен складной ручкой, поэтому термос удобно держать в одной руке, а также наливать напитки",
      "При изготовлении термоса используется высококачественная пищевая нержавеющая сталь, благодаря чему он не ржавеет, не окисляется, не влияет на вкусовые качества напитков",
      "УДЕРЖАНИЕ ТЕПЛА - 40 ч",
      "УДЕРЖАНИЕ ХОЛОДА - 35 ч, напитки со льдом – 144 часа",
      "Прочный и долговечный термос сохраняет тепло 40 часов, холод – 35 часов, напитки со льдом – 144 часа.",
      "Материал: нержавеющая сталь 18/8",
      "Вес: 940 г",
      "Объем: 1400 мл",
      "РАЗМЕРЫ (СМ) - 11,9х9,9х35,8",
    ],
  },

  {
    id: 1003343094,
    title: "Термос STAN CLASSIC ",
    img: "ClassicLegendaryBottle2.0qtNightfall-1.webp",
    desc: "1,9L (nightfall)",
    category: "termoryukzak",
    price: 199,
    column: [
      "45 ЧАСОВ ГОРЯЧО",
      "2 ДНЯ ХОЛОДА",
      "8 дней со льдом ",
      " В эту бутылку можно вместить целую чашку кофе или чего-либо еще, что вы пьете, сохраняя его горячим или холодным до 45 часов. ",
      "можно мыть в посудомоечной машине",
    ],
  },

  {
    id: 10034383094,
    title: "Термокружка STANLEY 0,9L",
    img: "2024-12-29.png",
    desc: "черный, синий, розовый, оранжевый",
    category: "termoryukzak",
    price: 136,
    column: [
      "Двойные стенки стакана Flip Straw Tumbler сохраняют содержимое полностью прохладным в течение нескольких часов и предотвращают протекание бутылки.",
      "V-образная форма бутылки позволяет поместить ее в любой большой подстаканник, что делает ее идеальной для питья в машине и в тренажерном зале. Складная ручка делает работу еще проще, ведь ее можно легко переносить с места на место.",
      "Что делает эту бутылку совершенно уникальной, так это то, что стакан для соломинок IceFlow™ изготовлен из переработанного пластика, добытого в океане.",
      "Нержавеющая сталь 18/8, без BPA.",
      "Непротекающая соломинка",
    ],
  },
  {
    id: 10430095,
    title: "ПОХОДНЫЙ ТЕРМОС ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ",
    img: "thermose3.jpg",
    desc: "1.5 л серо-черный QUECHUA",
    category: "termoryukzak",
    price: 149,
    column: [
      "Два раскрытия: широкое/для розлива",
      "Ручка, кружка в комплекте",
      "Начальная температура 95°С:83°C через 6 ч, 74°С через 12 ч, 60°С через 24 ч.",
      "Складная ручка для транспортировки и легкости розлива",
      "Вымыть и высушить перед хранением. Хранить в чистом и сухом месте",
      "Размеры: Ø 12,5 см x 29 см",
      "Вес: 980 г.",
      "Наполните термос кипятком и оставьте на 5 минут.Затем замените воду нужным напитком. Закройте термос.Чем более заполнен термос, тем лучше он держит температуру",
      "Перед первым использованием тщательно трижды промойте все элементы изделия чистой водой",
    ],
  },

  {
    id: 10043089,
    title: "ТЕРМОС",
    img: "2201772.png",
    desc: "1 литр Quechua",
    category: "termoryukzak",
    price: 95,

    column: [
      "Начал. темп. 95°С: 82°C после 6 ч., 73°C после 12 ч., 60°C после 24 ч",
      "Чем более заполнен термос, тем лучше он держит температуру",
      "Легкость ухода благодаря крышке с резьбой. Внутренняя стенка из нерж. стали",
      "Крышка содержит встроенный фильтр, который фильтрует такие продукты, как фруктовые настойки или крупный чайный лист. Не подходит для очень мелкого чайного листа.",
      "Размеры: Ø 8,6 см x 30 см",
      "Вес: 405 г",
    ],
  },
  {
    id: 1043043095,
    title: "Термос",
    img: "3191441.png",
    desc: "0,7 литра Quechua",
    category: "termoryukzak",
    price: 86,
    order: true,
    column: [
      "Начал. темп. 95°С: 81°C через 6 ч., 70°C через 12 ч., 55°C через 24 ч.",
      "Частично отверните крышку для вылив.жидкости. Кружка в комплекте. Нерж. сталь.",
      "Легкость ухода благодаря крышке с резьбой. Внутренняя стенка из нерж. стали",
      "Двойные стенки из нерж. стали 18/8 (304), воздушная прослойка между стенками.",
    ],
  },
  {
    id: 1004090,
    title: "TEPMOC",
    img: "quechua-8734555.png",
    desc: "0,4 литра QUECHUA",
    category: "termoryukzak",
    price: 59,

    column: [
      "Начал. темп. 95°С: 76°C через 6 ч., 63°C через 12 ч., 48°C через 24 ч.",
      "Частично отверните крышку для вылив.жидкости. Кружка в комплекте. Нерж. сталь.",
      "Легкость ухода благодаря крышке с резьбой. Внутренняя стенка из нерж. стали",
      "Двойные стенки из нерж. стали 18/8 (304), воздушная прослойка между стенками.",
    ],
  },
  {
    id: 10430090,
    title: "Термос 0,5L Quechua MH500",
    img: "0723192137.png",
    desc: " с пластиковой крышкой",
    category: "termoryukzak",
    price: 69,
    order: true,
    column: [
      "Начал. темп. 95°С: остывает до 67°C за 6 ч. Будьте осторожны с гор. жидкостями.",
      "Быстрое открывание одной рукой, запираемая крышка с ручкой. Нержавеющая сталь.267 г.",
      "Легкость ухода благодаря крышке с резьбой. Внутренняя стенка из нержавеющей стали",
      "Двойные стенки из нержавеющей стали 18/8 (304), воздушная прослойка между стенками.",
    ],
  },

  {
    id: 1110095,
    title: "Ланч-бокс",
    img: "thermos20.jpg",
    desc: "QUECHUA 0.5 Л",
    category: "termoryukzak",
    price: 89,
    column: [
      "Способность сохранять продукты горячими от 95°C до 61°C в течение 6 часов.",
      "Легко чистится благодаря широкому горлышку (8 см).",
      "Конструкция с двойными стенками из нержавеющей стали 18/8  с воздушным зазором между ними.",
      "Широкое горлышко и завинчивающаяся крышка.",
      "Ручка для переноски",
      "Вес: 280 грамм.",
      "Объем: 0,5 литра",
      "Pазмеры: 11 см (высота) х диаметр 10 см.",
    ],
  },
  {
    id: 1110096,
    title: "Ланч-бокс",
    img: "termo21.jpg",
    desc: "QUECHUA MN500 0.8 Л",
    category: "termoryukzak",
    price: 119,
    column: [
      "Способность сохранять продукты горячими от 95°C до 61°C в течение 6 часов.",
      "Легко чистится благодаря широкому горлышку (8 см).",
      "Конструкция с двойными стенками из нержавеющей стали 18/8  с воздушным зазором между ними.",
      "Широкое горлышко и завинчивающаяся крышка.",
      "Ручка для переноски",
      "Вес: 530 грамм.",
      "Объем: 0,8 литра",
      "Pазмеры: 12 см (высота) х диаметр 14 см.",
    ],
  },
  {
    id: 100082,
    title: "ТЕРМОКРУЖКА ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ 0.38 Л ",
    titleEng: "Stainless Steel Thermo Mug 0.38 L",
    img: "mug.png",
    desc: "Хаки и Красная",
    category: "termoryukzak",
    price: 55,
    column: [
      "Двойная стенка с воздушной прослойкой позволяет сохранять температуру напитка",
      "Размеры: Ø 8,5 см x 11,2 см (ширина с ручкой 11 см)",
      "Вес: 255 г",
    ],
    columnEng: [
      "Double wall with an air layer helps maintain the temperature of the beverage",
      "Dimensions: Ø 8.5 cm x 11.2 cm (width with handle 11 cm)",
      "Weight: 255 g",
    ],
  },
  {
    id: 1003433094,
    title: "Термокружка Stanley Mug ",
    img: "09856-009.jpg",
    desc: "0,35L",
    category: "termoryukzak",
    price: 89,
    column: [
      "5 ЧАСОВ ГОРЯЧО",
      "6 ЧАСОВ ХОЛОДНОЙ",
      "26 часов со льдом",
      "Технология NeverLeak™ включает в себя уникальную трехпозиционную крышку, которая открывается, обеспечивая удобство питья, а затем плотно фиксируется при закрытии.",
      "Фирменная вакуумная изоляция Stanley сохранит ваш кофе или чай горячими в течение нескольких часов.",
      "Нержавеющая сталь 18/8",
      "Двойная вакуумная изоляция",
      "Легко очищаемая крышка, можно мыть в посудомоечной машине",
    ],
  },
  {
    id: 10905082,
    title: "Термокружка Stanley ",
    titleEng: "Stainless Steel Thermo Mug 0.38 L",
    img: "9DGmNrt71S546.png",
    desc: "0,25L",
    category: "termoryukzak",
    price: 79,
    column: [
      "Нержавеющая сталь",
      "Двойная вакуумная изоляция",
      "Крышка с простой кнопкой позволяет открывать, пить и закрывать ее одной рукой.",
      "Более тонкий дизайн для небольших мощностей",
      "Легкоочищаемая крышка, можно мыть в посудомоечной машине",
      "Подходит для большинства кофеварок",
    ],
    columnEng: [],
  },

  // {
  //   id: 100090,
  //   title: "TEPMOC - 0,4 Л",
  //   img: "thermose12.jpg",
  //   desc: "QUECHUA ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ",
  //   category: "termoryukzak",
  //   price: 59,
  //   order:true,
  //   column: [
  //     "Двойные стенки из нерж. стали 18/8 (304), воздушная прослойка между стенками",
  //     "Легкость ухода благодаря крышке с резьбой. Внутренняя стенка из нерж. стали",
  //     "Начал. темп. 95°С: 76°C через 6 ч., 63°C через 12 ч., 48°C через 24 ч",
  //     "Размер: Ø 7 см x 22,4 см",
  //     "Вес: 290 г",
  //   ],
  // },

  {
    id: 1000150,
    title: "ТЕРМОРЮКЗАК",
    img: "kamp-esnek-sogutucu-10l.jpg",
    desc: "QUECHUA 10L",
    category: "termoryukzak",
    order: true,
    price: 59,
    column: [
      "Сохраняет продукты холодными в течение 6 часов без лотка для льда и до 6 часов с льдом (вес льда - 600 г).",
      "Регулируемый плечевой ремень для удобной переноски",
      "Вес: 217 грамм",
      "Размеры: 30×21×10 см",
    ],
  },
  {
    id: 10032087,
    title: "Складная бутылка Vapur + карабин",
    titleEng: " ",
    img: "vapur-cvet-sinij.jpg",
    desc: "26 Х 11 СМ",
    category: "bottle",
    price: 14,
    column: ["Основной материал: ПП (полипропилен)"],
  },
  {
    id: 100302087,
    title: "Питьевая система 2 литра",
    titleEng: " ",
    img: "6752f2e937.jpg",
    desc: "FORCLAZ",
    category: "bottle",
    price: 62,
    column: [
      "Широкое заливное отверстие.",
      "Легко чистить",
      "Объем 2 литра: подходит для длительных походов",
      "Соска из удобного силикона, угол 45 градусов для удобства питья.",
      "С помощью этого практичного гидратора вы можете пить без помощи рук во время походов, не снимая рюкзак.",
      "Хранить в чистом и сухом месте. Во избежание образования плесени поилку также можно хранить в морозильной камере.",
      "Не для спирта, масла, микроволновой печи, посудомоечной машины, воды > 40 °C.",
      "",
    ],
  },
  {
    id: 100087,
    title: "БУТЫЛКА TRIBAN 0.65 Л",
    titleEng: "Bottle TRIBAN 0.65 L ",
    img: "botle.jpg",
    desc: "Фляга для велосипеда",
    category: "bottle",
    price: 28,
    order: true,
    column: [
      "Мягкая насадка на мундштук для удобства питья",
      "Пластик без привкуса и запаха",
      "Объем: 650 мл.",
      "Широкая прозрачная полоса с мерной шкалой для точного дозирования",
      "Накручивающаяся крышка и мундштук для гарантии полной герметичности",
      "Подходит для всех типов флягодержателей",
      "Пластик не имеет ни вкуса, ни запаха",
    ],
  },
  {
    id: 100088,
    title: "БУТЫЛКА ПЛАСТИКОВАЯ 0,8 Л",
    img: "botle2.jpg",
    desc: "QUECHUA",
    category: "bottle",
    price: 19,
    column: [
      "Быстродействующий затвор можно открыть всего одним поворотом",
      "Прозрачный и безвкусный пластик",
      "Объем: 800 мл.",
      "Вес: 103 г ",
      "Большое горлышко, широкая ручка для удобной транспортировки",
      "Бутылка 100% полиэтилентерефталат (ПЭТФ) Крышка 100% полипропилен (ПП)",
    ],
  },
  {
    id: 100074,
    titleEng: "QUECHUA KETTLE FOR 1L",
    title: "ЧАЙНИК QUECHUA НА 1 Л",
    img: "teapotDecatlon.jpg",
    descEng: "Made of stainless steel",
    desc: "Из нержавеющей стали",
    category: "dishes",
    price: 84,
    column: [
      "Объем: 1 л",
      "Вес: 340 г.",
      "Предназначено для всех видов костров и особенно для костров на дровах",
    ],
    columnEng: [
      "Volume: 1 liter",
      "Weight: 340 g.",
      "Designed for all types of campfires, especially wood fires",
    ],
  },
  {
    id: 10033074,
    titleEng: "",
    title: "Чайник для кемпинга 800 мл",
    img: "bee569efm.webp",
    descEng: "Made of stainless steel",
    desc: "Из нержавеющей стали",
    category: "dishes",
    price: 49,
    column: [
      "Объем: 0.8 л",
      "Чайник из анодированного алюминия - легкий, прочный и устойчивый к коррозии. ",
      "В комплекте сетчатый чехол",
    ],
    columnEng: [],
  },
  {
    id: 100073,
    title: "КЕМПИНГОВЫЙ ОБЕДЕННЫЙ НАБОР",
    titleEng: "CAMPING DINING SET",
    img: "setForCamping.jpg",
    descEng: "1 pot, 2 deep plates, 2 sets of cutlery",
    desc: "для 2 человек",
    category: "dishes",
    order: true,
    price: 109,
    column: [
      "Объем: 1,6 л",
      "Вес:560 г",
      "1 кастрюля,2 глубокие тарелки, 2 столовых набора",
      "Посуда подходит для всех видов огня (дрова, глаз, индукция)",
    ],
    columnEng: [
      "Volume: 1.6 liters",
      "Weight: 560 g",
      "The cookware is suitable for all types of fire (wood, gas, induction)",
    ],
  },
  {
    id: 1005324073,
    title: "КЕМПИНГОВЫЙ ОБЕДЕННЫЙ НАБОР",
    titleEng: "CAMPING DINING SET",
    img: "765.jpeg",
    // descEng:'1 pot, 2 deep plates, 2 sets of cutlery',
    desc: "для 1 человек",
    category: "dishes",
    price: 84,
    column: [
      "Объем: 1,1 л",
      "1 кастрюля, 1 крышка-сито, 1 миска на 0,45 литра и 1 набор столовых приборов из 3 предметов",
      "Посуда подходит для всех видов огня (дрова, глаз, индукция)",
    ],
    columnEng: [
      "Volume: 1.6 liters",
      "Weight: 560 g",
      "The cookware is suitable for all types of fire (wood, gas, induction)",
    ],
  },
  {
    id: 10450073,
    title: "КЕМПИНГОВЫЙ ОБЕДЕННЫЙ НАБОР",
    titleEng: "CAMPING DINING SET",
    img: "195819716.webp",
    descEng: "",
    desc: "",
    category: "dishes",
    price: 72,
    column: [
      "котелок со складными ручками и крышкой 124*106 мм (Объем-800 мл)",
      "котелок малый со складными ручками и крышкой 116*61 мм",
      "складной нож-1 шт",
      "складная вилка-1 шт",
      "кладная ложка-1 шт",
      "чехол для транспортировки",
      "",
    ],
    columnEng: [""],
  },
  {
    id: 1045043073,
    title: "Набор посуды ​WIDESEA ",
    titleEng: "CAMPING DINING SET",
    img: "6471638532.webp",
    descEng: "",
    desc: " 2 котелков 0,8 и 0,5л",
    category: "dishes",
    price: 66,
    column: [
      "котелок со складными ручками и крышкой 124*106 мм (Объем-800 мл)",
      "котелок малый со складными ручками и крышкой (Объем-500 мл )116*61 мм",
    ],
    columnEng: [""],
  },
  {
    id: 100072,
    titleEng: "MULTIFUNCTIONAL FORK-SPOON (8 IN 1)",
    title: "Ложка-вилка(8 В 1)",
    img: "fork-spoon.webp",
    desc: "Из нержавеющий стали (цена за 1 единицу)",
    descEng: "Made of stainless steel (price per unit)",
    category: "dishes",
    price: "15",
    column: [
      "Pазмер:	18*4 см",
      "Толщина:	1.5 мм",
      "Вес:	44 гр",
      "Функции: ложка, вилка, зубчатое лезвие, открывалка для бутылок, гарпун (веревка-отверстие), консервный нож, 5 гаечных ключей (м2, м3, м3.5, м4, м5), подвесные отверстия",
    ],
    columnEng: [
      "Size: 18*4 cm",
      "Thickness: 1.5 mm",
      "Weight: 44 g",
      "Functions: spoon, fork, serrated edge, bottle opener, harpoon (rope-hole), can opener, 5 wrenches (M2, M3, M3.5, M4, M5), hanging holes",
    ],
  },
  {
    id: 100076,
    titleEng: "PLASTIC CAMPING SET - KNIFE, FORK, AND SPOON",
    title: "НАБОР ПОХОДНЫЙ ПЛАСТИКОВЫЙ",
    img: "PLASTICCAMPING.jpg",
    descEng: "The knife, fork, and spoon are joined together",
    desc: "скреплены вместе",
    category: "dishes",
    price: 13,
    column: [
      "Вес: 21 г.",
      "Размер: 15,5 x 2 x 3 см",
      "Эти пластиковые (из полиамида) столовые приборы особенно пригодны при использовании с набором посуды с антипригарным покрытием, поскольку они его не повреждают",
    ],
    columnEng: [
      "Weight: 21 g.",
      "Size: 15.5 x 2 x 3 cm",
      "These plastic (made of polyamide) utensils are particularly suitable for use with non-stick coated cookware, as they do not damage it",
    ],
  },
  {
    id: 100080,
    title: "СТОЛОВЫЙ ПРИБОР ПЛАСТИКОВЫЙ СКЛАДНОЙ",
    titleEng: "FOLDABLE PLASTIC CUTLERY",
    img: "forkspoon.jpg",
    desc: "Вилка/ложка",
    descEng: "FORK/SPOON",
    category: "dishes",
    price: 14,
    column: [
      "Вес: 11 г.",
      "Большая длина (18,5 см)",
      "Размеры в разложенном виде: 18,5 x 3,5 x 2 см",
      "Размеры в сложенном виде: 10 x 3,5 x 3 см",
      "Занимая мало места в рюкзаке (10 см в сложенном виде)",
      "Структурированный материал дна тарелки, предотвращающий распространение тепла от горячих жидкостей для удобства захвата",
    ],
    columnEng: [
      "Weight: 11 g",
      "Large length (18.5 cm)",
      "Dimensions when unfolded: 18.5 x 3.5 x 2 cm",
      "Dimensions when folded: 10 x 3.5 x 3 cm",
      "Takes up little space in the backpack (10 cm when folded)",
      "Structured material at the bottom of the plate, preventing the spread of heat from hot liquids for easy handling",
    ],
  },
  {
    id: 10430072,
    titleEng: "",
    title: "Набор столовых приборов",
    img: "bdc4c3d9a549d143-goods.webp",
    desc: "Из нержавеющий стали",
    descEng: "",
    category: "dishes",
    price: "26",
    column: [],
    columnEng: [],
  },
  {
    id: 100081,
    titleEng: "Clamp for pots, pans, and other items",
    title: "ЗАЖИМ ДЛЯ КАСТРЮЛИ И СКОВОРОДКИ И ПРОЧИХ ПРЕДМЕТОВ",
    img: "clip.jpg",
    desc: "ЧАПЕЛЬНИК",
    descEng: "Pot holde",
    category: "dishes",
    price: 15,
    column: [
      "Легкий алюминиевый чапельник обезопасит приготовление пищи на газовой горелке или таганке",
      "Размер:  12,5*2,8*2,5 см",
      "Вес: 40 гр",
    ],
    columnEng: [
      "Lightweight aluminum pot holder ensures safe cooking on a gas burner or portable stove",
      "Size: 12.5 x 2.8 x 2.5 cm",
      "Weight: 40 g",
    ],
  },

  {
    id: 1540004383,
    title: "Кружка ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ",
    titleEng: "Stainless Steel Camping Mug",
    img: "1-28.png",
    desc: "QUECHUA 0,35 литра ",
    category: "dishes",
    price: 13,
    order: true,
    column: ["Пищевая нержавеющая сталь", "Вес: 65 г"],
    columnEng: [],
  },
  {
    id: 15400083,
    title: "Кружка-матрешка ",
    titleEng: "Stainless Steel Camping Mug",
    img: "s-l960.webp",
    desc: "нержавеющая сталь",
    category: "dishes",
    order: true,
    price: 19,
    column: [],
    columnEng: [],
  },
  {
    id: 100083,
    title: "Кружка ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ",
    titleEng: "Stainless Steel Camping Mug",
    img: "thermose2.jpg",
    desc: "QUECHUA 0,4 литра ",
    category: "dishes",
    price: 32,
    order: true,
    column: [
      "2 ручки. Мерные деления внутри",
      "Выполнена из сверхпрочной нержавеющей стали",
      "Размеры: Ø 9 см x 8 см Объем: 0,45 литра (макс.) / 0,4 литра (полезный объем)",
      "Вес: 120 г",
    ],
    columnEng: [
      "2 handles. Measuring marks inside",
      "Made of ultra-durable stainless steel",
      "Dimensions: Ø 9 cm x 8 cm Capacity: 0.45 liters (max.) / 0.4 liters (useful volume)",
      "Weight: 120 g",
    ],
  },
  {
    id: 100084,
    title: "Кружка с уникальной печатью и встроенным карабином",
    titleEng: "Sublimation Mug with Your Print",
    img: "cup2.jpg",
    desc: "нанесение любого принта",
    descEng: "Customizable Sublimation Mug",
    category: "dishes",
    order: true,
    price: 40,
    column: [
      "Металлическая",
      "С карабином",
      "Рекомендовано мыть вручную",
      "Стоимость указана за готовую кружку с напечатанным изображением",
      "Диаметр: 7.5 см ",
      "Высота 9 см",
      "Объем 300 мл",
    ],
    columnEng: [
      "Metallic",
      "With carabiner",
      "Hand wash recommended",
      "Cost is for a mug with printed image",
      "Diameter: 7.5 cm",
      "Height: 9 cm",
      "Capacity: 300 ml",
    ],
  },
  {
    id: 100054084,
    title: "Кружка с уникальной печатью и встроенным карабином",
    titleEng: "Sublimation Mug with Your Print",
    img: "IMG_0112.JPG",
    desc: "нанесение любого принта",
    descEng: "Customizable Sublimation Mug",
    category: "dishes",
    price: 35,
    order: true,
    column: ["Металлическая", "Рекомендовано мыть вручную", "Объем 300 мл"],
    columnEng: [],
  },
  {
    id: 14500077,
    titleEng: "BOWL (0.45 L) QUECHUA",
    title: "МИСКА (0,45 Л.) QUECHUA",
    img: "1_org_zoom.jpg",
    desc: "Легкая и прочная миска",
    descEng: "Lightweight and durable bowl",
    category: "dishes",
    price: 13,
    column: [
      "Вес: 69 г.",
      "16 см в диаметре x 4,5 см",
      "Отверстие сбоку тарелки для легкой переноски снаружи рюкзака с помощью карабина",
      "Структурированный материал дна тарелки, предотвращающий распространение тепла от горячих жидкостей для удобства захвата",
    ],
    columnEng: [
      "Weight: 69 g.",
      "19 cm in diameter x 5 cm",
      "Side hole in the plate for easy attachment outside the backpack using a carabiner",
      "Structured material at the bottom of the plate to prevent the spread of heat from hot liquids for ease of handling",
    ],
  },
  {
    id: 100077,
    titleEng: "BOWL (0.5 L) QUECHUA",
    title: "МИСКА (0,5 Л.) QUECHUA",
    img: "dish.jpg",
    desc: "Легкая и прочная миска",
    descEng: "Lightweight and durable bowl",
    category: "dishes",
    price: 15,
    column: [
      "Вес: 71 г.",
      "16 см в диаметре x 4,5 см",
      "Отверстие сбоку тарелки для легкой переноски снаружи рюкзака с помощью карабина",
      "Структурированный материал дна тарелки, предотвращающий распространение тепла от горячих жидкостей для удобства захвата",
    ],
    columnEng: [
      "Weight: 71 g.",
      "16 cm in diameter x 4.5 cm",
      "Side hole in the plate for easy attachment outside the backpack using a carabiner",
      "Structured material at the bottom of the plate to prevent the spread of heat from hot liquids for ease of handling",
    ],
  },
  {
    id: 100400777,
    titleEng: "",
    title: "Тарелка металлическая",
    img: "flat-plate-0-45l.png",
    desc: "неглубокая 0,45Л",
    descEng: "",
    category: "dishes",
    price: 29,
    order: true,
    column: [],
    columnEng: [],
  },
  {
    id: 1087040777,
    titleEng: "",
    title: "Тарелка металлическая",
    img: "home-kitchens.png",
    desc: "глубокая ",
    descEng: "",
    category: "dishes",
    price: 23,
    order: true,
    column: [],
    columnEng: [],
  },
  {
    id: 100654077,
    titleEng: "",
    title: "Кувшин пластиковый",
    img: "125157.png",
    desc: "MH100 0,25L",
    descEng: "",
    category: "dishes",
    price: 14,
    column: [
      "Ручка д/удобного захвата, вставляется в другие кружки, отверстие д/крючка. 36 г",
      "Из ударопрочного и жаропрочного полипропилена (100°C)",
    ],
    columnEng: [],
  },
  {
    id: 10054073,
    title: "посуда из нержавеющей стали",
    titleEng: "CAMPING DINING SET",
    img: "8dcd-aede1cf3ab55.webp",
    desc: "600 мл ",
    category: "dishes",
    price: 35,
    column: ["Объем: 600 мл", "Материал: Нержавеющая сталь"],
    columnEng: [],
  },

  {
    id: 110099,
    title: "СКЛАДНОЙ НОЖ",
    img: "opinel.jpeg",
    desc: "INOX OPINEL 9 CM",
    category: "knife",
    price: 79,
    column: [
      "Вес: 57 г.",
      "Длина лезвия: 9 см",
      "Общая длина: 21 см",
      "Тип стали: 12(27 SANDVIK) / Твердость: 57-58 HRC",
      "Очень устойчиво к ржавчине",
      "Этот нож относится к серии 'TRADITIONAL' и имеет номер N°09. Он был разработан Джозефом Опинелем в 1896 году и считается одним из первых ножей в этой серии.",
    ],
  },
  {
    id: 11430099,
    title: "СКЛАДНОЙ НОЖ",
    img: "opinel.jpeg",
    desc: "INOX OPINEL 7/8 CM",
    category: "knife",
    price: 70,
    column: [
      // "Вес: 57 г.",
      // "Длина лезвия: 9 см",
      // "Общая длина: 21 см",
      // "Тип стали: 12(27 SANDVIK) / Твердость: 57-58 HRC",
      // "Очень устойчиво к ржавчине",
      // "Этот нож относится к серии 'TRADITIONAL' и имеет номер N°09. Он был разработан Джозефом Опинелем в 1896 году и считается одним из первых ножей в этой серии.",
    ],
  },
  {
    id: 100098,
    title: "СКЛАДНОЙ ОХОТНИЧИЙ НОЖ",
    img: "knife3.jpg",
    desc: "AXIS 75 GRIP V2",
    category: "knife",
    price: 75,
    column: [
      "Вес: 100 г.",
      "Mатериал: нержавещая сталь",
      "Кнопка блокировки/разблокировки лезвия",
      "Фиксируется нож в кармане или на поясе с помощью металлической клипсы",
      "Общая длина в разложенном виде: 17 см",
      "Толщина лезвия: 2,5 мм",
      "Длина рукоятки: 9,7 см",
      "Длина лезвия: 7.5 см",
      "Угол 20°",
    ],
  },
  {
    id: 100099,
    title: "СКЛАДНОЙ ОХОТНИЧИЙ НОЖ",
    img: "knife4.jpg",
    desc: "AXIS 85 GRIP V2",
    category: "knife",
    order: true,
    price: 86,
    column: [
      "Вес: 100 г.",
      "Mатериал: нержавещая сталь",
      "Кнопка блокировки/разблокировки лезвия",
      "Фиксируется нож в кармане или на поясе с помощью металлической клипсы",
      "Общая длина в разложенном виде: 20 см",
      "Толщина лезвия: 3 мм",
      "Длина рукоятки: 9,7 см",
      "Длина лезвия: 8.5 см",
      "Угол 20°",
    ],
  },

  {
    id: 1000100,
    title: "ШВЕЙЦАРСКИЙ АРМЕЙСКИЙ НОЖ",
    img: "knife5.jpg",
    desc: "VICTORINOX 14 ФУНКЦИЙ",
    category: "knife",
    order: true,
    price: 162,
    column: [
      "Многофункциональный складной нож, включающий в себя 14 инструментов",
      "Длина в сложенном виде: 111 мм",
      "Толщина лезвия: 2.5 мм",
      "Пластиковый кейс для ножей с замком система для безопасной транспортировки",
      "Лезвие: 100 % нержавеющая сталь",
      "Внешний корпус: 100 % акрилонитрилбутадиенстирол ",
      "Ручка: 100 % акрилонитрилбутадиенстирол (АБС)",
    ],
  },
  {
    id: 1076080100,
    title: "Нож Victorinox Spartan Blue Trans",
    img: "6955_big.jpg",
    desc: "длина лезвия 91 мм",
    category: "knife",
    price: 130,
    column: [
      "Большое лезвие",
      "Малое лезвие",
      "Штопор",
      "Консервный нож с: – Малой отвёрткой",
      "Открывалка для бутылок с: – Отвёрткой – Инструментом для снятия изоляции",
      "Шило, кернер",
      "Кольцо для ключей",
      "Пинцет",
      "Зубочистка",
      "Нож  надолго сохранит свои качества и характеристики, если регулярно:Удалять с него загрязнения. Промывать мыльным раствором. Просушивать пазы и лезвия.",
    ],
  },
  {
    id: 1000101,
    title: "СКЛАДНОЙ РЫБОЛОВНЫЙ НОЖ",
    img: "knife7.jpg",
    desc: "CAPERLAN KN FID",
    category: "knife",
    price: 49,
    column: [
      "Нержавеющая сталь с тефлоновым покрытием для защиты от ржавчиныв",
      "Устойчив к морской воде",
      "Его ручка плотно прилегает к вашей руке благодаря формованной эргономичной конструкции",
      "Общая длина: 19 см",
      "Длина лезвия:8,5 см",
      "Толщина лезвия: 3 мм",
      "Лезвие: 100 % нержавеющая сталь",
      "Рукоятка: 100 % полипропилен (ПП)",
      "Cмажьте лезвие силиконовым маслом и храните в сухом месте",
    ],
  },
  {
    id: 100430101,
    title: "Нож-Multitool Valkyrie",
    img: "14.19.29.jpg",
    desc: "черный",
    category: "knife",
    price: 94,
    column: [
      "Многофункциональный черный швейцарский карманный нож Valkyrie для кемпинга",
    ],
  },

  {
    id: 103043330101,
    title: "Нож-Multitool Caperlan",
    img: "multifunctional-fishing-plier.png",
    desc: "12 функций",
    category: "knife",
    price: 95,
    column: [
      "Многофункциональный черный швейцарский карманный нож Valkyrie для кемпинга",
    ],
  },
  {
    id: 103043234330101,
    title: "Карманный швейцарский нож",
    img: "1_org_zoom.png",
    desc: "7 функций",
    order: true,
    category: "knife",
    price: 52,
    column: [""],
  },
  {
    id: 10304323330101,
    title: "Вилка-ложка-нож",
    img: "coghlans-kamp.avif",
    desc: "COGHLANS",
    category: "knife",
    price: 59,
    column: [
      "Съемные вилка и ложка и консервный нож из нержавеющей стали.",
      "Петля для крепления к шнурку или брелку.",
    ],
  },
  {
    id: 1030432093330101,
    title: "Нож складной туристический COGHLANS",
    img: "coghlans.avif",
    desc: "5 функций",
    category: "knife",
    price: 48,
    column: [
      "Нож Открывалка для бутылок",
      "Открывалка для консервных банок",
      "Штопор",
      "Отвертка",
      "Зубочистка и пинцет",
    ],
  },

  {
    id: 1000103,
    title: "Точилка для ножа",
    img: "sharpener.png",
    desc: "SOLOGNAC",
    category: "knife",
    price: 36,
    column: [
      "Для эффективной заточки: Проведите 5 раз лезвием в направлении от рукоятки к острию с 'карбидной' (металлической) стороны, затем 5 раз с 'керамической' (белой) стороны. Усилие должно быть сильным, но не чрезмерным. Повторяйте до достижения необходимого результата",
      "Угол 20°",
      "1 сторона из углерода",
      "1 сторона из керамики для точной заточки лезвия",
      "Ручка из синтетич. каучука для удобства захвата и АБС-пластик для прочности",
      "Длина: 8,5 см.",
      "Высота: 6,5 см.",
      "Вес: 60 г.",
    ],
  },
  {
    id: 109800103,
    title: "Походная ручная пила",
    img: "16.07.38.jpg",
    desc: "",
    category: "knife",
    price: 26,
    column: [],
  },
  {
    id: 1098000103,
    title: "Походная ручная пила",
    img: "el-testeresi-bushcraft.avif",
    desc: "Coghlan's - Bushcraft",
    category: "knife",
    price: 76,
    column: [
      "Эту компактную, но эффективную пилу можно взять с собой куда угодно.",
      "При весе 76,5 грамм вы даже не заметите, что он у вас в сумке.",
      "Поместите его на древесину и позвольте пиле резать, слегка надавливая, не прилагая усилий.",
      "Во избежание риска поломки будьте осторожны и не зацепите его при толкании назад.",
      "Если на лезвии осталась смола, его можно очистить оливковым маслом.",
      "Длина лезвия: 10 см",
      "Общая длина: 21,5 см. : 76,5 г.",
    ],
  },
  {
    id: 109800098103,
    title: "Походная ручная пила",
    img: "testeresi-bushcraft.avif",
    desc: "Coghlan's - Bushcraft",
    category: "knife",
    price: 98,
    column: [
      "При весе 155 грамм вы даже не заметите, что он у вас в сумке.",
      "Поместите его на древесину и позвольте пиле резать, слегка надавливая, не прилагая усилий.",
      "Во избежание риска поломки будьте осторожны и не зацепите его при толкании назад.",
      "Если на лезвии осталась смола, его можно очистить оливковым маслом.",
      "Длина лезвия: 18 см.",
      "Длина в закрытом виде: 24 см.",
      "Длина в открытом виде: 40 см.",
      "Вес: 155 г.",
    ],
  },
  {
    id: 109800087981030,
    title: "Чехол для ножа",
    img: "p2306573.png",
    desc: "120/40/20 mm",
    category: "knife",
    price: 35,
    column: [
      "Предназначен для хранения складных ножей Axis 75 и 85 или любых ножей длиной до 120/40/20 мм.",
      "Прочность : полиэстеровая ткань плотностью 600 денье с усилением.",
      "Вес (в г)	40 г",
    ],
  },
  // {
  //   id: 1000104,
  //   title: "СКЛАДНОЙ СТУЛ QUECHUA",
  //   img: "chaiSmall.jpg",
  //   desc: "до 110 кг",
  //   category: "chair",
  //   price: 72,
  //   column: [
  //     "Вес:1кг",
  //     "Компактность в сложенном виде",
  //     "Вшитый ремень для транспортировки",
  //     "Простая система раскладывания. Резинка для фиксации в сложенном виде",
  //     "Максимальная нагрузка: 110 кг",
  //   ],
  // },
  // {
  //   id: 1000105,
  //   title: "СКЛАДНОЙ СТУЛ С ПОДСТАКАННИКОМ QUECHUA",
  //   img: "chairBig.jpg",
  //   desc: "до 110 кг",
  //   category: "chair",
  //   price: 99,
  //   column: [
  //     "Вес:1кг",
  //     "2 подлокотника | 1 подстаканник | Высота сиденья: 41 см",
  //     "Компактность в сложенном виде",
  //     "Размеры: 77,5 x 20 x 17 см",
  //     "Общий вес: 2,8 кг",
  //     "Встроенный ремень для легкости транспортировки и переноски",
  //     "Превосходная прочность конструкции и ткани",
  //     "Максимальная нагрузка: 110 кг",
  //   ],
  // },

  {
    id: 10001097,
    title: "Стул - кресло",
    img: "22.28.38.jpg",
    desc: "без подстаканников",
    category: "chair",
    price: 42,
    column: ["Максимальная нагрузка: 110 кг"],
  },
  {
    id: 1000107,
    title: "СКЛАДНОЙ СТУЛ ",
    img: "17.04.02.jpg",
    desc: "С ПОДСТАКАННИКОМ  до 110 кг",
    order: true,
    category: "chair",
    price: 45,
    column: [
      "Вес:1кг",
      "2 подлокотника | 1 подстаканник | Высота сиденья: 40 см",
      "Компактность в сложенном виде",
      "Общий вес: 2,3 кг",
      "В комплекте чехол для переноски",
      "Максимальная нагрузка: 110 кг",
    ],
  },
  {
    id: 104300107,
    title: "СКЛАДНОЙ СТУЛ",
    img: "2024-08-2417.14.44.jpg",
    desc: "до 150 кг",
    category: "chair",
    order: true,
    price: 59,
    column: [
      "Материал звена: Железо",
      "Материал кронштейна: Железо",
      "Материал сиденья: Оксфорд",
      "Размер в разложенном виде: 44 см x 44 см x 70 см",
      "Размер в сложенном виде: 20 см x 20 см x 70 см",
      "Вес: 1,6 кг",
    ],
  },
  {
    id: 104349800107,
    title: "СКЛАДНОЙ СТУЛ",
    img: "22.33.47.jpg",
    desc: "до 110 кг",
    category: "chair",
    price: 139,
    order: true,
    column: [
      "Легкая и прочная алюминиевая рама",
      "Размер: 60*56*35 см",
      "Воздухопроницаемые сетчатые вставки для повышенного комфорта",
      "В комплект входит сумка для удобного хранения и транспортировки",
      "Сетка из быстросохнущего нейлона с обеих сторон",
      "Максимальная грузоподъемность: 110 кг",
      "вес: 950 гр ",
    ],
  },
  {
    id: 1020109,
    title: "СКЛАДНОЙ СТОЛ ",
    img: "стол1.jpg",
    desc: "ТКАНЕВЫЙ, ДЛЯ КЕМПИНГА",
    category: "chair",
    price: 80,
    column: [
      "Размер в раскрытом виде 73*55*48см, вес 750 г. ",
      "Изготовлен из алюминиевого корпуса и прочной ткани Оксфорд.",
      "Удобство транспортировки обеспечивает специальная сумка на плечевом ремне. Соединительные пластмассы изготовлены из АБС-пластика.",
      "Это легкий и прочный продукт, чрезвычайно простой в установке и подходящий для использования в любое время года.",
    ],
  },
  {
    id: 10230109,
    title: "СКЛАДНОЙ СТОЛ ",
    img: "2579643.png",
    desc: "Quechua MH100",
    category: "chair",
    price: 120,
    order: true,
    column: [
      "Размеры сложен.: 42 x 32 x 4 см/ 5 л.",
      "Вес 1,6 кг",
      "Ручка для переноски.",
      "Высота: 30 см.",
      "Широкая столешница для 2 человек: 42 x 64 см",
      "Простая система складывания. 2 выступа помогают хранить стол в сложенном виде.",
      "Макс.нагрузка на столешницу: 50 кг.",
      "Ножки из алюм., столешница – из меламина.",
    ],
  },
  {
    id: 10234034109,
    title: "Стол складной TONAR",
    img: "37464yxxvkwj.jpg",
    desc: "высота 60 см",
    category: "chair",
    price: 172,
    column: [
      "Размер: 50х70 см",
      "Высота: 60 см",
      "Материал ножки: сталь 16 мм",
      "Толщина: 1 мм",
      "Материал поверхности: ХДФ",
      "Максимальная нагрузка: 30 кг",
      "Вес: 2,6 кг",
    ],
  },

  // {
  //   id: 1000111,
  //   title: "БАЛАКЛАВА (ВЕЛОСИПЕДНАЯ) БЕЗШОВНАЯ",
  //   img: "buff2.jpg",
  //   desc: "VAN RESELL 500",
  //   category: "buff",
  //   price: 39,
  //   column: [
  //     "Очень тонкий эластичный материал идеально адаптируется к любой форме лица",
  //     "Материал, гарантирующий защиту лица от холода",
  //     "Волокна материала отводят пот и препятствуют конденсации внутри",
  //     "Состав: 2.0% Эластан, 23.0% Полиамид, 75.0% Полиэтилентерефталат",
  //     "Стирка в прохладной воде (30° C) Программа стирки: 'синтетика'",
  //   ],
  // },
  // {
  //   id: 1000112,
  //   title: "ДЕТСКАЯ БАЛАКЛАВА",
  //   img: "buffForChild.jpg",
  //   desc: "(ЛЫЖНАЯ МАСКА) WEDZE",
  //   category: "buff",
  //   price: 29,
  //   column: [
  //     "продукт из 100% переработанного полиэстера",
  //     "Защищает голову, шею и челюсть от холода, сохраняя тепло",
  //     "Помимо базовых преимуществ, эта снежная маска под шлемом гарантирует гигиену головы, особенно в заемных и арендованных шлемах",
  //     "Подходит для диаметра 48-56 см.",
  //   ],
  // },
  // {
  //   id: 1000113,
  //   title: "БАФФ ",
  //   img: "buff3.jpg",
  //   desc: "VAN RYSEI ROADR 100",
  //   category: "buff",
  //   price: 25,

  //   column: [
  //     "Защиты от неблагоприятных условий (ветра, пыли) при велопоездке. Подходит также и для других видов спорта, например, для бега",
  //     "Быстросохнущий материал, обеспечивающий отвод пота",
  //     "Эластичность ткани позволяет носить ее как повязку на голове и шее",
  //     "Легкая и комфортная, не затрудняет дыхание",
  //     "100% переработаный полиэстер",
  //     "Стирка в прохладной воде (30° C)",
  //   ],
  // },

  // {
  //   id: 1000120,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses.jpg",
  //   desc: "3-Й КАТЕГОРИИ QUECHUA MH940",
  //   category: "sunglasses",
  //   price: 91,
  //   oldPrice: 91,
  //   newPrice: 74,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 26 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  // {
  //   id: 100043121,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses2.jpg",
  //   desc: "3-Й КАТЕГОРИИ QUECHUA MH530",
  //   category: "sunglasses",
  //   price: 109,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 26 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  // {
  //   id: 1000122,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses3.jpg",
  //   desc: "3-Й КАТЕГОРИИ QUECHUA MH500",
  //   category: "sunglasses",
  //   price: 72,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Большие линзы для лучшего периферического обзора",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 29 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  // {
  //   id: 1000123,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses4.jpg",
  //   desc: "3-Й КАТЕГОРИИ KALENJI RUNSTYLE 2",
  //   category: "sunglasses",
  //   price: 88,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Большие линзы для лучшего периферического обзора",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 24 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  // {
  //   id: 1000124,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses5.jpg",
  //   desc: "3-Й КАТЕГОРИИ ROCKRIDER ST100",
  //   category: "sunglasses",
  //   price: 84,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Линзы категории 3 – фильтруют 100% УФ-лучей: идеальный выбор для солнечной погоды",
  //     "Резиновые наконечники дужек: препятствуют соскальзыванию очков",
  //     "Большие линзы для лучшего периферического обзора",
  //     "Линзы из высокопрочного поликарбонатного пластика",
  //     "Вес: 24 г.",
  //     "100.0% Поликарбонат",
  //   ],
  // },
  // {
  //   id: 110043124,
  //   title: "СОЛНЦЕЗАЩИТНЫЕ ОЧКИ",
  //   img: "sunglasses6.jpg",
  //   desc: "VAN RYSEL ROADR 500",
  //   category: "sunglasses",
  //   price: 90,
  //   column: [
  //     "Особая форма: защищает глаза от ветра, брызг, плохой погоды",
  //     "Материал противоударный поликарбонат",
  //     "Геометрия: сферическая",
  //     "Линзы 3 категории фильтруют свет на 18% - 43%",
  //     "100% УФ-фильтр",
  //     "Большой размер стекла обеспечивает широкий угол обзора",
  //     "Воздушно-проницаемая структура  предотвращает туман ",
  //     "Вес: 32 г.",
  //   ],
  // },
  {
    id: 100044141,
    title: "Тюбинг",
    img: "09491dd5-4982-448d-8ed7-d424eb2b2545.webp",
    desc: "QUECHUA AIR BASIC ",
    category: "air",
    price: 80,
    column: ["Сверхпрочный надувной тюбинг", ""],
  },
  {
    id: 1000141,
    title: "НАДУВНАЯ ПОДУШКА ДЛЯ КЕМПИНГА",
    img: "airbasic.jpg",
    desc: "QUECHUA AIR BASIC ",
    category: "air",
    price: 34,
    column: [
      "Размеры: 27×37×10 см",
      "Вес: 100 г",
      "Чтобы подушка быстрее сдулась, рекомендуем сжать клапан, чтобы знать блокировку и выпустить воздух",
    ],
  },
  {
    id: 1100141,
    title: "ПОДУШКА ДЛЯ КЕМПИНГА",
    img: "podushka-comfort-dlya-kempingu.jpg",
    desc: "QUECHUA COMFORT",
    category: "air",
    price: 49,
    column: [
      "Размер: 45 x 30 x 13 см ",
      "Вес: 400 грамм",
      "Объем: 4.5 литра",
      "Чехол из мягкого, текстурированного и удобного полиэстера. Легко стирается в стиральной машине при максимуме 30°C (на обычной программе).",
    ],
  },
  // {
  //   id: 1000142,
  //   title: "НАДУВНОЙ МАТРАС ДВУХМЕСТНЫЙ",
  //   img: "matQ.jpg",
  //   desc: "QUECHUA AIR BASIC",
  //   category: "air",
  //   price: 150,
  //   column: [
  //     "Вес: 4 кг",
  //     "Размеры: 190 x 140 x 16 см",
  //     "Габаритные размеры: 8,2 л",
  //     "Рекомендуемый максимальный вес: 220 кг.",
  //     "Ремонтный комплект",
  //     "Надувается насосом за 1 минуту 40 сек.",
  //     "Противовозвратный клапан",
  //     "Надувается с помощью всех наших насосов для кемпинга (ножных, ручных, электр.)",
  //     "Велюровый на ощупь",
  //     "Искусственный материал: 100.0% Поливинилхлорид",
  //     "Только ручная стирка",
  //     "Не использовать в воде",
  //   ],
  // },
  {
    id: 110430142,
    title: "Надувной матрас полутораместный",
    img: "3c5adc24b3_1080x.webp",
    desc: "BESTWAY 188 Х 99 Х 22 см",
    category: "air",
    price: 61,
    order: true,
    column: [
      "Размеры: 188 Х 99 Х 22 см",
      "Рекомендуемый максимальный вес: 150 кг.",
      "Вес: 2 кг",
    ],
  },
  {
    id: 1100142,
    title: "НАДУВНОЙ МАТРАС ОДНОМЕСТНЫЙ",
    img: "mattressIntex1.png",
    desc: "INTEX 191 Х 76 Х 25 см",
    category: "air",
    price: 55,
    order: true,
    column: [
      "Размеры: 191х76х25 см",
      "Рекомендуемый максимальный вес: 136 кг.",
      "Изготовлен по технологии Fiber-Tech внутренние перегородки сделаны из множества полиэфирных волокон, за счет чего он становится намного прочнее и легче флокированное покрытие матраса не дает соскальзывать",
      "Легко очищается клаган 2в1",
      "В комплект входит самоклеящаяся заплатка",
    ],
  },
  {
    id: 111327670142,
    title: "НАДУВНОЙ МАТРАС ДВУХМЕСТНЫЙ",
    img: "mattressIntex2.jpg",
    desc: "INTEX 203х152х25 см",
    category: "air",
    order: true,
    price: 80,
    column: [
      "Размеры: 203х152х25 см",
      "Рекомендуемый максимальный вес: 136 кг.",
      "Изготовлен по технологии Fiber-Tech внутренние перегородки сделаны из множества полиэфирных волокон, за счет чего он становится намного прочнее и легче флокированное покрытие матраса не дает соскальзывать",
      "Легко очищается клаган 2в1",
      "В комплект входит самоклеящаяся заплатка",
    ],
  },
  {
    id: 11132540142,
    title: "НАДУВНОЙ МАТРАС ДВУХМЕСТНЫЙ",
    img: "mattressIntex2.jpg",
    desc: "INTEX 203х183х25 см",
    category: "air",
    order: true,
    price: 89,
    column: [
      "Размеры: 203х183х25 см",
      "Рекомендуемый максимальный вес: 136 кг.",
      "Изготовлен по технологии Fiber-Tech внутренние перегородки сделаны из множества полиэфирных волокон, за счет чего он становится намного прочнее и легче флокированное покрытие матраса не дает соскальзывать",
      "Легко очищается клаган 2в1",
      "В комплект входит самоклеящаяся заплатка",
    ],
  },
  {
    id: 1100143,
    title: "НАСОС РУЧНОЙ",
    img: "ocr.jpeg",
    desc: "INTEX DOUBLE QUICK 1",
    category: "air",
    price: 18,
    column: [
      "Вес: 420 г",
      "В комплекте 3 насадки-переходника двухтактный режим работы ручного насоса, позволяет производить полезную работу как при поступательном движении насоса, так и при возвратном движении",
      "29 см",
      "Подходит для всех изделий ТМ Intex",
    ],
  },
  // {
  //   id: 1000143,
  //   title: "НОЖНОЙ НАСОС",
  //   img: "media.jpg",
  //   desc: "QUECHUA",
  //   category: "air",
  //   price: 52,
  //   column: [
  //     "2,5 л воздуха за одно нажатие",
  //     "Вес: 822 г",
  //     "Объем: 33 см",
  //     "Ширина: 24,5 см",
  //     "Высота: 9,1 см",
  //     "Рекомендуется хранить это изделие в сухом месте, беречь от слишком низких и слишком высоких температур",
  //   ],
  // },
  // {
  //   id: 1000125,
  //   title: "ГЕРМОПАКЕТ BTRACE",
  //   img: "packageBTRACE.jpg",
  //   desc: "ПЛОСКИЙ ПВХ 20X13см",
  //   category: "accessories",
  //   price: 17,
  //   column: ["Материал: ПВХ"],
  // },
  // {
  //   id: 1000126,
  //   title: "ОГНИВО BTRACE Ø 8 ММ",
  //   img: "flintBTRACE8.png",
  //   desc: "10х2,4х1,7см",
  //   category: "accessories",
  //   price: 39,
  //   column: ["Материал: Редкоземельный металл", "Диаметр (мм): 8"],
  // },
  // {
  //   id: 1000127,
  //   title: "КОЛЫШКИ BTRACE АЛЮМИНИЙ (КОМПЛЕКТ 10 ШТ.)",
  //   img: "aluminum_pegs_btrace.png",
  //   desc: "2шт-12₾",
  //   category: "accessories",
  //   price: 32,
  //   column: ["Материал: Алюминий"],
  // },
  {
    id: 1000138,
    title: "ГАМАК одноместный",
    img: "hammock.jpg",
    desc: "QUECHUA BASIC",
    category: "accessories",
    price: 94,
    column: [
      "Вес: 540 г",
      "Размеры 300 x 150 см",
      "Не стирать | Если необходимо: почистить влажной губкой",
      "Основная ткань/сумка для переноски 100% полиэтилентерефталат",
      "Рекомендуемый максимальный вес: 110 кг",
      "Крепления в комплекте",
    ],
  },
  {
    id: 1340142087,
    title: "Гамак Quechua Comfort",
    img: "basic.jpeg",
    desc: "до 220 кг (двухместный)",
    category: "accessories",
    price: 0,
    order: true,
    column: [
      "Двухместный гамак",
      "Состав: 100% полиэстер",
      "Состав стропы: 100% полипропилен",
      "Размеры: 280 x 175 cm",
      "Размеры в упаковке: 36 x 18 x 8 cm / 5,2 ",
      "Вес: 950 грамм",
      "Максимальная нагрузка 220 кг",
    ],
  },
  {
    id: 134032142087,
    title: "Веревка",
    img: "picture32.jpg",
    desc: "8 мм, длина 10 метров",
    category: "accessories",
    price: 62,
    order: true,
    column: [
      "Для буксира на воде до 550 кг",
      "Плавающая леска, которую легко обнаружить и которая не застрянет в винте",
    ],
  },
  {
    id: 13403213242087,
    title: "Веревка 30 мм",
    img: "22.45.18.jpg",
    desc: "длина 10 метров",
    category: "accessories",
    price: 21,
    column: [],
  },
  {
    id: 1340321332242087,
    title: "Веревка 30 мм",
    img: "22.45.18.jpg",
    desc: "длина 15 метров",
    category: "accessories",
    price: 31,
    column: [],
  },

  {
    id: 1000131,
    title: "БИНОКЛЬ SOLOGNAC",
    img: "binoculars.jpg",
    desc: "8-кратное приближение",
    category: "binoculars",
    price: 142,

    column: ["Вес: 202г", "Верхнее покрытие : 100% Силикон"],
  },
  {
    id: 1000132,
    title: "БИНОКЛЬ QUECHUA MH B560",
    img: "quechuabinoculars.jpg",
    desc: "12-кратное приближение",
    category: "binoculars",
    price: 197,
    shortly:
      "Компактный и легкий бинокль с простой регулировкой. Идеален для обзора панорамы во время походов. Большие объективы (32 мм) и специальное покрытие оптики для обеспечения четкости изображения. Подходит для тех, кто носит очки, благодаря выдвижному глазку, который можно откинуть назад.",
    column: [
      "Вес: 333г",
      "Оправа : 100% Поликарбонат",
      "В комплекте шнурок на шею и футляр из полиэстера для защиты от ударов и пыли, а также для легкости переноски.",
    ],
  },
  {
    id: 198000132,
    title: "Монокулярный бинокль X8",
    img: "kids-fixed-focus-hiking-monocular-m100-x8-magnification-blue-quechua-8872579.avif",
    desc: "синий — MH100",
    category: "binoculars",
    price: 69,
    shortly: "",
    column: [
      "Объект кажется в 8 раз ближе. Диаметр линзы = 25 мм, яркое изображение",
      "Регулировка не требуется. Наблюдайте с расстояния не менее 20 м для обеспечения четкого обзора.",
      "Всего 78 г.",
      "Силиконовый компонент обеспечивает хорошую ударопрочность и превосходную долговечность.",
      "Призмы и линзы из минерального стекла качества K9. Линзы окуляра из минерального стекла качества K9.",
    ],
  },
  {
    id: 19800900132,
    title: "Эластичный ремешок",
    img: "4886c6b7bbc4.jpg",
    desc: "для бинокля",
    category: "binoculars",
    price: 52,
    shortly: "",
    column: [
      "Эта эластичная сбруя — удобная альтернатива ношению бинокля на шее.",
      "Состав материала/ингредиенты: Тесьма 50% синтетический каучук, 50% полиэтилентерефталат (ПЭТ)",
      "",
    ],
  },
  {
    id: 198040900132,
    title: "Портативный бинокль",
    img: "f48b5fe5.png",
    desc: "с объективом 21 мм и призмой 8х21",
    category: "binoculars",
    price: 59,
    shortly: "",
    column: [
      "Увеличение бинокля 8x",
      "Диаметр объектива, мм 21",
      "Реальный угол зрения, 8.2",
    ],
  },
  {
    id: 19804094300132,
    title: "Портативный бинокль",
    img: "269979ik.png",
    desc: "с объективом 30 мм и призмой 10х22",
    category: "binoculars",
    price: 65,
    shortly: "",
    column: [
      "Увеличение бинокля 8x",
      "Диаметр объектива, мм 21",
      "Реальный угол зрения, 8.2",
    ],
  },
  // {
  //   id: 1000133,
  //   title: "БИНОКЛЬ QUECHUA MH B540",
  //   img: "binokl3.jpg",
  //   desc: "10-кратное приближение",
  //   category: "accessories",
  //   price: 165,
  //   shortly:
  //     "Компактный и легкий бинокль с простой регулировкой. Идеален для обзора панорамы во время походов. Большие объективы (32 мм) и специальное покрытие оптики для обеспечения четкости изображения. Подходит для тех, кто носит очки, благодаря выдвижному глазку, который можно откинуть назад.",
  //   column: [
  //     "Вес: 332г",
  //     "Оправа : 100% Поликарбонат",
  //     "В комплекте шнурок на шею и футляр из полиэстера для защиты от ударов и пыли, а также для легкости переноски.",
  //   ],
  // },

  {
    id: 1000139,
    title: "Браслет из паракорда+свисток",
    img: "paracordbig.jpg",
    desc: "Цвета в ассартименте",
    category: "accessories",
    price: 14,
    column: [
      "Браслет при необходимости можно распустить в крепкий шнур длина веревки около 3 метров",
      "Длина браслета 24,5 см",
      "Вес: 30 гр",
      "Браслет имеет встроенный свисток и светоотражающий элемент",
      "Подходит для повседневного использования",
    ],
  },
  {
    id: 1000140,
    title: "Браслет из паракорда без свистка",
    img: "paracordsmall.jpg",
    desc: "Цвета в ассартименте",
    category: "accessories",
    price: 9,
    column: [
      "браслет при необходимости можно распустить в крепкий шнур длина веревки около 3 метров,",
      "22*1,8*0,7 см 2,5 метра",
      "Нагрузка до 80 кг	",
      "Вес: 18 гр",
      "Подходит для повседневного использования",
    ],
  },
  {
    id: 100032140,
    title: "Браслет из паракорда (свисток, огниво, компас)",
    img: "567890.png",
    desc: "Цвета в ассартименте",
    category: "accessories",
    price: 16,
    column: [
      "Ширина браслета – 23 мм. Окружность (в застегнутом виде с учетом фастексов) – 22,5 см (можно самостоятельно перевязать на другой размер).",
      "браслет при необходимости можно распустить в крепкий шнур длина веревки около 3 метров,",
      "3 метра",
      "Нагрузка до 80 кг	",
      "Вес: 18 гр",
      "Подходит для повседневного использования",
    ],
  },

  {
    id: 100043135,
    title: "Аварийное Термоодеяло",
    img: "21.49.26.jpg",
    desc: "130 X 210 см",
    category: "accessories",
    price: 9,

    column: [
      "Спальный термосберегающий мешок 210х140 см разработан для выживания, может использоваться в повседневной жизни при неблагоприятной погоде.",
      "Данное покрывало можно использовать в ливень и жару - термоматериал прекрасно отражает энергию солнца и совершенно не пропускает влагу.",
      "",
    ],
  },
  {
    id: 10320043135,
    title: "Компрессионный чехол",
    img: "orclaz-forclaz-8572586.avif",
    desc: " 2 шт по 10 литров",
    category: "accessories",
    price: 31,
    column: [
      "Для одежды/спальника",
      "В сложенном виде: 32 см (ширина) x 37 см (длина) Наполненный чехол: 20 см (ширина) x 20 см (длина) x 30 см (высота)",
      "Ручная стирка. Максимальная температура 40°C",
      "Основная ткань: 100,0% полиэстер",
    ],
  },
  {
    id: 1000135,
    title: "СПАСАТЕЛЬНОЕ ОДЕЯЛО",
    img: "rescueBlanket.jpg",
    desc: "SIMOND",
    category: "accessories",
    price: 15,
    order: true,
    column: [
      "Eго гибкий материал предотвращает зацепы и разрывы защищает от холода",
      "Отражая тепло тела может быть использовано повторно",
      "Основная ткань 100% полиэтилентерефталат",
      "Размеры - 90 * 210 см",
      "Вес: 115г",
    ],
  },
  {
    id: 1000136,
    title: "ОДЕЯЛО ПЕРВОЙ ПОМОЩИ",
    img: "safeBlanket.jpg",
    desc: "FORCIAZ 100",
    category: "accessories",
    price: 24,
    column: [
      "Односпальное термоодеяло для одноразового использования, чтобы защитить Вас от холода или тепла, когда это необходимо.",
      "Предназначено для использования только один раз, занимая минимум места и веса в рюкзаке",
      "Золотистая сторона (сверху) удерживает тепло и защищает от холода: предотвращает риск переохлаждения, сохраняет температуру тела, защищает от ветра и влажности",
      "Серебристая сторона (сверху) защищает от перегрева: поддерживает прохладу (машины, палатки и т.д.)",
      "Размеры: 160 x 210 см.",
      "Толщина: 0,012 мм",
      "Не накрывать голову – есть риск удушения. Не использовать в бурю, вблизи раскаленных веществ или при пользовании электрическими медицинскими аппаратами (например, аппаратом DSA). Не разрезать и не прокалывать. Хранить в сухом месте.",
    ],
  },
  {
    id: 1032032043135,
    title: "Компас",
    img: "110000428237905.jpg",
    desc: "на мини-карабине",
    category: "accessories",
    price: 32,
    column: [
      "4 в 1: Компас, свисток, термометр, увеличительное стекло.",
      "Зафиксируйте его где угодно с помощью карабина!",
      "Точный и быстрый универсальный компас-указатель.",
      "Чтобы компас правильно указывал на север, используйте его на ровной местности. Например, поместите компас на ладонь. Если вы хотите использовать его с картой, поместите карту на плоскую поверхность и поместите компас сверху",
    ],
  },
  {
    id: 10320326543043135,
    title: "Карабин-компос",
    img: "compass-orange.png",
    desc: "",
    category: "accessories",
    price: 28,
    column: [
      " Практичный и легкий, вы можете прикрепить его где угодно!",
      "Легкий алюминиевый компас: весит всего 16 г",
    ],
  },
  {
    id: 132032032043135,
    title: "Дезодорирующий спрей",
    img: "spray.png",
    desc: "для обуви",
    category: "accessories",
    price: 24,
    column: [
      "Нейтрализуйте нежелательные запахи и носите походную обувь уверенно.",
      "",
    ],
  },
  {
    id: 9899009989,
    title: "Грелки для рук",
    img: "hand-warmers-x.avif",
    desc: "10 шт",
    category: "accessories",
    price: 35,
    column: [
      "Эти карманные грелки для рук активируются при контакте с воздухом",
      "В идеале используйте их перед тем, как замерзнут руки, или для согревания при необходимости",
      "Подуйте на них, чтобы ускорить начало",
      "Эти грелки для рук обеспечат вам регулярное мягкое тепло в течение 5–6 часов, обеспечивая больший комфорт во время зимних мероприятий",
      "Откройте пакет/встряхните/подождите 10 мин. В идеале активируйте при комнатной температуре",
      "Распространение мягкого тепла в течение не менее 5-6 часов. Одноразовый продукт.",
      "Состав продукта: Формула: 15,0% активированного угля, 3,0% солей, 9,0% вермикулита, 18,0% воды, 55,0% железа.",
    ],
  },
  {
    id: 989903209989,
    title: "Тянущийся трос для багажа",
    img: "d3888c9870c9d606.webp",
    desc: "1 шт",
    category: "accessories",
    price: 3,
    column: ["Размер: 25 см"],
  },
  {
    id: 98299009989,
    title: "Полотенце из микрофибры",
    img: "microfibre.avif",
    desc: "S 39*55см",
    category: "accessories",

    price: 18,
    column: [
      "Двустороннее банное полотенце из микрофибры",
      "Компактное и легкое полотенце из микрофибры пригодится в любой ситуации",
      "Полотенце быстро сохнет, что позволяет быстро вернуться к использованию",
      "Чтобы высушить тело, не растирайте кожу, а промакивайте.",
      "Машинная стирка. Ткань не рвется, устойчива к частым стиркам.",
    ],
  },
  {
    id: 982990099891,
    title: "Полотенце из микрофибры",
    img: "microfibre.avif",
    desc: "M 60*80см",
    category: "accessories",
    price: 28,
    column: [
      "Двустороннее банное полотенце из микрофибры",
      "Компактное и легкое полотенце из микрофибры пригодится в любой ситуации",
      "Полотенце быстро сохнет, что позволяет быстро вернуться к использованию",
      "Чтобы высушить тело, не растирайте кожу, а промакивайте.",
      "Машинная стирка. Ткань не рвется, устойчива к частым стиркам.",
    ],
  },
  {
    id: 9829900998912,
    title: "Полотенце из микрофибры",
    img: "microfibre.avif",
    desc: "L 80*130см",
    category: "accessories",
    price: 38,
    column: [
      "Двустороннее банное полотенце из микрофибры",
      "Компактное и легкое полотенце из микрофибры пригодится в любой ситуации",
      "Полотенце быстро сохнет, что позволяет быстро вернуться к использованию",
      "Чтобы высушить тело, не растирайте кожу, а промакивайте.",
      "Машинная стирка. Ткань не рвется, устойчива к частым стиркам.",
    ],
  },
  {
    id: 93829900998912,
    title: "Полотенце из микрофибры",
    img: "259cd_1300x1300.webp",
    desc: "Cool Gray 82 Х 32 СМ",
    category: "accessories",
    price: 19,
    column: ["Основной материал: Полиэстер (полиэфирное волокно)"],
  },
  {
    id: 9382990032998912,
    title: "Медаль Казбек",
    img: "23.11.40.jpg",
    desc: "MOUNTAIN MEDALS",
    category: "accessories",
    price: 65,
    column: [],
  },
  {
    id: 938299043912,
    title: "Лопата Fiskars Solid",
    img: "22.33.52.jpg",
    desc: "",
    category: "accessories",
    price: 80,
    column: [
      "Основной материал: Полиэстер (полиэфирное волокно)",
      "D-образная рукоятка подходит для всех размеров рук, как в перчатках, так и без них, обеспечивает удобный и надежный захват, помогает сохранить естественное положение рук при работе",
      "Размер лезвия см: 28x18",
      "Вес нетто: 1.42 кг",
      " 78 см, 18 см ширина",
    ],
  },
  {
    id: 9038299043912,
    title: "Верхние перчатки",
    img: "hiking-waterproof-over-gloves-mt-500.png",
    desc: "для треккинга",
    category: "gloves",
    price: 77,
    column: [
      "Ремешок для регулировки на запястье. Съемный ремешок, чтобы можно было снять перчатки, не теряя их.",
      "Вес: 52 г на пару размера M/L",
      "100% ветрозащитная мембрана, не пропускающая ветер",
      "Водонепроницаемая мембрана с классом водонепроницаемости H 20 (по Шмерберу) 10 000 мм с проклеенными швами",
      "Основная ткань: 100,0% полиэстер",
    ],
  },
  {
    id: 903829904391214,
    title: "Портативный фильтр",
    img: "6ac0b67f85da0fS.webp",
    desc: "для воды",
    category: "accessories",
    price: 42,
    column: [
      " Обеспечить удобную, безопасную и чистую питьевую воду на открытом воздухе.",
      "Способность супер очистки воды: при нормальных обстоятельствах можно фильтровать не менее 1000 литров воды",
      "Материал фильтра: ультрафильтрационная мембрана, активированный уголь, антибактериальные частицы нано-серебра",
    ],
  },
  {
    id: 9038292187391214,
    title: "Тактический нейлоновый зажим",
    img: "123344.jpeg",
    desc: "для бутылки с водой",
    category: "accessories",
    price: 6,
    column: [],
  },
  {
    id: 90382921391214,
    title: "Зонт пляжный",
    img: "6076743152.jpg",
    desc: "красный, синий",
    category: "accessories",
    price: 35,
    column: [],
  },
  {
    id: 9038292138791214,
    title: "Портативная солнечная зарядная панель",
    img: "IMG_1503.PNG",
    desc: "2W-USB",
    category: "accessories",
    price: 35,
    column: ["Водонепроницаемая"],
  },
  {
    id: 7771214,
    title: "Складная солнечная панель",
    img: "b50b5ab1-1d19-4903-a247-d.png",
    desc: "Выход: USB 5 В, Напряжение ≤36 В ",
    category: "accessories",
    price: 97,
    column: [
      " Изготовлен из высококачественных материалов, прочный и чувствительный.",
      "Его можно использовать для зарядки мобильных телефонов, мобильных аккумуляторов и т. д.",
      "Легкий, складной, легкий и удобный для переноски.",
      "Легкий и ультратонкий, простой в хранении и установке.",
      "Он подходит для активного отдыха, чрезвычайных ситуаций и работ на открытом воздухе и может продолжать подавать электроэнергию даже в пасмурные дни.",
      "Материал: Монокристалл+ПЭТ+ткань.",
      "Температура зарядки: -20-70 ℃",
      "Вес нетто продукта: 400 г",
    ],
  },
  {
    id: 9999090,
    title: "Компактная аптечка: мини-сумка",
    img: "c44e346-goods.webp",
    desc: "20 X 13 СМ",
    category: "accessories",
    price: 13,
    column: [],
  },
  {
    id: 99990908723,
    title: "сверхпрочные брелки-карабины",
    img: "87ade41f917.webp",
    desc: "5 шт.",
    category: "accessories",
    price: 7,
    column: ["47 X 24,5 mm", "Диаметр 3,8 мм", "Алюминиевый сплав"],
  },

  {
    id: 99990902332,
    title: "алюминиевые карабины",
    img: "28c1b-goods.webp",
    desc: "10 шт.",
    category: "accessories",
    price: 14,
    column: ["47 Х 24 mm", "3,8 мм"],
  },
  {
    id: 999909023342,
    title: "Свисток 3в1",
    img: "b6-18a41e7916f6.webp",
    desc: "Компас, Термометр, Свисток",
    category: "accessories",
    price: 14,
    column: ["6 см до 150 дБ"],
  },

  // {
  //   id: 1000145,
  //   title: "ЛЫЖНЫЕ ПЕРЧАТКИ (ВЕРХОНКИ)",
  //   img: "glove.jpg",
  //   desc: " WEDZE 500",
  //   category: "accessories",
  //   price: 65,
  //   oldPrice: 65,
  //   newPrice: 45,
  //   column: [
  //     "Водонепроницаемая мембрана и внешний материал с водоотталкивающей пропиткой",
  //     "Синтепон 200 г/м² (тыльн. ст.), 80 г/м² (ладонь), подклад., искус. мех. Манжеты.",
  //     "Эластичный шнурок на запястье",
  //     "Широкая манжета, которую можно расположить над или под рукавом куртки",
  //     "Ладонь из полиуретана, укрепленный указательный палец",
  //     "Стирка в прохладной воде (30° C)",
  //     "14.1% Хлопок, 2.5% Вискоза, 83.4% Полиэтилентерефталат",
  //   ],
  // },

  {
    id: 1000151,
    title: "BORSCH (Украинский борщ) ",
    img: "ukrainian-borscht-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,

    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 11100152,
    title: "BORSCH VEGAN (Украинский борщ, вегетарианский) ",
    img: "ukrainian-borscht-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 16,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000152,
    title: "TOMATO SOUP (Томатный суп) ",
    img: "tomato-soup-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000153,
    title: "BUCKWHEAT (Гречка) ",
    img: "buckwheat-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 21,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000154,
    title: "BUCKWHEAT VEGAN (Гречка вегетарианская) ",
    img: "buckwheat-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000155,
    title: "RIZOTTO (Ризотто) ",
    img: "rizotto-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 21,

    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000156,
    title: "BUCKWHEAT VEGAN (Ризотто вегетарианская) ",
    img: "rizotto-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000157,
    title: "CREAMY POTATO (Сливочный картофель) ",
    img: "creamy-potato-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 21,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000158,
    title: "CREAMY POTATO VEGAN (Сливочный картофель, вегетарианский) ",
    img: "creamy-potato-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000159,
    title: "PASTA BOLOGNESE (Паста болоньезе) ",
    img: "pasta-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 21,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000160,
    title: "PASTA BOLOGNESE VEGAN (Паста болоньезе, вегетарианская) ",
    img: "pasta-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 10009161,
    title: "LOBIO (Лобио) ",
    img: "lobio-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 21,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000161,
    title: "LOBIO (Лобио, вегетарианский) ",
    img: "lobio-front.png",
    desc: "lobio-front.png",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000162,
    title: "BULGUR WITH CORN (Булгур с кукурузой) ",
    img: "bulgur-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 21,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000163,
    title: "BULGUR WITH CORN VEGAN (Булгур с кукурузой, вегетарианский) ",
    img: "bulgur-front.png",
    desc: "Max pack +50%",
    category: "sublimates",
    price: 18,
    column: [
      "Высококаллорийные блюда быстрого приготовления",
      "-только из натуральных ингредиентов: без ГМО, химических добавок и консервантов",
      "- сохранены витамины и микроэлементы",
      "- лучшее усвоение и долгое ощущение сытости ",
      "- легкий вес и компактность упаковки",
      "- срок годности 6 месяцев",
      `Инструкция по приготовлению:
      -открыть пакет, добавить кипяток (см. инструкцию на упаковке), перемешать
      -закрыть пакет и подождать 10 минут
      -перемешать содежимое и оставить еще на 5 минут
      -готово, приятного аппетита!`,
      "Если на улице холодно,  поместите пакет в теплое место: в карман, под куртку, в спальный мешок или рюкзак - так вода не остынет быстрее чем приготовится еда",
      "В продукте уже присутствуют специи, однако вы можете добавить их по вкусу",
    ],
  },
  {
    id: 1000164,
    title: "JERKY BEET (Вяленая говядина)",
    img: "jerky-beef-front.png",
    desc: "",
    category: "sublimates",
    price: 18,
    column: [
      "Ингредиенты: мясо говядины, маринад (соевый соус, винный уксус, чеснок, сахар, перец чили, имбирь, соль)",
      "Пищевая ценность: 205,8 калорий.",
      "Вес: 60 грамм",
    ],
  },
  {
    id: 100016498,
    title: "JERKY chicken (Вяленая курица)",
    img: "jerky-chicken-front.png",
    desc: "",
    category: "sublimates",
    price: 15,
    column: [
      "Ингредиенты: куриное мясо, маринад (соевый соус, винный уксус, мед, французская горчица, сахар, перец чили, имбирь, оливковое масло, соль)",
      "Пищевая ценность:  217,8 калорий.",
      "Вес: 60 грамм",
    ],
  },
  {
    id: 1000164980,
    title: "Granola Chocolate with bananes (Гранола шоколадная)",
    img: "chocolate-granola-front.png",
    desc: "",
    category: "sublimates",
    price: 12,
    column: [
      "Ингредиенты: овсянка печеная, бананы, фундук, миндаль, изюм, какао, масло сливочное, мед, молоко сухое (20 грамм).",
    ],
  },
  {
    id: 10600164909009,
    title: "Украинский борщ веган | Borscht",
    img: "Izy__Meal-Borscht-Web-Thumbnail.jpg",
    desc: "",
    category: "sublimates",
    price: 16,
    column: [
      "Украинский сублимированный борщ - это простая версия классического супа, приготовленного из свеклы, моркови, картофеля и капусты, без добавления укропа. Он не содержит искусственных добавок, что обеспечивает натуральные и питательные вкусовые качества. Этот суп - отличный источник витаминов и минералов, что делает его полезным блюдом. Для приготовления требуется всего лишь добавить воды, что позволяет быстро и просто насладиться теплым и приятным блюдом на свежем воздухе.",
    ],
  },
  {
    id: 1000164983409,
    title: "Тыквенный суп веганский | Pumpkin soup",
    img: "23.56.48.jpg",
    desc: "",
    category: "sublimates",
    price: 16,
    column: [],
  },
  {
    id: 10001649836409,
    title: "Харчо | Chiken soup kharcho",
    img: "23.41.04.jpg",
    desc: "",
    category: "sublimates",
    price: 24,
    column: [],
  },
  {
    id: 1000164982109,
    title: "Лобио веган | Mashed Beans with herbs",
    img: "23.51.25.jpg",
    desc: "",
    category: "sublimates",
    price: 17,
    column: [
      "Лобио - это простая смесь фасоли и овощного ассорти, приправленная различными травами для придания вкуса. Это сублимированное блюдо, не содержащее искусственных ингредиентов, которое является полезным и питательным блюдом с высоким содержанием витаминов и минералов. Чтобы приготовить, просто добавьте горячей воды, перемешайте и подождите, пока жидкость восстановится, как при заваривании чая. Он разработан с учетом простоты приготовления, что делает его подходящим для активного отдыха, где возможности приготовления ограничены.",
    ],
  },
  {
    id: 10001649821098,
    title: "Пюре сулугуни вегетарианское | Puree with smoked sulguni",
    img: "Izy__Meal-Puree-Web-Thumbnail.jpg",
    desc: "",
    category: "sublimates",
    price: 17,
    column: [
      "Это сублимированное блюдо сочетает в себе мягкость и неповторимый аромат копченого сыра сулугуни, а для придания вкуса оно приправлено укропом. Оно готовится без использования искусственных ингредиентов, чтобы сделать блюдо одновременно вкусным и питательным. Приготовление очень простое: добавьте горячую воду и подождите несколько минут, пока блюдо не впитает влагу. Этот продукт идеально подходит для тех, кто ищет быстрое, полезное блюдо с неповторимым вкусом, подходящее для трапезы на свежем воздухе.",
    ],
  },
  {
    id: 100016498210981,
    title: "Cушенные бананы | Bananas",
    img: "Izy-Packaging__Banana-Small.png",
    desc: "",
    category: "sublimates",
    price: 7,
    column: [],
  },
  {
    id: 100016498210982,
    title: "Cушенные яблоки | Apples",
    img: "Izy-Packaging__Apple-Small.png",
    desc: "",
    category: "sublimates",
    price: 7,
    column: [],
  },
  {
    id: 100016498210988,
    title: "Сыр маленькая упаковка | Sulugumi small",
    img: "a281_Izy__Smoked-Sulguni-25g-Mockup.png",
    desc: "",
    category: "sublimates",
    price: 5,
    column: [],
  },
  {
    id: 1000169498210988,
    title: "Кофе Peru Lazy Snark",
    img: "coffee-peru-front.png",
    desc: "",
    category: "coffee",
    price: 6,
    column: ["Грейпфрут, слива, апельсин"],
  },
  {
    id: 10001694982109880,
    title: "Кофе Burundi Lazy Snark",
    img: "coffee-burundi-front.png",
    desc: "",
    category: "coffee",
    price: 6,
    column: ["Клубника, Черника, Карамель"],
  },
  {
    id: 100016949898210988,
    title: "Кофе Rwanda Lazy Snark",
    img: "coffee-rwanda-front.png",
    desc: "",
    category: "coffee",
    price: 6,
    column: ["Апельсин, папайя, киви, цветы"],
  },
  {
    id: 9100016949898210988,
    title: "Tasty Coffee Кения Маунт",
    img: "drip-coffee-kenya-maunt-new-768.png",
    desc: "1 дрип",
    category: "coffee",
    price: 4,
    column: ["Сочный кофе с нотами красной смородины, какао и чёрного чая"],
  },
  {
    id: 9100016949887980210988,
    title: "Tasty Coffee Бэрри",
    img: "169-Barry_10-768.png",
    desc: "1 дрип",
    category: "coffee",
    price: 4,
    column: [
      "Плотный кофе с нотами ореховой пасты, темных ягод и печенья",
      "идеален для молочных напитков",
    ],
  },
  {
    id: 910001694989802109889,
    title: "Tasty Coffee Эфиопия Сидамо",
    img: "drip-coffee-ethiopia-sidamo-new-768.png",
    desc: "1 дрип",
    category: "coffee",
    price: 4,
    column: ["Лёгкий кофе с нотами цветов, желтых фруктов и спелого лимона"],
  },
  {
    id: 100001679,
    title: "Сап-борд Magnum pro light",
    img: "729607464.png",
    desc: "Runeed Everest",
    category: "supboard",
    price: 779,
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      "Высота доски: 350см",
      "Толщина: 15 см",
      "Макс. нагрузка: 200 кг",
      "Сап борд двухслойный Magnum PRO light поставляется с полной комплектацией: весло (из прочного пластика, разборное, нетонущее); сумка для хранения и транспортировки борда; страховочный лиш; плавник для улучшенного управления сапбордом; дорожный ремонтный комплект.",
    ],
  },
  {
    id: 1000167,
    title: "НАДУВНАЯ SUP-ДОСКА",
    img: "sup1.png",
    desc: "Runeed Everest",
    category: "supboard",
    price: 779,
    order: true,
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Водонепроницаемый чехол для телефона - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:81см",
      "Толщина: 15см",
      "Длина: 320 см",
      "Вес: 8.3 кг",
      "Макс. нагрузка: 145 кг",
      "RUNEED sup paddle board изготовлена из мягкой противоскользящей палубы EVA, что значительно улучшает стабильность и безопасность во время гребли. ",
      "Идеально подходит для всех уровней мастерства, чтобы избежать несчастных случаев и травм! ",
      "Надувная доска имеет матовое утолщение.",
    ],
  },
  {
    id: 1000168,
    title: "НАДУВНАЯ SUP-ДОСКА (ловец снов)",
    img: "sup2.jpg",
    desc: "Runeed Everest",
    category: "supboard",
    price: 779,
    order: true,
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:84см",
      "Толщина: 15см",
      "Длина: 350 см",
      "Вес: 9.5 кг",
      "Макс. нагрузка: 145 кг",
    ],
  },
  {
    id: 1000169,
    title: "НАДУВНАЯ SUP-ДОСКА (синий)",
    img: "sup3.jpg",
    desc: "Runeed Everest",
    category: "supboard",
    price: 779,
    order: true,
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:84см",
      "Толщина: 15см",
      "Длина: 350 см",
      "Вес: 9.5 кг",
      "Макс. нагрузка: 145 кг",
    ],
  },
  {
    id: 1000170,
    title: "НАДУВНАЯ SUP-ДОСКА (красный)",
    img: "sup4.jpg",
    desc: "Runeed Everest",
    category: "supboard",
    price: 779,
    order: true,
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:84см",
      "Толщина: 15см",
      "Длина: 350 см",
      "Вес: 9.5 кг",
      "Макс. нагрузка: 145 кг",
    ],
  },
  {
    id: 1000171,
    title: "НАДУВНАЯ SUP-ДОСКА (шаман)",
    img: "sup5.jpg",
    desc: "Runeed Everest",
    category: "supboard",
    price: 779,
    order: true,
    shortly: "КОМПЛЕКТАЦИЯ: ",
    column: [
      " -Водонепроницаемый мешок 5 литров - 1 шт",
      "-1 съемный центральный киль - 1 шт.",
      "-Съемный боковой киль - 2 шт.",
      "Ручной насос высокого давления - 1 шт.",
      "Водонепроницаемый мешок большой емкости из материала 600D - 1 шт",
      "Алюминиевое весло - 1 шт.",
      "1 ключ для ремонта (ключ/материал из ПВХ) - 1 шт.",
      "Водонепроницаемый мешок - 1 шт.",
      "Безопасный ремень - 1 шт.",
      "Ширина:84см",
      "Толщина: 15см",
      "Длина: 350 см",
      "Вес: 9.5 кг",
      "Макс. нагрузка: 145 кг",
    ],
  },
  {
    id: 1000172,
    title: "Мужские кожаные треккинговые ботинки ",
    img: "trek100-men-s-mountain-trekking-boots.png",
    desc: "водонепроницаемые Shark — MT100",
    category: "shoes",
    price: 355,
    order: true,
    column: [
      "Гибкая подошва, которая поддерживает движение стопы и идеально подходит для легкой местности.",
      "Подошва из полиуретана для прочной и эффективной амортизации.",
      "Вес 506 г на ботинок 42 размера",
      "2 верхних крючка и 1 блокирующий крючок для хорошей поддержки лодыжки.",
      "Одобренная подошва для оптимального сцепления и сцепления на твердой и мокрой земле.",
      "Водонепроницаемая, дышащая мембрана для универсального использования в любых условиях",
    ],
  },
  {
    id: 1000173,
    title: "Мужские водонепроницаемые походные ботинки",
    img: "shoesred.png",
    desc: "SH500 Mountain",
    category: "shoes",
    price: 360,
    order: true,
    column: [
      "Комфортная температура в статичном состоянии: -6°C в движении: -14 °C",
      "Водонепроницаемая и дышащая мембрана гарантирует сухость ног",
      "Сцепление на снегу благодаря рифлению глубиной 5 мм",
      "Сцепление по технологии SNOWCONTACT: особый материал и рельеф подошвы",
      "Голенище средней высоты с 2 парами крючков для хорошей фиксации",
    ],
  },
  {
    id: 1000174,
    title: "Мужские водонепроницаемые походные ботинки",
    img: "shoesgray.png",
    desc: "черные — MH100",
    category: "shoes",
    price: 334,
    order: true,
    column: [
      "Резиновая подошва с хорошим сцеплением и рифлением глубиной 4 мм",
      "Резиновая накладка для защиты носка от попадания камней.",
      "Обувь 440 г для размера 43",
      "2 верхних крючка и 1 блокирующий крючок для хорошей поддержки лодыжки.",
      "Одобренная подошва для оптимального сцепления и сцепления на твердой и мокрой земле.",
      "Водонепрониц. 'дышащая' мембрана: Ваши ноги будут сухими 3 ч в любую погоду",
    ],
  },
  {
    id: 1000175,
    title: "Женские водонепроницаемые походные ботинки",
    img: "1568309.jpg",
    desc: "бирюзовые — MH100",
    category: "shoes",
    order: true,
    price: 324,
    column: [
      "Резиновая подошва с хорошим сцеплением и рифлением глубиной 4 мм",
      "Резиновая накладка для защиты носка от попадания камней",
      "Высокий верх. Блокир. крючки для повтор. шнуровки между стопой и лодыжкой",
      "Промежуточная подошва из ЭВА для амортизации по всей длине стопы",
      "Обувь 440 г для размера 43",
    ],
  },
  {
    id: 1000176,
    title: "Мужские водонепроницаемая кроссовки",
    img: "1568439.jpg",
    desc: "темно-серые - MH100",
    category: "shoes",
    price: 275,
    order: true,
    column: [
      "Резиновая подошва с хорошим сцеплением и рифлением глубиной 4 мм",
      "Резиновая накладка для защиты носка от попадания камней",
      "Высокий верх. Блокир. крючки для повтор. шнуровки между стопой и лодыжкой",
      "Промежуточная подошва из ЭВА для амортизации по всей длине стопы",
      "405 г для размера 42",
    ],
  },
  {
    id: 100017698,
    title: "Женские водонепроницаемые кроссовки",
    img: "2579665.png",
    desc: "синяя-серые - MH100 (38 размер)",
    category: "shoes",
    price: 270,
    column: [
      "Нескользящая резиновая подошва и кошки, обеспечивающие сопротивление скольжению толщиной 4 мм.",
      "Водонепроницаемая и дышащая мембрана: сохраняет сухость в течение 3 часов при любых погодных условиях.",
      "337 г в размере 39.",
      "Прочная резиновая подкладка в области носка.",
      "Обеспечивает амортизацию благодаря межподошве из ЭВА, которая простирается по всей стопе.",
    ],
  },
  {
    id: 1000177,
    title: "Мужская уличная обувь cерая - MH100",
    img: "110000561571010.jpg",
    desc: "Трейлранинг и хайкинг",
    category: "shoes",
    price: 232,
    order: true,
    column: [
      "Мужские походные ботинки MH100 от Quechua – отличный выбор для любителей пеших прогулок и походов",
      "Благодаря амортизации, защите от ударов и легкости, они обеспечивают комфорт и удобство при передвижении",
      "Промежуточная подошва из материала ЭВА и специальный протектор на подошве гарантируют комфорт и устойчивость",
      "Резиновая накладка на носке ботинка защищает от ударов, а рельефная резиновая подошва обеспечивает отличное сцепление с поверхностью",
    ],
  },
  {
    id: 1000178,
    title: "Мужские водонепроницаемые кроссовки",
    img: "2583116.png",
    desc: "синие - городские NH150 WP (43,44)",
    category: "shoes",
    price: 223,

    column: [
      "Резиновая подошва с хорошим сцеплением и рифлением глубиной 4 мм",
      "Водонепроницаемая 'дышащая' мембрана. Испытано в течение 2 ч. под дождем",
      "Резиновая накладка для защиты носка от попадания камней",
      "Высокий верх. Блокир. крючки для повтор. шнуровки между стопой и лодыжкой",
      "Промежуточная подошва из ЭВА для амортизации по всей длине стопы",
      '"Дышащая" стелька толщиной 5 мм. Раструб в области лодыжки: удобный пеноматериал',
      "Каждый ботинок весит всего 435 г для размера 43",
    ],
  },
  {
    id: 1000179,
    title: "Мужские походные ботинки",
    img: "91159aeff7c751.jpeg",
    desc: "черные - NH100 (38,45 размер)",
    category: "shoes",
    price: 170,
    column: [
      "Материал верха: 42.9% полиуретан, 57.1% полиэтилентерефталат ",
      "Подошва: 100% полистирол-полибутадиен-полистирол (СБС)",
      "Подкладка и носок из: 50% этиленвинилацетат, 50% полиэтилентерефталат",
      "Съемную стельку можно заменить ортопедической стелькой",
      " Высокое голенище, ограничивающее попадание камешков или веточек",
      "Укрепляющие вставки на мысках для защиты от камней",
      "Подошва ТПР с шипами 3,5 мм, подходящая для пешеходного маршрута",
      "Амортизирующая вставка из ЭВА 6 мм в области пятки для пешеходного маршрута",
    ],
  },
  {
    id: 1000180,
    title: "Мужские походные ботинки",
    img: "25ee76d2fc.jpeg",
    desc: "черные - NH100 (41,43,45 размер)",
    category: "shoes",
    price: 125,
    column: [
      "Подошва: 100% термопластичный полиэтилен. ",
      "Материал верха: 44.5% полиуретан, 55.5% полиэстер ",
      "Подкладка и носок из: 50% полиэстер, 50% сополимер этилена и винилацетата.",
      "Съемную стельку можно заменить ортопедической стелькой",
      "Укрепляющие вставки на мысках для защиты от камней",
      "Подошва из ТПР с шипами 3,5 мм для пешеходного маршрута с малым перепадом высот",
      "Особый материал подошвы и шипы глубиной 3,5 мм не задерживают грязь",
    ],
  },
  {
    id: 1000181,
    title: "Женская уличная обувь",
    img: "2582521.jpg",
    desc: "темно-синяя — NH100 (39,37 размер)",
    category: "shoes",
    price: 120,
    column: [
      "Амортизирующая вставка из ЭВА толщиной 6 мм в области пятки для грунтовых троп",
      "Подошва из TPR с шипами глубиной 3,5 мм адаптирована для природных поверхностей",
      "Съемную стельку можно заменить ортопедической стелькой",
      "Синтетическая вставка в союзке для защиты от истирания на природе",
      "Особый материал подошвы и шипы глубиной 3,5 мм не задерживают грязь",
      "Каждый кроссовок весит не более 297 г для размера 39",
    ],
  },
  {
    id: 100901814,
    title: "Женская уличная обувь",
    img: "8553549-product.png",
    desc: "черная — NH50 (38)",
    category: "shoes",
    price: 110,
    column: [
      "Эти ботинки NH50 созданы для ходьбы по ровным тропам, будь то у моря, в лесу или в сельской местности.",
      "Амортизация с двумя вставками EVA: 3 мм в передней части и 8 мм в задней части ботинка",
      "Съемные стельки, чтобы вы могли вставить ортопедические стельки",
      "Подошва из: 100,0% Стирол Этилен Бутадиен Стирола",
      "Верх: 30,0% полиуретан, 70,0% полиэстер",
      "Подкладка и носок: 50,0% этиленвинилацетат, 50,0% полиэстер",
    ],
  },
  {
    id: 10099010814,
    title: "Мужская уличная обувь",
    img: "8844095-produc.png",
    desc: "черная — NH50 (43) ",
    category: "shoes",
    price: 110,
    column: [
      "Мужские походные ботинки Quechua NH50 Low | Эти удобные ботинки NH50 были разработаны для нерегулярных прогулок по равнинам, лесам или среднегорьям в сухую погоду.",
      "Вставка в области пятки и стелька из 5-миллиметровой пены EVA обеспечивают хорошую амортизацию.",
      "Подошва из: 100,0% Стирол-бутадиен-стирол;",
      "Верх из: 60,0% Полиэстер, 40,0% Полиуретан;",
      " Подкладка и носок из: 50,0% Полиэфирная смола, 50,0% Этиленвинилацетат",
    ],
  },

  {
    id: 10099901084314,
    title: "Детские водонепроницаемые зимние ботинки",
    img: "8640887-product.png",
    desc: "SH100 Mid (36/37)",
    category: "shoes",
    price: 89,
    column: [
      "Эти теплые ботинки были созданы с резиновой подошвой snowcontact для обеспечения лучшего сцепления.",
      "Комфортная температура – ​​в состоянии покоя: -11°CВ движении: -20°C",
      "Дышащая, водонепроницаемая мембрана сохранит ваши ноги сухими",
      "",
    ],
  },
  {
    id: 10001814,
    title: "Женские водонепроницаемые зимние ботинки",
    img: "warm-vodonepronicaemye-zhenskie-serye.jpg",
    desc: "SH100 Mid0",
    category: "shoes",
    price: 114,
    column: [
      "Комфортная температура в статичном состоянии: -4°C; в движении: -13°C.",
      "Сцепление по технологии SNOWCONTACT: особый материал и рельеф подошвы",
      "Широкое открытие сапога облегчает надевание и гарантирует фиксацию",
      "Литая колодка со 100% водонепроницаемостью (голенище не водонепроницаемое)",
      "Отличное сцепление на снегу благодаря рифлению глубиной 5 мм.",
    ],
  },
  {
    id: 1000182,
    title: "Мужские водонепроницаемые зимние ботинки ",
    img: "bottes-de-neige-chaudes-impermeables-sh100-warm-mid-homme.jpg",
    desc: "SH100 Mid0",
    category: "shoes",
    price: 114,
    order: true,
    column: [
      "Комфортная температура в статичном состоянии: -9°C; в движении: -18°C.",
      "Сцепление по технологии SNOWCONTACT: особый материал и рельеф подошвы",
      "Широкое открытие сапога облегчает надевание и гарантирует фиксацию",
      "Литая колодка со 100% водонепроницаемостью (голенище не водонепроницаемое)",
      "Отличное сцепление на снегу благодаря рифлению глубиной 5 мм.",
    ],
  },
  {
    id: 1080430182,
    title: "Короткие носки для походов Hike 50 ",
    img: "sock-hike-50-mid-2-pack-navy.png",
    desc: "темно-синие, 2 пары",
    category: "socks",
    price: 19,
    column: [
      "Биохлопок. Ткань плотного плетения на ступне для комфорта. Плоские швы.",
      "В составе 2% эластана для хорошей фиксации носков",
      "Добавление нити из полиамида способствует ускоренному высыханию",
      "Усиленные вставки на мыске и пятке",
    ],
  },

  {
    id: 1080433230182,
    title: "Длинные носки для походов, Hike 50",
    img: "sock-hike-50-high-pack-of-2-pairs-grey.png",
    desc: "серые, 2 пары",
    category: "socks",
    price: 27,
    column: [
      "Биохлопок. Ткань плотного плетения на ступне для комфорта. Плоские швы.",
      "В составе 2% эластана для хорошей фиксации носков",
      "Добавление нити из полиамида способствует ускоренному высыханию",
      "Усиленные вставки на мыске и пятке",
    ],
  },

  {
    id: 10804330182,
    title: "Короткие носки для походов, Hike 100 ",
    img: "mountain-hiking-low-socks-2-pairs-100-grey.png",
    desc: "черные / серые, 2 пары",
    category: "socks",
    price: 29,
    column: [
      "3% нити из эластана: носки адаптируются к форме стопы и не сползают",
      "Экологичный материал из биохлопка и льна. Удобный махровый трикотаж под ступней",
      "Вентиляция, вентилируемый трикотаж и синтетическое волокно для отвода влаги",
      "Прочные составные волокна полиамида",
    ],
  },
  {
    id: 9110304397659,
    title: "Носки для сноуборда Wedze 100",
    img: "owboard-corabi-mavi-100.png",
    desc: "розовые, синие, малиновые",
    category: "socks",
    price: 35,
    column: [
      "Подошва носка на 41% состоит из шерсти.(18% от общего носка).",
      "Носки СРЕДНЕЙ толщины для узких или средних лыжных ботинок.",
      "Полностью эластичные носки плотно прилегают к ноге",
      "Синтетическое волокно эффективно отводит пот наружу.",
    ],
  },
  {
    id: 91103097659,
    title: "НОСКИ",
    img: "8dc21759-279b-4ee9-9e34-1e87742ddb92.webp",
    desc: "ДЛЯ ВЗРОСЛЫХ",
    category: "socks",
    price: 18,
    column: ["Состав:75% Хлопок,25% Акрил"],
  },
  {
    id: 9110304497659,
    title: "НОСКИ",
    img: "19.45.17.jpg",
    desc: "розовые, оранжевые, черные, белые",
    category: "socks",
    price: 28,
    column: [
      "Состав:80% Полиамид,20% Полиэстер",
      "компрессионные носки для занятий спортом ",
    ],
  },
  {
    id: 1038003263454331109,
    title: "НОСКИ ГОРНОЛЫЖНЫЕ",
    img: "25288.png",
    desc: "ДЛЯ ВЗРОСЛЫХ, СЕРЫЕ",
    category: "socks",
    price: 30,
    column: [
      "Если для вас катание на лыжах ассоциируется со спокойствием, прогулками и открытиями, эти носки обеспечат вам все необходимое тепло, чтобы в полной мере насладиться ими.",
      "Максимальная толщина, носок рекомендуется для обуви с широкими подошвами.",
      "Благодаря своему составу (акриловому) отвод пота слабый.",
    ],
  },
  {
    id: 10380032634454331109,
    title: "НОСКИ",
    img: "2e211b19-52a0-4dd7-8923-2bbfe4b4dda5.webp",
    desc: "ДЛЯ ВЗРОСЛЫХ",
    category: "socks",
    price: 25,
    column: [
      "Для лыж и сноуборда для мужчин и женщин",
      "Hоски для походов на свежем воздухе с амортизирующим дном",
      "Трикотаж из полиэстера (98%) и спандекса (2%)",
    ],
  },
  {
    id: 1038320032634454331109,
    title: "НОСКИ",
    img: "fe827bbbc4de40f0da688ff15839678d.webp",
    desc: "ДЛЯ ВЗРОСЛЫХ",
    category: "socks",
    price: 25,
    column: [
      "Зимних лыжных",
      "Зимних спортивных носков",
      "до колена",
      "Состав: 80% Полиамид,20% Полиэстер",
    ],
  },
  {
    id: 1032932634454331109,
    title: "НОСКИ",
    img: "9fabbd4ee2d0a0210f13d9bff49713c3.webp",
    desc: "ДЛЯ ВЗРОСЛЫХ",
    category: "socks",
    price: 25,
    column: [
      "Зимних лыжных",
      "Зимних спортивных носков",
      "до колена",
      "Состав: 80% Полиамид,20% Полиэстер",
    ],
  },

  {
    id: 10804323330182,
    title: "Длинные зимние термоноски SH100 Mid",
    img: "adults-mid-length-warm-hiking-socks-100-x-warm-2-pairs-blue.avif",
    desc: "шерстяные, синие, 2 пары",
    category: "socks",
    price: 35,
    column: [
      "Махровый трикотаж с наличием шерсти обеспечит тепло в холодную погоду",
      "Махровый трикотаж по всей длине носка для большего удобства использования",
      "Усиливающие вставки из полиамида для снижения истирания и увеличения прочности зоны под ступней",
    ],
  },
  {
    id: 1080432330182,
    title: "Длинные зимние термоноски SH100 Mid",
    img: "warm-hiking-socks-sh100-mid-2-pairs.avif",
    desc: "шерстяные, серые, 2 пары",
    category: "socks",
    order: true,
    price: 35,
    column: [
      "Махровый трикотаж с наличием шерсти обеспечит тепло в холодную погоду",
      "Махровый трикотаж по всей длине носка для большего удобства использования",
      "Усиливающие вставки из полиамида для снижения истирания и увеличения прочности зоны под ступней",
    ],
  },
  {
    id: 108043233301482,
    title: "Длинные зимние термоноски SH500",
    img: "8641380-produ.jpg",
    desc: "2 пары, мериносовая шерсть, голубые",
    category: "socks",
    price: 45,
    order: true,
    column: [
      "Внутренний материал стопы — из шерсти мериноса с махровым трикотажем",
      "Усиливающие вставки из полиамида для сниж. ист. и ув. прочности зоны под ступней",
      "Махровый трикотаж под стопой обеспечивает комфорт во время ходьбы.",
      "Асимметричные носки (левый и правый) для более точной посадки",
      "Трикотажный материал снижает риск появления мозолей в зоне плюсны и пятки",
    ],
  },
  {
    id: 108043323330182,
    title: "Длинные зимние термоноски SH500",
    img: "p2579420.png",
    desc: "2 пары, мериносовая шерсть, зеленый/розовый",
    category: "socks",
    price: 45,
    order: true,
    column: [
      "Внутренний материал стопы — из шерсти мериноса с махровым трикотажем",
      "Усиливающие вставки из полиамида для сниж. ист. и ув. прочности зоны под ступней",
      "Махровый трикотаж под стопой обеспечивает комфорт во время ходьбы.",
      "Асимметричные носки (левый и правый) для более точной посадки",
      "Трикотажный материал снижает риск появления мозолей в зоне плюсны и пятки",
    ],
  },
  {
    id: 1080433233330182,
    title: "Длинные зимние термоноски Hike High 900",
    img: "hiking-socks-hike-900-mid-2-pack-black-quechua-8616554-Photoroom.png",
    desc: "2 пары, мериносовая шерсть, красно-черные",
    category: "socks",
    price: 57,
    column: [
      "Шерсть мериноса. Антифрикционные. Усиление из махрового трикотажа в зоне трения",
      "Волокна Coolmax® и вентилируемый трикотаж для сухости ног",
      "Анатомическая форма (П/Л) и на 100% эластичная структура для поддержки ноги",
      "Волокна из материала Cordura® и полиамида для большей прочности",
    ],
  },
];
